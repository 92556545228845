import LockClosedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState, useRef } from 'react';
import { useSelectedNodes } from '../SelectedNodesContext';
import NumberInput from '../NumberInput';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ResetIcon from '@mui/icons-material/Replay';
import UpIcon from '@mui/icons-material/Straight';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

export default function TransformationProperties() {
	const [translationX, setTranslationX] = useState<number | undefined>(undefined);
	const [translationY, setTranslationY] = useState<number | undefined>(undefined);
	const [translationZ, setTranslationZ] = useState<number | undefined>(undefined);

	const [rotationX, setRotationX] = useState<number | undefined>(undefined);
	const [rotationY, setRotationY] = useState<number | undefined>(undefined);
	const [rotationZ, setRotationZ] = useState<number | undefined>(undefined);

	const [scaleX, setScaleX] = useState<number | undefined>(undefined);
	const [scaleY, setScaleY] = useState<number | undefined>(undefined);
	const [scaleZ, setScaleZ] = useState<number | undefined>(undefined);

	const [scale, setScale] = useState<number | undefined>(undefined);

	const [lockScale, setLockScale] = useState(true);

	const [forceUpdate, setForceUpdate] = useState(0);

	const { selectedNodeIds } = useSelectedNodes();

	const [transformSpace, setTransformSpace] = useState<'local' | 'global'>('local');
	const [transformType, setTransformType] = useState<'relative' | 'absolute'>('relative');
	const [locked, setLocked] = useState(false);

	const nodeId = useRef<number | null>(null);

	const getData = () => {
		if (!nodeId.current) return;

		const currentValues = globalThis.lys.getTransformRotationScale(nodeId.current, transformSpace === 'global');
		const currentNode = globalThis.lys.getNodeById(nodeId.current);

		setLocked(currentNode.isLocked());

		// Update state with the returned values
		setTranslationX(currentValues[0]);
		setTranslationY(currentValues[1]);
		setTranslationZ(currentValues[2]);

		setRotationX(currentValues[3]);
		setRotationY(currentValues[4]);
		setRotationZ(currentValues[5]);

		setScaleX(currentValues[6]);
		setScaleY(currentValues[7]);
		setScaleZ(currentValues[8]);

		setScale((currentValues[6] + currentValues[7] + currentValues[8]) / 3.0);
	};

	useEffect(() => {
		if (nodeId.current !== selectedNodeIds[0]) nodeId.current = selectedNodeIds[0];

		getData();
	}, [selectedNodeIds]);

	useEffect(() => {
		getData();
	}, [forceUpdate, transformSpace]);

	useEffect(() => {
		globalThis.updateSelectedTransforms = () => setForceUpdate((n) => n + 1);
		globalThis.updateSelectedTransforms();

		return () => {
			delete globalThis.updateSelectedTransforms;
		};
	}, []);

	const alignAxisToUp = (axis: 'X' | 'Y' | 'Z') => {
		if (!nodeId.current) return;

		let rotation = { x: 0, y: 0, z: 0 };

		switch (axis) {
			case 'X':
				rotation = { x: 0, y: 0, z: 90 }; // Example: X axis aligns to world Y
				break;
			case 'Y':
				rotation = { x: 0, y: 0, z: 0 }; // Y is already up
				break;
			case 'Z':
				rotation = { x: -90, y: 0, z: 0 }; // Example: Z axis aligns to world Y
				break;
		}

		// Update the rotation state
		setRotationX(rotation.x);
		setRotationY(rotation.y);
		setRotationZ(rotation.z);

		// Apply the rotation to the node
		globalThis.lys.setTranslateRotationScale(
			nodeId.current,
			translationX || 0,
			translationY || 0,
			translationZ || 0,
			rotation.x,
			rotation.y,
			rotation.z,
			scaleX || 1,
			scaleY || 1,
			scaleZ || 1,
			transformSpace === 'global',
			true,
		);
	};

	// Handler functions for user input
	const handleTranslationX = (value: number | undefined) => {
		setTranslationX(value);

		if (
			nodeId.current &&
			value !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				value,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleTranslationY = (value: number | undefined) => {
		setTranslationY(value);

		if (
			nodeId.current &&
			translationX !== undefined &&
			value !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				value,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleTranslationZ = (value: number | undefined) => {
		setTranslationZ(value);

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			value !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				value,
				rotationX,
				rotationY,
				rotationZ,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleRotationX = (value: number | undefined) => {
		setRotationX(value);

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			value !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				value,
				rotationY,
				rotationZ,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleRotationY = (value: number | undefined) => {
		setRotationY(value);

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			value !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				rotationX,
				value,
				rotationZ,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleRotationZ = (value: number | undefined) => {
		setRotationZ(value);

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			value !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				value,
				scaleX,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleScaleX = (value: number | undefined) => {
		setScaleX(value);

		if (lockScale && value !== undefined) {
			setScaleY(value);
			setScaleZ(value);
			setScale(value);
		}

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			value !== undefined &&
			scaleY !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				value,
				scaleY,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleScaleY = (value: number | undefined) => {
		setScaleY(value);

		if (lockScale && value !== undefined) {
			setScaleX(value);
			setScaleZ(value);
			setScale(value);
		}

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			value !== undefined &&
			scaleZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				scaleX,
				value,
				scaleZ,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleScaleZ = (value: number | undefined) => {
		setScaleZ(value);

		if (lockScale && value !== undefined) {
			setScaleX(value);
			setScaleY(value);
			setScale(value);
		}

		if (
			nodeId.current &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined &&
			scaleX !== undefined &&
			scaleY !== undefined &&
			value !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				translationX,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				scaleX,
				scaleY,
				value,
				transformSpace === 'global',
				true,
			);
		}
	};

	const handleScale = (value: number | undefined) => {
		setScale(value);

		if (lockScale && value !== undefined && scaleX !== undefined && scaleY !== undefined && scaleZ !== undefined) {
			const average = (scaleX + scaleY + scaleZ) / 3.0;
			const mult = value / average;

			const newScaleX = scaleX * mult;
			const newScaleY = scaleY * mult;
			const newScaleZ = scaleZ * mult;

			setScaleX(newScaleX);
			setScaleY(newScaleY);
			setScaleZ(newScaleZ);

			if (
				nodeId.current &&
				translationX !== undefined &&
				translationY !== undefined &&
				translationZ !== undefined &&
				rotationX !== undefined &&
				rotationY !== undefined &&
				rotationZ !== undefined
			) {
				globalThis.lys.setTranslateRotationScale(
					nodeId.current,
					translationX,
					translationY,
					translationZ,
					rotationX,
					rotationY,
					rotationZ,
					newScaleX,
					newScaleY,
					newScaleZ,
					transformSpace === 'global',
					true,
				);
			}
		}
	};

	const handleReset = () => {
		setTranslationX(0);
		setTranslationY(0);
		setTranslationZ(0);

		setRotationX(0);
		setRotationY(0);
		setRotationZ(0);

		setScaleX(1);
		setScaleY(1);
		setScaleZ(1);
		setScale(1);

		if (nodeId.current && transformSpace !== undefined) {
			globalThis.lys.setTranslateRotationScale(
				nodeId.current,
				0,
				0,
				0,
				0,
				0,
				0,
				1,
				1,
				1,
				transformSpace === 'global',
				true,
			);
		}
	};

	return (
		<>
			{selectedNodeIds.length == 1 && (
				<Box sx={{ p: 1 }}>
					<Grid
						container
						spacing={0}
						paddingTop={1}
						paddingBottom={1}
						columns={12}
						alignItems="center"
						justifyContent="center"
						rowSpacing={1}
						columnSpacing={1}
					>
						{/* Column Headers */}
						<Grid item xs={3}></Grid> {/* Placeholder for row label */}
						<Grid item xs={3}>
							<Typography align="center" color="red">
								X
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="center" color="green">
								Y
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="center" color="blue">
								Z
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="left">Translation:</Typography>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								value={translationX}
								setValue={handleTranslationX}
							/>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								value={translationY}
								setValue={handleTranslationY}
							/>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								value={translationZ}
								setValue={handleTranslationZ}
							/>
						</Grid>
						<Grid item xs={3}>
							<Typography align="left">Rotation:</Typography>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								adornment="°"
								step={15}
								value={rotationX}
								setValue={handleRotationX}
							/>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								adornment="°"
								step={15}
								value={rotationY}
								setValue={handleRotationY}
							/>
						</Grid>
						<Grid item xs={3}>
							<NumberInput
								disabled={selectedNodeIds.length !== 1 || locked}
								adornment="°"
								step={15}
								value={rotationZ}
								setValue={handleRotationZ}
							/>
						</Grid>
						<Grid item xs={3}>
							<Typography align="left">
								Scale:{' '}
								{lockScale ? (
									<LockClosedIcon fontSize="small" onClick={() => setLockScale(false)} />
								) : (
									<LockOpenIcon fontSize="small" onClick={() => setLockScale(true)} />
								)}
							</Typography>
						</Grid>
						{lockScale && (
							<>
								<Grid item xs={3}>
									<NumberInput
										disabled={selectedNodeIds.length !== 1 || locked}
										value={scale}
										setValue={handleScale}
									/>
								</Grid>
								<Grid item xs={3}></Grid>
								<Grid item xs={3}></Grid>
							</>
						)}
						{!lockScale && (
							<>
								<Grid item xs={3}>
									<NumberInput
										disabled={selectedNodeIds.length !== 1 || locked}
										value={scaleX}
										setValue={handleScaleX}
									/>
								</Grid>
								<Grid item xs={3}>
									<NumberInput
										disabled={selectedNodeIds.length !== 1 || locked}
										value={scaleY}
										setValue={handleScaleY}
									/>
								</Grid>
								<Grid item xs={3}>
									<NumberInput
										disabled={selectedNodeIds.length !== 1 || locked}
										value={scaleZ}
										setValue={handleScaleZ}
									/>
								</Grid>
							</>
						)}
					</Grid>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between', // Ensures children are spaced apart
							flexGrow: 1,
							marginRight: 1,
							marginTop: 1,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center', // Vertically align ToggleButtonGroup
							}}
						>
							<ToggleButtonGroup
								disabled={locked}
								value={transformSpace}
								exclusive
								onChange={(e, newValue) => {
									if (newValue !== null) {
										setTransformSpace(newValue);
									}
								}}
								aria-label="transform space"
								sx={{
									'& .MuiToggleButtonGroup-grouped': {
										borderRadius: 0,
										'&:first-of-type': {
											borderTopLeftRadius: '16px',
											borderBottomLeftRadius: '16px',
										},
										'&:last-of-type': {
											borderTopRightRadius: '16px',
											borderBottomRightRadius: '16px',
										},
									},
								}}
							>
								<ToggleButton
									value="local"
									style={{
										borderTopLeftRadius: '5px',
										borderBottomLeftRadius: '5px',
										textTransform: 'none',
										height: 32,
										padding: '4px 12px',
									}}
								>
									Local
								</ToggleButton>
								<ToggleButton
									value="global"
									style={{
										borderTopRightRadius: '5px',
										borderBottomRightRadius: '5px',
										textTransform: 'none',
										height: 32,
										padding: '4px 12px',
									}}
								>
									Global
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center', // Vertically align IconButtons
								gap: 1, // Add spacing between IconButtons
								paddingTop: 1,
							}}
						>
							<Tooltip title={'rotate to x up'} arrow>
								<span>
									<IconButton disabled={locked} onClick={() => alignAxisToUp('X')}>
										<Box display="flex" alignItems="center">
											<Typography
												variant="caption"
												sx={{
													fontSize: '0.7rem',
													marginRight: '-4px', // Bring the letter closer
												}}
											>
												X
											</Typography>
											<UpIcon fontSize="inherit" sx={{ fontSize: '1rem', color: 'red' }} />
										</Box>
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title={'rotate to y up'} arrow>
								<span>
									<IconButton disabled={locked} onClick={() => alignAxisToUp('Y')}>
										<Box display="flex" alignItems="center">
											<Typography
												variant="caption"
												sx={{
													fontSize: '0.7rem',
													marginRight: '-4px', // Bring the letter closer
												}}
											>
												Y
											</Typography>
											<UpIcon fontSize="inherit" sx={{ fontSize: '1rem', color: 'green' }} />
										</Box>
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title={'rotate to z up'} arrow>
								<span>
									<IconButton disabled={locked} onClick={() => alignAxisToUp('Z')}>
										<Box display="flex" alignItems="center">
											<Typography
												variant="caption"
												sx={{
													fontSize: '0.7rem',
													marginRight: '-4px', // Bring the letter closer
												}}
											>
												Z
											</Typography>
											<UpIcon fontSize="inherit" sx={{ fontSize: '1rem', color: 'blue' }} />
										</Box>
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title={'reset transformation'} arrow>
								<span>
									<IconButton disabled={locked} onClick={handleReset}>
										<ResetIcon fontSize="small" />
									</IconButton>
								</span>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
}
