import { Box, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserProfileForm, { UserAction, UserProfile } from '../components/users/UserProfileForm';
import { User } from '../utils/user';
import FButton from '../components/FButton';
import { isValidEmail } from '../utils/user';

export type FlowState = 'start' | 'email sent' | 'followed link';

/**
 * The user information that is required to finalize your profile or reset your password.
 */

export default function ResetPasswordPage({ onUser }: { onUser: (user: User) => void }) {
	enum TokenState {
		INIT,
		VALID,
		INVALID,
	}

	const [email, setEmail] = useState('');

	const [searchParams, _setSearchParams] = useSearchParams();
	const [state, setState] = useState<FlowState>('start');
	const [user, setUser] = useState<UserProfile>(null);
	const [tokenState, setTokenState] = useState<TokenState>(TokenState.INIT);

	const navigate = useNavigate();

	useEffect(() => {
		const token = searchParams.get('token');
		if (token) {
			setState('followed link');
			fetchUserData(token).then((user) => {
				if (user) {
					setUser(user);
					setTokenState(TokenState.VALID);
				} else {
					setTokenState(TokenState.INVALID);
				}
			});
		}
		const emailParam = searchParams.get('email');
		if (emailParam) {
			setEmail(emailParam);
		}
	}, []);

	async function fetchUserData(token: string): Promise<UserProfile> {
		const res = await fetch('/api/auth/resets/' + token);
		if (res.ok) {
			return res.json();
		} else {
			return null;
		}
	}

	function resetPasswordCompleted() {
		//TODO - before there was some onUser(user) getting the new user logged into the frontend
		navigate('/');
	}

	function resetPasswordSubmit(user: UserProfile, password: string): Promise<Response> {
		const token = searchParams.get('token');
		return fetch('/api/auth/resets/' + token, {
			method: 'PUT',
			body: JSON.stringify({
				password: password,
				name: user.name,
				company: user.company,
			}),
		});
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const res = await sendResetPasswordRequest(email);
		if (res.ok) {
			setState('email sent');
		}
	};

	const validate = () => {
		return isValidEmail(email);
	};

	return (
		<div>
			{state === 'start' && (
				<Box
					display={isMobileOnly ? undefined : 'flex'}
					justifyContent={isMobileOnly ? undefined : 'center'}
					alignItems={isMobileOnly ? undefined : 'center'}
					textAlign="center"
					minHeight="100vh"
					minWidth={isMobileOnly ? '100vw' : undefined}
				>
					<Paper sx={isMobileOnly ? { height: '100vh' } : undefined} elevation={10} style={{ padding: 30 }}>
						<Typography style={{ paddingTop: 30 }} variant="h3">
							Reset my password
						</Typography>

						<Box component="form" onSubmit={handleSubmit} noValidate>
							<div style={{ paddingTop: 30 }}>
								<TextField
									type="email"
									required
									fullWidth
									label="Email"
									name="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>

							<div style={{ paddingTop: 30 }}>
								<FButton
									fullWidth
									variant="contained"
									rel="noopener noreferrer"
									disabled={!validate()}
									onClick={handleSubmit}
								>
									Send me a reset link
								</FButton>
							</div>
						</Box>
					</Paper>
				</Box>
			)}

			{state === 'email sent' && (
				<Box
					display={isMobileOnly ? undefined : 'flex'}
					justifyContent={isMobileOnly ? undefined : 'center'}
					alignItems={isMobileOnly ? undefined : 'center'}
					textAlign="center"
					minHeight="100vh"
				>
					<Paper sx={isMobileOnly ? { height: '100vh' } : undefined} elevation={10} style={{ padding: 30 }}>
						<Typography variant="h3">Check your inbox</Typography>
						<div style={{ paddingTop: 30 }}>
							Please read the email sent to your inbox for instructions on how to reset your password
						</div>
						<div style={{ paddingTop: 30 }}>
							<FButton variant="outlined" onClick={() => navigate('/login')}>
								Back to Login
							</FButton>
						</div>
					</Paper>
				</Box>
			)}

			{state === 'followed link' && (
				<Box
					display={isMobileOnly ? undefined : 'flex'}
					justifyContent={isMobileOnly ? undefined : 'center'}
					alignItems={isMobileOnly ? undefined : 'center'}
					textAlign="center"
					minHeight="100vh"
				>
					<Paper elevation={10}>
						{tokenState === TokenState.VALID && (
							<UserProfileForm
								onCompleted={resetPasswordCompleted}
								onSubmit={resetPasswordSubmit}
								mustChangePassword={true}
								user={user}
								profileAction={UserAction.UPDATE}
							/>
						)}
						{tokenState === TokenState.INVALID && (
							<Box>
								<Typography variant="h3">Invalid reset token. </Typography>
								<Typography variant="h5">Please go through the reset password flow again</Typography>
							</Box>
						)}
					</Paper>
				</Box>
			)}
		</div>
	);
}

export async function sendResetPasswordRequest(email: string): Promise<Response> {
	const res = await fetch('/api/auth/resets', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
		}),
	});
	return res;
}
