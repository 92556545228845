import { Folder } from '@mui/icons-material';
import { Breadcrumbs, IconButton, ImageList, ImageListItem, ImageListItemBar, Link, Typography } from '@mui/material';
import { MoreVert, ArrowDropDown } from '@mui/icons-material';
import * as FileApi from '../../services/file-api';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useComponentSize } from '../../utils/use-component-size';
import { useDragItemContext } from '../DragItemContext'; // Import the context
import { FileGridMoreMenu } from './FileGridMoreMenu';
import { sortFunctions, SortFunctionWithName, SortMenu } from './SortMenu';
import { useNavigate } from 'react-router-dom';
import { ShowRenderDialog } from './ShowRenderDialog';

type FileGalleryProps = {
	breadCrumb: FileApi.FSEntry[];
	folderList: FileApi.FSEntry[];
	fileList: FileApi.FSEntry[];
	onSelectFolder: (id: string) => void;
	onChange: () => void;
};

export default function FileGrid({ breadCrumb, folderList, fileList, onSelectFolder, onChange }: FileGalleryProps) {
	const imageContainerRef = useRef(null);
	const { width: imageContainerwidth } = useComponentSize(imageContainerRef);
	const [imageColumns, setImageColumns] = useState(1);
	const { setDraggedItem } = useDragItemContext(); // Access the dragged item from context

	//more menu state
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [fileWithOpenMoreMenu, setFileWithOpenMoreMenu] = useState<FileApi.FSEntry>(null);

	const [showSortMenu, setShowSortMenu] = useState<boolean>(false);

	const [sortFunction, setSortFunction] = useState<SortFunctionWithName>(sortFunctions.modifiedTime);

	const [displayedRender, setDisplayedRender] = useState<FileApi.FSEntry | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		setImageColumns(Math.floor(imageContainerwidth / 140));
	}, [imageContainerwidth]);

	const handleDragStart = (e, file: FileApi.FSEntry) => {
		setDraggedItem(file, 'fileGrid'); // Pass the event to set custom data in dataTransfer

		const dragImage = e.target;

		const xOffset = 0;
		const yOffset = 0;

		e.dataTransfer.setDragImage(dragImage, xOffset, yOffset);
	};

	const handleClickOnFile = (e: React.MouseEvent, file: FileApi.FSEntry) => {
		e.stopPropagation();
		e.preventDefault();
		if (file.type === 'scene') {
			navigate(`/scenes/${file.id}/edit`);
		} else if (file.type === 'render') {
			setDisplayedRender(file);
		}
	};

	return (
		<div ref={imageContainerRef}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Breadcrumbs aria-label="breadcrumb" sx={{ margin: '1em' }}>
					{breadCrumb.map((folder) => (
						<Link
							key={folder.id}
							color="inherit"
							onClick={() => onSelectFolder(folder.id)}
							style={{ cursor: 'pointer' }}
						>
							<Typography>{folder.label}</Typography>
						</Link>
					))}
				</Breadcrumbs>
				<IconButton
					onClick={(_event) => {
						setAnchorEl(_event.currentTarget);
						setShowSortMenu(true);
					}}
					size="small"
				>
					<Typography>{sortFunction.name}</Typography>
					<ArrowDropDown />
				</IconButton>
			</div>

			{folderList.length > 0 && (
				<ImageList cols={imageColumns} style={{ margin: 0 }} gap={8}>
					{folderList.sort(sortFunction.sortFunction).map((folder) => (
						<ImageListItem key={folder.id}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									cursor: 'pointer',
								}}
								onClick={() => onSelectFolder(folder.id)}
							>
								<Folder
									color="primary"
									style={{
										width: '100%',
										flex: 1,
										borderRadius: '5px',
									}}
								/>
								<ImageListItemBar
									title={folder.label}
									position="below"
									sx={{
										margin: '-10px 5px 10px 15px',
										'& .MuiImageListItemBar-titleWrap': {
											padding: 0,
										},
										flexGrow: 0,
									}}
								/>
								{/* More menu uncomment when we want to delete
                <IconButton
                  sx={{ position: "absolute", top: '0px', right: '0px', zIndex: 10 }}
                  onClick={e => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                    setFileWithOpenMoreMenu(folder);
                  }}
                  size="small">
                  <MoreVert />
                </IconButton>
                */}
							</div>
						</ImageListItem>
					))}
				</ImageList>
			)}

			<ImageList
				cols={imageColumns}
				gap={12}
				sx={{ boxSizing: 'border-box' }}
				style={{
					gridTemplateColumns: `repeat(${imageColumns}, minmax(0, 1fr))`,
				}}
			>
				{fileList.sort(sortFunction.sortFunction).map((file) => (
					<ImageListItem key={file.id} sx={{ cursor: 'pointer' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<img
								draggable={true}
								onDragStart={(e) => handleDragStart(e, file)} // Set dragged file on drag start
								// onDragStart={(e) => dragStart(index)}
								// onDragEnd={(e) => onDraggingMaterial(undefined)}
								src={file.thumbnail}
								alt={file.label}
								loading="lazy"
								onClick={(event) => handleClickOnFile(event, file)}
								id={file.id}
								onError={(e: SyntheticEvent<HTMLImageElement>) => {
									const me = e.target as HTMLImageElement;
									me.onerror = null; // Prevents infinite loop if the fallback image also fails
									me.src =
										'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjmDZt2n8ABkoCwgNiyRMAAAAASUVORK5CYII=';
								}}
								style={{
									flex: 1,
									borderRadius: '5px',
									zIndex: 2,
								}}
							/>
							{file.label && (
								<ImageListItemBar
									title={file.label}
									position="below"
									sx={{
										flex: 1,
										backgroundColor: 'background.label',
										borderRadius: '0 0 5px 5px',
										marginTop: '-5px',
										padding: '10px 5px 0px 8px',
										zIndex: 1,
										flexGrow: 0,
									}}
								/>
							)}
							<IconButton
								sx={{
									position: 'absolute',
									top: '0px',
									right: '0px',
									zIndex: 10,
								}}
								onClick={(_event) => {
									setAnchorEl(_event.currentTarget);
									setFileWithOpenMoreMenu(file);
								}}
								size="small"
							>
								<MoreVert />
							</IconButton>
						</div>
					</ImageListItem>
				))}
			</ImageList>
			<FileGridMoreMenu
				selectedFile={fileWithOpenMoreMenu}
				anchorEl={anchorEl}
				onChange={() => {
					setFileWithOpenMoreMenu(null);
					onChange();
				}}
				onClose={() => setFileWithOpenMoreMenu(null)}
			/>
			{showSortMenu && (
				<SortMenu
					anchorEl={anchorEl}
					onClose={() => setShowSortMenu(false)}
					submit={(sortFunctionWithName) => setSortFunction(() => sortFunctionWithName)}
				/>
			)}
			{!!displayedRender && (
				<ShowRenderDialog
					renderFile={displayedRender}
					open={!!displayedRender}
					onClose={() => {
						setDisplayedRender(null);
						onChange();
					}}
				/>
			)}
		</div>
	);
}
