import { useState, useRef, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FTextField from './FTextField';

interface NumberInputProps {
	value: number; // The current value of the input
	setValue: (value: number) => void; // Function to set the new value
	label?: string;

	disabled?: boolean;
	adornment?: string;
	precision?: number;
	step?: number;
	fullWidth?: boolean;
	min?: number;
	max?: number;
}

function NumberInput({
	disabled,
	label,
	adornment = '',
	value,
	setValue,
	precision = 2,
	step = 1,
	fullWidth,
	min,
	max,
}: NumberInputProps) {
	const [dragging, setDragging] = useState(false);
	const startX = useRef<number | null>(null);
	const startValueRef = useRef<number>(0);
	const [displayValue, setDisplayValue] = useState<string>('');
	const [multiplierTextPos, setMultiplierTextPos] = useState({ x: 0, y: 0 });
	const [multiplier, setMultiplier] = useState(1);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [isEditing, setIsEditing] = useState(false);

	const sensitivity: number = 0.01;

	const clampValue = (val: number): number => {
		if (min !== undefined && val < min) return min;
		if (max !== undefined && val > max) return max;
		return val;
	};

	const handleBlur = () => {
		setIsEditing(false);
		setDisplayValueRounded(value);
	};

	const handleFocus = () => {
		setIsEditing(true);
	};

	const handleMouseDown = (e: React.MouseEvent) => {
		if (!disabled) {
			setDragging(true);
			if (inputRef.current) {
				inputRef.current.style.pointerEvents = 'none';
			}

			startX.current = e.clientX;
			startValueRef.current = Number(value);
			document.body.style.cursor = 'ew-resize';
			document.body.style.userSelect = 'none';
		}
	};

	const handleMouseMove = (e: MouseEvent) => {
		e.preventDefault();
		if (dragging && startX.current !== null) {
			let currentMultiplier = 1;

			if (e.shiftKey) {
				currentMultiplier = 10; // 10x step when Shift is pressed
			} else if (e.ctrlKey) {
				currentMultiplier = 0.1; // 0.1x step when Ctrl is pressed
			}

			setMultiplier(currentMultiplier);

			const deltaX = e.clientX - startX.current;
			const increment: number = deltaX * step * sensitivity * currentMultiplier;

			let newValue = startValueRef.current + increment;
			newValue = clampValue(newValue);
			setValue(newValue);
			setDisplayValueRounded(newValue);
			setMultiplierTextPos({ x: e.clientX, y: e.clientY });
			startX.current = e.clientX;
			startValueRef.current = newValue;
		}
	};

	const handleMouseUp = () => {
		setDragging(false);
		document.body.style.cursor = 'default';
		document.body.style.userSelect = '';

		if (inputRef.current) {
			inputRef.current.style.pointerEvents = 'auto';
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			setIsEditing(false);
			setDisplayValueRounded(value);
			inputRef.current?.blur();
		}
	};

	const setDisplayValueRounded = (value: number) => {
		const roundedValue = Math.round(value * 10 ** precision) / 10 ** precision;
		setDisplayValue(roundedValue.toFixed(precision));
	};

	useEffect(() => {
		if (isEditing) return;
		if (value === undefined) return;

		if (!isNaN(value)) {
			setDisplayValueRounded(value);
		}
	}, [value, precision, isEditing]);

	useEffect(() => {
		if (dragging) {
			window.addEventListener('mousemove', handleMouseMove);
			window.addEventListener('mouseup', handleMouseUp);
		} else {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		}
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};
	}, [dragging]);

	return (
		<>
			<FTextField
				label={label}
				disabled={disabled}
				fullWidth={fullWidth}
				inputRef={inputRef}
				onMouseDown={handleMouseDown}
				onChange={(e) => {
					setDisplayValue(e.target.value);
					const inputValue = e.target.value;
					if (inputValue !== '' && !isNaN(Number(inputValue)) && inputValue !== '-' && inputValue !== '-0') {
						setValue(clampValue(Number(inputValue)));
					}
				}}
				onBlur={handleBlur}
				onFocus={handleFocus}
				onKeyDown={handleKeyDown}
				value={displayValue}
				sx={{
					'& .MuiInputBase-input': {
						cursor: disabled ? 'default' : 'ew-resize',
						MozAppearance: 'textfield',
						'&::-webkit-outer-spin-button': {
							display: 'none',
						},
						'&::-webkit-inner-spin-button': {
							display: 'none',
						},
					},
				}}
				type="number"
				InputProps={{
					readOnly: dragging,
					endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : null,
				}}
				inputProps={{
					inputMode: 'decimal',
					step: step,
					style: {
						textAlign: 'right', // Ensures alignment in the native <input>
						fontFeatureSettings: '"tnum"', // Tabular numbers for consistent width
						paddingLeft: '0px',
					},
				}}
			/>
			{dragging && (
				<div
					style={{
						position: 'fixed',
						top: multiplierTextPos.y + 20,
						left: multiplierTextPos.x + 10,
						background: 'rgba(0, 0, 0, 0.7)',
						color: 'white',
						padding: '2px 4px',
						borderRadius: '4px',
						pointerEvents: 'none',
						fontSize: '12px',
					}}
				>
					{multiplier}x
				</div>
			)}
		</>
	);
}

export default NumberInput;
