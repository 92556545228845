import { useCallback, useEffect, useRef, useState } from 'react';
import { PointInNode } from '../components/SelectedNodesContext';

export type ChatMessage = {
	id: string;
	userName: string;
	userId: string;
	contents: string;
	cameraPosition: string;
	pointInNodes: PointInNode[];
};

export type ChatResponse = {
	messages: ChatMessage[];
	numberOfMessagesInHistory: number;
	sendMessage: (message: ChatMessage) => void;
	error?: string;
	isConnected: boolean;
};

function useWebSocketChat(sceneId: string | null, language: string): ChatResponse {
	const [messages, setMessages] = useState<ChatMessage[]>([]);
	const [numberOfMessagesInHistory, setNumberOfMessagesInHistory] = useState(0);
	const [error, setError] = useState<string | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const wsRef = useRef<WebSocket | null>(null);
	const wsForSceneId = useRef<string | null>(null);

	const fetchChatHistory = useCallback(async () => {
		if (!sceneId) return;

		try {
			const url = language
				? `/api/scenes/${sceneId}/chat/history/translated/${language}`
				: `/api/scenes/${sceneId}/chat/history`;
			const response = await fetch(url);
			if (!response.ok) {
				setError('Not able to connect to the chat at this time.');
				return;
			}
			const data = await response.json();
			setMessages(data.history);
			setNumberOfMessagesInHistory(data.history.length);
		} catch (err) {
			setError(err.message);
		}
	}, [sceneId, language]);

	useEffect(() => {
		if (!sceneId) return;

		if (!wsRef.current) {
			if (language) {
				wsRef.current = new WebSocket(`/api/scenes/${sceneId}/chat/translated/${language}`);
			} else {
				wsRef.current = new WebSocket(`/api/scenes/${sceneId}/chat`);
			}
			wsForSceneId.current = sceneId;

			wsRef.current.onopen = () => {
				setIsConnected(true);
				fetchChatHistory();
				setError(null);
			};

			wsRef.current.onmessage = (event) => {
				try {
					if (event.data === 'ping') {
						return;
					}
					const newMessage = JSON.parse(event.data);
					setMessages((prevMessages) => [...prevMessages, newMessage]);
				} catch (_e) {}
			};

			wsRef.current.onerror = (_event: Event) => {
				setError('Not able to connect to the chat at this time.');
			};

			wsRef.current.onclose = () => {
				setIsConnected(false);
			};
		}

		return () => {
			if (wsRef.current) {
				wsRef.current.close();
				wsRef.current = null;
				wsForSceneId.current = null;
			}
		};
	}, [sceneId, fetchChatHistory, language]);

	const sendMessage = useCallback((message: ChatMessage) => {
		if (wsRef.current?.readyState === WebSocket.OPEN) {
			wsRef.current.send(JSON.stringify(message));
		} else {
			setError('Not able to send chat message at this time.');
		}
		setMessages((prevMessages) => [...prevMessages, message]);
	}, []);

	return {
		messages,
		sendMessage,
		error,
		isConnected,
		numberOfMessagesInHistory,
	};
}

export default useWebSocketChat;
