import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

export default function AdminUserEvents() {
	const appbarHeight = isMobile ? '74.5px' : '47px';

	type UserEvent = {
		createdAt: Date;
		category: string;
		userId: string;
		sceneId: string;
		extraData: string;
	};

	const [events, setEvents] = useState([] as UserEvent[]);
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25,
		page: 0,
	});
	const [filterModel, setFilterModel] = useState<GridFilterModel>();
	const [sortModel, setSortModel] = useState<GridSortModel>();

	const navigate = useNavigate();

	const columns: GridColDef[] = [
		{ field: 'createdAt', headerName: 'Time', width: 150 },
		{ field: 'category', headerName: 'What', width: 200 },
		{ field: 'email', headerName: 'Email', width: 200 },
		{ field: 'extraData', headerName: 'More info', width: 350 },
		{ field: 'userId', headerName: 'User', width: 150 },
		{
			field: 'sceneId',
			headerName: 'Scene',
			width: 150,
			renderCell: (params) => <Button onClick={() => navigate(`/scenes/${params.value}`)}>{params.value}</Button>,
		},
	];

	useEffect(() => {
		setLoading(true);

		const url = new URL('/api/admin/user-events', window.location.origin);

		if (filterModel) {
			for (const filter of filterModel.items) {
				url.searchParams.append(filter.field, filter.value);
			}
		}
		if (sortModel) {
			for (const sort of sortModel) {
				url.searchParams.append(`_${sort.field}`, sort.sort);
			}
		}

		fetch(url.toString(), {
			headers: {
				'X-Page': `${paginationModel.page}`,
				'X-PageSize': `${paginationModel.pageSize}`,
			},
		}).then((res) =>
			res.json().then((events) => {
				setTotalCount(parseInt(res.headers.get('X-Total-Count')));
				setEvents(events);
				setLoading(false);
			}),
		);
	}, [paginationModel, sortModel, filterModel]);

	return (
		<DataGrid
			rows={events}
			rowCount={totalCount}
			loading={loading}
			pageSizeOptions={[3]}
			autoPageSize
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			filterMode="server"
			onFilterModelChange={setFilterModel}
			paginationMode="server"
			sortingMode="server"
			onSortModelChange={setSortModel}
			columns={columns}
			sx={{ paddingTop: appbarHeight }}
		/>
	);
}
