import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import { actions, ActionKey, ActionContext } from './actions';
import { useKeyboardShortcuts } from './KeyboardShortcutsContext';

interface ActionMenuProps {
	open: boolean;
	handleClose: () => void;
	anchorEl?: null | HTMLElement;
	location?: [number, number] | null;
	menuActions: (ActionKey | 'divider')[];
	actionContext: ActionContext;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
	open,
	handleClose,
	anchorEl,
	location,
	menuActions,
	actionContext,
}) => {
	const { shortcuts } = useKeyboardShortcuts();

	// Function to generate shortcut display
	const getShortcutDisplay = (shortcut: string) => {
		const keys = shortcut.split('+');
		return keys.map((key, index) => (
			<React.Fragment key={index}>
				{index > 0 && <span style={{ margin: '0 2px' }}>+</span>}
				<kbd>{key.trim()}</kbd>
			</React.Fragment>
		));
	};

	return (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorReference={location ? 'anchorPosition' : 'anchorEl'}
			anchorPosition={
				location
					? {
							top: location[1],
							left: location[0],
						}
					: undefined
			}
			transitionDuration={100}
			PaperProps={{
				sx: {
					width: 350,
					maxWidth: '100%',
				},
			}}
		>
			{menuActions.map((actionKey, index) => {
				if (actionKey === 'divider') {
					return <Divider key={`divider-${index}`} />;
				}

				const action = actions[actionKey];
				const isDisabled = action.disabled ? action.disabled(actionContext) : false;
				const label = typeof action.label === 'function' ? action.label(actionContext) : action.label;
				const IconComponent = action.icon;
				const shortcut = shortcuts[actionKey] || '';

				return (
					<MenuItem
						key={actionKey}
						onClick={() => {
							action.handler(actionContext);
							handleClose();
						}}
						disabled={isDisabled}
					>
						{IconComponent && (
							<ListItemIcon>
								<IconComponent fontSize="small" />
							</ListItemIcon>
						)}
						<ListItemText primary={label} />
						{shortcut && (
							<ListItemSecondaryAction
								sx={{
									position: 'relative',
									transform: 'none',
									right: 0,
								}}
							>
								{getShortcutDisplay(shortcut)}
							</ListItemSecondaryAction>
						)}
					</MenuItem>
				);
			})}
		</Menu>
	);
};

export default ActionMenu;
