import { FormControlLabel, Switch, Tooltip, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { User } from '../../utils/user';
import { colorForUser } from '../../utils/colors';

export type UserAvatarProps = {
	user: User;
	onThemeModeChange?: (themeMode: string) => void;
};

export default function UserAvatar({ user, onThemeModeChange }: UserAvatarProps) {
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.SyntheticEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const navigate = useNavigate();

	const handleClose = (e: React.SyntheticEvent) => {
		//onSelectPrefabModel(e.currentTarget.id);
		if (e.currentTarget.id == 'logout') {
			fetch('/api/auth', { method: 'DELETE' }).then(() => {
				window.localStorage.removeItem('user');
				navigate('/login');
			});
		}
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip
				title={
					<React.Fragment>
						<Typography color="inherit">{user.name}</Typography>
					</React.Fragment>
				}
			>
				<Avatar
					sx={{
						color: '#ffffff',
						bgcolor: colorForUser(user),
						width: 32,
						height: 32,
						marginRight: 1,
						marginLeft: 1,
						fontSize: '12px',
						fontWeight: 400,
						cursor: 'pointer',
					}}
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={open ? handleClose : handleClick}
				>
					{user.name?.charAt(0) ?? 'G'}
				</Avatar>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				transitionDuration={0}
				PaperProps={{
					style: {
						width: 150,
					},
				}}
				anchorOrigin={
					isMobileOnly
						? {
								vertical: 'top',
								horizontal: 'left',
							}
						: {
								vertical: 'bottom',
								horizontal: 'left',
							}
				}
				transformOrigin={
					isMobileOnly
						? {
								vertical: 'bottom',
								horizontal: 'left',
							}
						: {
								vertical: 'top',
								horizontal: 'left',
							}
				}
			>
				<MenuItem onClick={handleClose} id="logout">
					<ListItemText>Log Out</ListItemText>
					<Typography variant="body2" color="text.secondary">
						CTR+L
					</Typography>
				</MenuItem>
				<MenuItem>
					<FormControlLabel
						control={
							<Switch
								checked={theme.palette.mode == 'light'}
								onChange={(e) => {
									e.target.checked ? onThemeModeChange('light') : onThemeModeChange('dark');
								}}
							/>
						}
						label={theme.palette.mode[0].toUpperCase() + theme.palette.mode.slice(1)}
					/>
				</MenuItem>
			</Menu>
		</div>
	);
}
