// SceneDrawer.tsx
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { SyntheticEvent, Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import Chat from './Chat';
import MaterialProperties from './MaterialProperties';
import ResizableContainer from '../ResizableContainer';
import SceneTree from './SceneTree';
import TransformationProperties from './TransformationProperties';
import { User } from '../../utils/user';
import MaterialList from './MaterialList';
import { useMaterials } from '../MaterialsContext';
import AssetManager from '../asset-library/AssetManager';
import useWebSocketChat from '../../utils/websocket-chat-hook';
import { useSelectedNodes } from '../SelectedNodesContext';
import EnvironmentList from './EnvironmentList';
import EnvironmentProperties from './EnvironmentProperties';
import { SceneTabMode } from '../../pages/FigurementApp';
import CameraList from './CameraList';
import CameraProperties from './CameraProperties';
import ShotConfigurator from './ShotConfigurator';
import SearchBar from '../SearchBar';
import ImageList from './ImageList';
import ImageProperties from './ImageProperties';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SceneProperties from './SceneProperties';
import { useLysReady } from '../LysReadyContext';

export type SceneDrawerProps = {
	user: User;
	sceneId: string;
	materialTypes: any;
	canEdit: boolean;
	newRenderCounter: number;
	setNewRenderCounter: (count: number) => void;
	newChatMessagesCounter: number;
	setNewChatMessagesCounter: Dispatch<SetStateAction<number>>;
	sceneTabMode: SceneTabMode | null;
	setSceneTabMode: (newTabIndex: SceneTabMode) => void;
};

export default function SceneDrawer({
	sceneId,
	canEdit,
	materialTypes,
	user,
	newRenderCounter,
	setNewRenderCounter,
	newChatMessagesCounter,
	setNewChatMessagesCounter,
	sceneTabMode,
	setSceneTabMode,
}: SceneDrawerProps) {
	const [language, setLanguage] = useState('');
	const [numberOfMessagesSeen, setNumberOfMessagesSeen] = useState(0);
	const { messages, sendMessage, error, isConnected, numberOfMessagesInHistory } = useWebSocketChat(
		sceneId,
		language,
	);
	const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodes();
	const { selectedMaterialIds, setSelectedMaterialIds } = useMaterials();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedCameraNodeIds, setSelectedCameraNodeIds] = useState<number[]>([]);
	const [activeCamera, setActiveCamera] = useState<number>(-1);
	const { lysIsReady } = useLysReady();

	useEffect(() => {
		setNumberOfMessagesSeen((n) => Math.max(n, numberOfMessagesInHistory));
	}, [numberOfMessagesInHistory]);

	useEffect(() => {
		if (sceneTabMode !== SceneTabMode.chat) {
			setNewChatMessagesCounter(messages.length - numberOfMessagesSeen);
		} else {
			setNumberOfMessagesSeen(messages.length);
		}
	}, [messages, sceneTabMode]);

	const [detailTabIndex, setDetailTabIndex] = useState<SceneTabMode>(SceneTabMode.scene);

	const handleDetailTabChange = (_event: SyntheticEvent, mode: SceneTabMode) => {
		setDetailTabIndex(mode);
	};

	const lastUpdateSource = useRef<string | null>(null);

	useEffect(() => {
		if (lastUpdateSource.current === 'selectedMaterialIds') {
			lastUpdateSource.current = null;
			return;
		}

		if (selectedNodeIds.length > 0) {
			const materialIds: string[] = selectedNodeIds
				.map((nodeId: number) => {
					const node = globalThis.lys.getNodeById(nodeId);
					if (node !== null) {
						const material = node.getMaterial();
						return material ? material.getId() : undefined;
					}
					return undefined;
				})
				.filter((id): id is string => id !== undefined);

			lastUpdateSource.current = 'selectedNodeIds';
			setSelectedMaterialIds(materialIds);
		}
	}, [selectedNodeIds]);

	useEffect(() => {
		if (lastUpdateSource.current === 'selectedNodeIds') {
			lastUpdateSource.current = null;
			return;
		}

		if (selectedMaterialIds.length > 0) {
			const allNodeIds = globalThis.lys.getAllNodeIds();
			const allNodes = allNodeIds.map((nodeId) => globalThis.lys.getNodeById(nodeId));
			const nodeIds: number[] = selectedMaterialIds
				.map((materialId: string) => {
					return allNodes
						.filter((node) => node && node.getMaterial()?.getId() === materialId)
						.map((node) => node!.getId());
				})
				.flat();

			const nodesToBeSelected = [...new Set(nodeIds)];
			lastUpdateSource.current = 'selectedMaterialIds';
			setSelectedNodeIds(nodesToBeSelected);
		} else {
			setSelectedNodeIds([]);
		}
	}, [selectedMaterialIds]);

	return (
		<Box display="flex" sx={{ flexGrow: 1, minHeight: 0, boxSizing: 'border-box' }} flexDirection="column">
			{sceneTabMode !== null && lysIsReady && (
				<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
					<Typography
						fontWeight="bold"
						sx={{
							ml: 1,
							display: 'flex',
							alignItems: 'center', // Align text and icon vertically
						}}
					>
						<span style={{ flexGrow: 1 }}>
							{sceneTabMode === SceneTabMode.scene && 'Scene tree'}
							{sceneTabMode === SceneTabMode.material && 'Materials'}
							{sceneTabMode === SceneTabMode.environment && 'Environment Lights'}
							{sceneTabMode === SceneTabMode.camera && 'Cameras'}
							{sceneTabMode === SceneTabMode.image && 'Image configurations'}
							{sceneTabMode === SceneTabMode.shot && 'Shots configurations'}
							{sceneTabMode === SceneTabMode.chat && 'Chat'}
							{sceneTabMode === SceneTabMode.render && 'Final renders'}
						</span>
						<IconButton
							aria-label="Close"
							onClick={() => setSceneTabMode(null)} // Function to set sceneTabMode to null
						>
							<CloseIcon />
						</IconButton>
					</Typography>
					<SearchBar onSearch={setSearchTerm} />

					{sceneTabMode === SceneTabMode.camera && sceneId && (
						<ResizableContainer>
							<CameraList
								search={searchTerm}
								selectedCameraNodeIds={selectedCameraNodeIds}
								setSelectedCameraNodeIds={setSelectedCameraNodeIds}
								setActiveCamera={setActiveCamera}
								activeCamera={activeCamera}
							/>
							<CameraProperties nodeId={activeCamera} />
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.environment && sceneId && (
						<ResizableContainer>
							<EnvironmentList search={searchTerm} sceneId={sceneId} />
							<EnvironmentProperties />
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.material && sceneId && (
						<ResizableContainer>
							<MaterialList search={searchTerm} sceneId={sceneId} />

							<Box
								style={{
									overflow: 'auto',
								}}
							>
								<MaterialProperties sceneId={sceneId} materialTypes={materialTypes} />
							</Box>
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.image && sceneId && (
						<ResizableContainer defaultSizes={[0.25, 0.75]}>
							<ImageList search={searchTerm} />

							<Box
								style={{
									overflow: 'auto',
								}}
							>
								<ImageProperties />
							</Box>
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.chat && sceneId && (
						<ResizableContainer>
							<Chat
								messages={messages}
								sendMessage={sendMessage}
								error={error}
								isConnected={isConnected}
								sceneId={sceneId}
								user={user}
								cameraPosition="1,1,0.5"
								language={language}
								setLanguage={setLanguage}
								setNewChatMessagesCounter={setNewChatMessagesCounter}
							/>
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.shot && sceneId && (
						<ResizableContainer>
							<ShotConfigurator />
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.render && sceneId && (
						<ResizableContainer>
							<AssetManager
								selectedPath={`home/Renders/${sceneId}.renders`}
								showFolders={false}
								recursive={true}
								filetypes={'.jpg'}
								sx={{ height: '100%' }}
							/>
						</ResizableContainer>
					)}

					{sceneTabMode === SceneTabMode.scene && (
						<ResizableContainer defaultSizes={[0.5, 0.5]}>
							<SceneTree search={searchTerm} />

							<>
								<Box>
									<Tabs
										value={detailTabIndex}
										onChange={handleDetailTabChange}
										aria-label="detail tabs"
										variant="fullWidth"
										sx={{
											minHeight: '48px',
										}}
									>
										<Tab
											label={
												<Typography
													variant="body1"
													component="span"
													sx={{ textTransform: 'none' }}
												>
													Position
												</Typography>
											}
										/>
										<Tab
											label={
												<Typography
													variant="body1"
													component="span"
													sx={{ textTransform: 'none' }}
												>
													Material
												</Typography>
											}
										/>
										<Tab
											label={
												<Typography
													variant="body1"
													component="span"
													sx={{ textTransform: 'none' }}
												>
													Properties
												</Typography>
											}
										/>
									</Tabs>
								</Box>

								<Box
									style={{
										overflow: 'auto',
									}}
								>
									{detailTabIndex === 0 && <TransformationProperties />}
									{detailTabIndex === 1 && (
										<MaterialProperties sceneId={sceneId} materialTypes={materialTypes} />
									)}
									{detailTabIndex === 2 && <SceneProperties />}
								</Box>
							</>
						</ResizableContainer>
					)}
				</Box>
			)}
		</Box>
	);
}
