import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Dispatch, KeyboardEvent, SetStateAction, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { AuthenticatedUser, isAuthenticatedUser, User } from '../../utils/user';
import { ChatMessage } from '../../utils/websocket-chat-hook';
import { CalloutLine, useCallouts } from '../../contexts/CalloutContext';
import { PointInNode, useSelectedNodes } from '../SelectedNodesContext';
import { colorFromString } from '../../utils/colors';
import { useTheme } from '@mui/system';

export type ChatProps = {
	sceneId: string;
	user: User | AuthenticatedUser;
	cameraPosition: string;
	language: string;
	setLanguage: (language: string) => void;
	setNewChatMessagesCounter: Dispatch<SetStateAction<number>>;
	messages: ChatMessage[];
	sendMessage: (message: Omit<ChatMessage, 'id'>) => void;
	error?: string;
	isConnected: boolean;
};

function isWrittenByMe(message: ChatMessage, me: User | AuthenticatedUser): boolean {
	if ('id' in me && !!message.userId) {
		return me.id === message.userId;
	}
	return me.name === message.userName;
}

export default function Chat({
	user,
	sceneId,
	cameraPosition,
	language,
	setLanguage,
	messages,
	sendMessage,
	error,
	isConnected,
}: ChatProps) {
	const [currentMessage, setCurrentMessage] = useState('');
	const lastMessageRef = useRef(null);
	const textFieldRef = useRef(null);

	function handleKeyUp(event: KeyboardEvent) {
		if (event.key === 'Enter' && currentMessage.trim() !== '') {
			const newMessage = {
				type: 'chat',
				userId: isAuthenticatedUser(user) ? user.id : null,
				userName: user.name,
				sceneId,
				contents: currentMessage,
				pointInNodes: selectedPointInNodes,
				cameraPosition,
			};
			handleSendMessage(newMessage);
			setCurrentMessage('');
		}
	}

	const handleSendMessage = (message: Omit<ChatMessage, 'id'>) => {
		sendMessage(message);
	};

	const [inputCallouts, setInputCallouts] = useState<CalloutLine[]>([]);
	const [showInputCallouts, setShowInputCallouts] = useState(false);

	const [messageCallouts, setMessageCallouts] = useState<CalloutLine[]>([]);
	const [showMessageCallouts, setShowMessageCallouts] = useState(false);

	const calloutsContext = useCallouts();
	const { selectedPointInNodes, selectedNodeIds } = useSelectedNodes();
	const theme = useTheme();

	const handleFocus = () => {
		setShowInputCallouts(true);
	};

	const handleBlur = () => {
		setShowInputCallouts(false);
		messageCallouts.forEach(calloutsContext.removeCallout);
		setMessageCallouts([]);
		inputCallouts.forEach(calloutsContext.removeCallout);
		setInputCallouts([]);
	};

	const handleMouseEnterCard = (event: SyntheticEvent, message: ChatMessage) => {
		const targetElement = event.currentTarget as HTMLElement;
		setMessageCallouts(
			message.pointInNodes.map((pin: PointInNode) => ({
				pointInNode: pin,
				targetElement,
				targetPosition: { x: 0, y: 30 },
				backgroundColor: colorFromString(message.userName ?? message.userId),
				strokeColor: theme.palette.divider,
				uniqueKey: `${message.id}_${pin.id}`,
			})),
		);
		setShowMessageCallouts(true);
	};

	const handleMouseLeaveCard = () => {
		messageCallouts.forEach(calloutsContext.removeCallout);
		setMessageCallouts([]);
		setShowMessageCallouts(false);
	};

	const [scrolled, setScrolled] = useState(0);

	function handleScroll() {
		setScrolled((prev) => prev + 1);
	}

	useEffect(() => {
		if (calloutsContext == undefined) return;

		if (showInputCallouts) {
			inputCallouts.forEach(calloutsContext.removeCallout);
			const newCallouts = selectedPointInNodes.map((pin: PointInNode) => ({
				pointInNode: pin,
				targetElement: textFieldRef.current,
				targetPosition: { x: 0, y: 10 },
				backgroundColor: colorFromString(user.name),
				strokeColor: theme.palette.divider,
				uniqueKey: `chat-input-${pin.id}`,
			}));
			setInputCallouts(newCallouts);
			newCallouts.forEach(calloutsContext.addCallout);
		} else {
			inputCallouts.forEach(calloutsContext.removeCallout);
			setInputCallouts([]);
		}
	}, [selectedPointInNodes, showInputCallouts, scrolled, selectedNodeIds]);

	useEffect(() => {
		if (calloutsContext == undefined) return;

		if (showMessageCallouts) {
			messageCallouts.forEach(calloutsContext.removeCallout);
			messageCallouts.forEach(calloutsContext.addCallout);
		} else {
			messageCallouts.forEach(calloutsContext.removeCallout);
		}
	}, [showMessageCallouts, scrolled]);

	useEffect(() => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [messages]);

	return (
		<>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: '8px',
				}}
				padding={1}
			>
				<FormControl>
					<InputLabel id="translate-chat-helper-label">Translate</InputLabel>
					<Select
						size="medium"
						label="Translate"
						labelId="translate-chat-helper-label"
						onChange={(e) => setLanguage(e.target.value)}
						value={language}
						disabled={!isConnected}
					>
						<MenuItem value="">
							<em>Do not translate</em>
						</MenuItem>
						<MenuItem value="en">English</MenuItem>
						<MenuItem value="es">Español</MenuItem>
						<MenuItem value="de">Deutszch</MenuItem>
					</Select>
				</FormControl>
				<Grid container sx={{ flexGrow: 1, overflowY: 'auto' }} onScroll={handleScroll}>
					<Stack spacing={2} flexGrow={1}>
						{messages.map((message, index) => (
							<Card
								key={index}
								ref={index === messages.length - 1 ? lastMessageRef : null}
								onMouseEnter={(e) => handleMouseEnterCard(e, message)}
								onMouseLeave={handleMouseLeaveCard}
								sx={{
									color: '#fff',
									border: '0.5px solid #fff',
									borderRadius: '8px',
									backgroundColor: colorFromString(message.userName),
									alignSelf: isWrittenByMe(message, user) ? 'end' : 'start',
								}}
							>
								<CardContent sx={{ pt: '8px' }}>
									<Typography sx={{ color: '#111', mt: '0px', mb: '8px' }}>
										{message.userName}:
									</Typography>
									<Typography variant="body1">{message.contents}</Typography>
								</CardContent>
							</Card>
						))}
					</Stack>
				</Grid>
				{error && (
					<Typography variant="body1" color="error">
						{error}
					</Typography>
				)}
				<input
					style={{
						backgroundColor: colorFromString(user.name),
					}}
					className="chat-input"
					placeholder="Write message"
					ref={textFieldRef}
					name="message"
					disabled={!isConnected}
					value={currentMessage}
					onFocus={handleFocus}
					onMouseEnter={handleFocus}
					onMouseLeave={handleBlur}
					onBlur={handleBlur}
					onChange={(e) => setCurrentMessage(e.target.value)}
					onKeyUp={handleKeyUp}
				/>
			</Box>
		</>
	);
}
