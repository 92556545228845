import { Box, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Password, PasswordChanged } from './Password';
import FButton from '../FButton';

export enum UserAction {
	CREATE,
	UPDATE,
}

export interface UserProfile {
	email: string;
	name: string;
	company?: string;
}

type ProfileParams = {
	profileAction: UserAction;
	mustChangePassword: boolean;
	user?: UserProfile;
	onSubmit: (user: UserProfile, password: string) => Promise<Response>;
	onCompleted: () => void;
};

export default function UserProfileForm({
	profileAction,
	mustChangePassword,
	user,
	onSubmit,
	onCompleted,
}: ProfileParams) {
	const [email, setEmail] = useState(initString(user?.email));
	const [emailError, setEmailError] = useState<string | null>(
		profileAction === UserAction.CREATE ? 'Email is required' : null,
	);

	const [name, setName] = useState(initString(user?.name));
	const [nameError, setNameError] = useState<string | null>('Name is required');

	const [company, setCompany] = useState(initString(user?.company));

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);

	const [submitError, setSubmitError] = useState('');

	function initString(s: string): string {
		return s ? s : '';
	}

	// validate input fields
	useEffect(() => {
		if (!email) {
			setEmailError('Email is required');
		} else {
			setEmailError(isProperEmail(email) ? null : 'Invalid email');
		}
		setNameError(name?.length > 2 ? null : 'Name is required');
	}, [email, name, password, passwordError, submitError, user]);

	function isProperEmail(email: string) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	function passwordChangedFunc(pwdChanged: PasswordChanged) {
		setPassword(pwdChanged.password);
		setPasswordError(pwdChanged.error);
	}

	async function handleSubmit() {
		const response: Response = await onSubmit({ email, name, company }, password);
		if (response.ok) {
			setSubmitError('');
			onCompleted();
		} else {
			setSubmitError(`Failed to submit request: ${response.status} ${response.statusText}`);
		}
	}

	return (
		<Box
			display={isMobileOnly ? undefined : 'flex'}
			justifyContent={isMobileOnly ? undefined : 'center'}
			alignItems={isMobileOnly ? undefined : 'center'}
			textAlign="center"
		>
			<Paper elevation={0} style={{ padding: 30, width: '300px' }}>
				<Typography style={{ paddingTop: 30 }} variant="h3">
					{profileAction === UserAction.CREATE ? 'Create' : 'Update'} Profile
				</Typography>

				<Box component="form" onSubmit={handleSubmit} noValidate>
					<div style={{ paddingTop: 30 }}>
						<TextField
							type="email"
							required
							fullWidth
							label={emailError ?? 'Email'}
							placeholder="Email"
							name="email"
							value={email}
							disabled={profileAction === UserAction.UPDATE}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>

					<div style={{ paddingTop: 30 }}>
						<TextField
							required
							fullWidth
							label={nameError ?? 'Name'}
							placeholder="Name"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>

					<div style={{ paddingTop: 30 }}>
						<TextField
							fullWidth
							label="Company"
							placeholder="Company"
							name="company"
							value={company}
							onChange={(e) => setCompany(e.target.value)}
						/>
					</div>
					<Password required={mustChangePassword} passwordChangedFunc={passwordChangedFunc} />
					<div style={{ paddingTop: 30 }}>
						<FButton
							variant="contained"
							disabled={!!emailError || !!nameError || !!passwordError}
							rel="noopener noreferrer"
							onClick={handleSubmit}
						>
							{profileAction === UserAction.CREATE ? 'Create' : 'Update'} Profile
						</FButton>
					</div>
					<div>
						{!!submitError && (
							<Typography variant="caption" color="red">
								{submitError}
							</Typography>
						)}
					</div>
				</Box>
			</Paper>
		</Box>
	);
}
