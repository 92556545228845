import { Orientation, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableContainer from '../ResizableContainer';

import { useRef } from 'react';
import { useComponentSize } from '../../utils/use-component-size';
import FolderTree from '../asset-library/FolderTree';
import * as FileApi from '../../services/file-api';
import FileGrid from './FileGrid';
import { LatestRenderContext } from '../../pages/FigurementApp';
import { useLibraryUpdateContext } from '../LibraryReloadContext'; // Adjust path as needed

export default function AssetManager({
	showFolders,
	recursive,
	filetypes,
	selectedPath,
	onSelectedPathChange,
}: {
	showFolders: boolean;
	recursive: boolean;
	filetypes: string;
	selectedPath: string;
	onSelectedPathChange?: (path: string) => void;
	sx?: SxProps<Theme>;
}) {
	const { doLibraryReload } = useLibraryUpdateContext();

	const navigate = useNavigate();

	const componentRef = useRef(null);
	const { width: componentWidth } = useComponentSize(componentRef);
	const [orientation, setOrientation] = useState<Orientation>('vertical');

	useEffect(() => {
		if (componentWidth > 600) setOrientation('horizontal');
		else setOrientation('vertical');
	}, [componentWidth]);
	const latestRenderDate = useContext(LatestRenderContext);

	const [folderTree, setFolderTree] = useState([]);

	useEffect(() => {
		FileApi.loadFolders().then(setFolderTree);
	}, []);
	useEffect(() => {
		FileApi.loadFilesForFolder(selectedPath, recursive, filetypes, showFolders).then((jsonData) => {
			if (showFolders) {
				setFileList(jsonData.filter((obj: FileApi.FSEntry) => obj.type !== 'folder'));
				setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === 'folder'));
			} else {
				setFileList(jsonData);
			}
		});
	}, [showFolders, latestRenderDate, doLibraryReload]);

	const [fileList, setFileList] = useState<FileApi.FSEntry[]>([]);
	const [folderList, setFolderList] = useState<FileApi.FSEntry[]>([]);

	const clickOnFolderIcon = async (id: string) => {
		onSelectNode(id);
		navigate(`/files/${id}`, { replace: true });
	};

	const onSelectNode = async (id: string): Promise<void> => {
		FileApi.loadFilesForFolder(id, recursive, filetypes, showFolders).then((jsonData) => {
			if (showFolders) {
				const files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== 'folder');
				setFileList(files);
				setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === 'folder'));
			} else {
				setFileList(jsonData);
			}
		});
		if (onSelectedPathChange) {
			onSelectedPathChange(id);
		}
	};

	return (
		<>
			<ResizableContainer defaultSizes={[0.3, 0.7]} orientation={orientation}>
				<FolderTree
					folderTree={folderTree}
					selectedNodeId={selectedPath}
					renderRootId={selectedPath}
					onSelectNode={onSelectNode}
				/>
				<Box sx={{ padding: 1, overflow: 'auto' }}>
					<FileGrid
						breadCrumb={[]}
						folderList={folderList}
						fileList={fileList}
						onSelectFolder={clickOnFolderIcon}
						onChange={() => onSelectNode(selectedPath)}
					/>
				</Box>
			</ResizableContainer>
		</>
	);
}
