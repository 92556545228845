import React, { useState, useCallback } from 'react';

type FileDropZoneProps = {
	// Function to handle dropped files
	onFilesDrop?: (files: File[]) => void;

	dropAllowed?: boolean;

	// Children can be either a render function or React nodes
	children: React.ReactNode | ((props: { isDragging: boolean }) => React.ReactNode);
};

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFilesDrop, children, dropAllowed = true }) => {
	const [isDragging, setIsDragging] = useState(false);

	const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleDragIn = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0 && dropAllowed) {
			setIsDragging(true);
		}
		e.dataTransfer.dropEffect = dropAllowed ? 'copy' : 'none';
	}, []);

	const handleDragOut = useCallback((e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	}, []);

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			setIsDragging(false);

			if (!dropAllowed) return;

			const droppedFiles = Array.from(e.dataTransfer.files);
			onFilesDrop?.(droppedFiles);
		},
		[onFilesDrop],
	);

	return (
		<div
			style={{ height: '100%' }}
			onDragEnter={handleDragIn}
			onDragLeave={handleDragOut}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		>
			{typeof children === 'function'
				? (children as (props: { isDragging: boolean }) => React.ReactNode)({
						isDragging,
					})
				: children}
		</div>
	);
};

export default FileDropZone;
