import { Menu, MenuItem, Typography } from '@mui/material';
import * as FileApi from '../../services/file-api';

export const sortFunctions: Record<string, SortFunctionWithName> = {
	modifiedTime: { name: 'Last modified', sortFunction: sortByModifiedTime },
	name: { name: 'By name A-Z', sortFunction: sortByName },
	nameReverse: {
		name: 'By name Z-A',
		sortFunction: (a, b) => -sortByName(a, b),
	},
};

export type SortFunction = (a: FileApi.FSEntry, b: FileApi.FSEntry) => number;
export type SortFunctionWithName = { name: string; sortFunction: SortFunction };

export type SortMenuProps = {
	anchorEl: HTMLElement;
	onClose: () => void;
	submit: (SortFunction) => void;
};

function sortByModifiedTime(a: FileApi.FSEntry, b: FileApi.FSEntry): number {
	return a.modifiedAgo - b.modifiedAgo;
}

function sortByName(a: FileApi.FSEntry, b: FileApi.FSEntry): number {
	return a.label.localeCompare(b.label);
}

export function SortMenu({ anchorEl, onClose, submit }: SortMenuProps) {
	function onSubmit(sortFunction: SortFunctionWithName) {
		submit(sortFunction);
		onClose();
	}

	return (
		<div>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				open={true}
				anchorEl={anchorEl}
				onClose={onClose}
			>
				{Object.entries(sortFunctions).map(([key, sortFunctionWithName]) => (
					<MenuItem
						key={key}
						onClick={() => {
							onSubmit(sortFunctionWithName);
						}}
						sx={{
							fontSize: '0.75rem', // Set font size for the MenuItem text
						}}
					>
						{sortFunctionWithName.name}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
