import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export interface PasswordChanged {
	password: string;
	error: boolean;
}

type PasswordProps = {
	required: boolean;
	passwordChangedFunc: (pwd: PasswordChanged) => void;
};

export function Password({ required, passwordChangedFunc }: PasswordProps) {
	const [password, setPassword] = useState<string | null>('');
	const [passwordError, setPasswordError] = useState<string | null>(null);
	const [passwordWarning, setPasswordWarning] = useState<string | null>(null);

	// validate input fields
	useEffect(() => {
		if (!required && !password) {
			setPasswordError(null);
		} else if (required && !password) {
			setPasswordError('Password is required');
		} else if (password.length < 8) {
			setPasswordError('Password must be at least 8 characters');
		} else {
			setPasswordError(null);
			fetch(`/api/auth/passwords/${password}`).then(async (res) => {
				if (res.status === 200) {
					setPasswordWarning(null);
				} else {
					const message = await res.text();
					setPasswordWarning(message);
				}
			});
		}

		const pwdChanged: PasswordChanged = {
			password: password,
			error: !(!required && !password) && !!passwordError,
		};
		passwordChangedFunc(pwdChanged);
	}, [password, passwordError]);

	return (
		<Box style={{ paddingTop: 30 }}>
			<TextField
				type="password"
				required={required}
				fullWidth
				placeholder="Password"
				label={passwordError ?? 'Password'}
				name="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
			/>
			<Typography variant="body1" color="red" fontSize={12}>
				{passwordError && !!password ? passwordError : ' '}
			</Typography>
			<Typography variant="body2" color="orange" fontSize={12}>
				{passwordWarning && !!password ? passwordWarning : ' '}
			</Typography>
		</Box>
	);
}
