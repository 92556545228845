import { isAuthenticatedUser, User } from './user';

export function colorForUser(user: User): string {
	if (isAuthenticatedUser(user)) {
		// base color on user id
		return colorFromString(user.name ?? user.id);
	}
	return colorFromString(user.name);
}

export function colorFromString(hashInput: string) {
	const hash = hashInput.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
	const hue = hash % 360;
	return `hsl(${hue}, 50%, 50%)`;
}
