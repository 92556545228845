import ocFullJS from './lib/opencascade_custom.js';
// @ts-expect-error
import ocFullWasm from './lib/opencascade_custom.wasm?url';

export class StepImporter {
	constructor() {
		// @ts-expect-error
		this.ocInstance = ocFullJS({
			locateFile: function () {
				return ocFullWasm;
			},
		});
	}

	/**
	 * Step to glb converter
	 * @param {ArrayBuffer} data
	 * @param {Object} configurations //Typescript type StepImporterConfigurations
	 * @returns {Uint8Array} glb data after conversion
	 */
	async importStep(data, configurations) {
		const startTime = new Date();
		const oc = await this.ocInstance;

		const reader = new oc.STEPCAFControl_Reader_1();

		oc.FS.createDataFile('/', 'model.stp', data, true, true, true);
		reader.ReadFile('/model.stp');
		let elapsedTime = new Date() - startTime;
		console.log(`stepimporter: file loaded successfully!     Converting to OCC now... time=${elapsedTime}ms`);

		const docHandle = new oc.Handle_TDocStd_Document_2(
			new oc.TDocStd_Document(new oc.TCollection_ExtendedString_1()),
		);
		reader.Transfer_1(docHandle, new oc.Message_ProgressRange_1());
		//const numRootsTransferred = reader.TransferRoots(new oc.Message_ProgressRange_1());    // Translate all transferable roots to OpenCascade
		//const shape = reader.OneShape();         // Obtain the results of translation in one OCCT shape
		elapsedTime = new Date() - startTime;
		console.log(`stepimporter:  converted successfully!  Triangulating now... time=${elapsedTime}ms`);

		const shapeTool = oc.XCAFDoc_DocumentTool.ShapeTool(docHandle.get().Main()).get();
		const rootLabels = new oc.TDF_LabelSequence_1();
		shapeTool.GetFreeShapes(rootLabels);

		const shape = new oc.TopoDS_Shape();
		oc.XCAFDoc_ShapeTool.GetShape_1(rootLabels.Value(1), shape);

		// Tell OpenCascade that we want our shape to get meshed

		const linearDeflection = configurations.tessellationLineDeflection ?? 0.1;
		const isRelative = false;
		const angularDeflection = configurations.tessellationAngle ?? 1;
		const parallel = true;
		new oc.BRepMesh_IncrementalMesh_2(shape, linearDeflection, isRelative, angularDeflection, parallel);

		// Export a GLB file (this will also perform the meshing)
		const cafWriter = new oc.RWGltf_CafWriter(new oc.TCollection_AsciiString_2('./file.glb'), true);
		cafWriter.Perform_2(
			docHandle,
			new oc.TColStd_IndexedDataMapOfStringString_1(),
			new oc.Message_ProgressRange_1(),
		);
		// Read the GLB file from the virtual file system
		const glbFile = oc.FS.readFile('./file.glb', { encoding: 'binary' });
		oc.FS.unlink('model.stp');
		oc.FS.unlink('file.glb');
		elapsedTime = new Date() - startTime;
		console.log(`stepimporter: converted to GLB successfully!... time=${elapsedTime}ms`);
		return glbFile;

		const blob = new Blob([glbFile.slice().buffer], {
			type: 'model/gltf-binary',
		});

		const url = URL.createObjectURL(blob);
		console.log(url);
		console.log(glbFile.slice().buffer);

		oc.FS.unlink('model.stp');
		oc.FS.unlink('file.glb');
		return blob;
	}
}
