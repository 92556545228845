import { useState, useEffect, useCallback } from 'react';

function useGlobalUpdate(updateName) {
	const [updateCount, setUpdateCount] = useState(0);

	const internalUpdateFunction = useCallback(() => {
		setUpdateCount((n) => n + 1);
	}, []);

	useEffect(() => {
		const registryName = `${updateName}Registry`;

		// Initialize the registry if it doesn't exist
		globalThis[registryName] = globalThis[registryName] || [];

		// Add the update function to the registry
		globalThis[registryName].push(internalUpdateFunction);

		// Define the global update function if it doesn't exist
		if (!globalThis[updateName]) {
			globalThis[updateName] = () => {
				globalThis[registryName].forEach((fn) => fn());
			};
		}

		// Cleanup on unmount
		return () => {
			globalThis[registryName] = globalThis[registryName].filter((fn) => fn !== internalUpdateFunction);
			if (globalThis[registryName].length === 0) {
				delete globalThis[updateName];
				delete globalThis[registryName];
			}
		};
	}, [updateName, internalUpdateFunction]);

	return updateCount;
}

export default useGlobalUpdate;
