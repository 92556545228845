import { Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {
	AnyParameter,
	ParameterValue,
	SimpleMaterial,
	TextParameter,
	TextureParameter,
	useMaterials,
} from '../MaterialsContext';
import { ChangeEvent, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { FileImporter } from '../toolbar/FileImporter';
import { ThumbnailWithRetry } from '../asset-library/ThumbnailWithRetry';
import FileDropZone from '../FileDropZone';
import { useLoaderData } from 'react-router-dom';
import { Access } from '../share/AccessMenu';
import StyledBox from '../StyledBox';
import FTextField from '../FTextField';
import NumberInput from '../NumberInput';
import { AutoFixHigh } from '@mui/icons-material';

interface MaterialPropertiesProps {
	materialTypes: string[];
	sceneId: string;
}

export default function MaterialProperties({ materialTypes, sceneId }: MaterialPropertiesProps) {
	const { selectedMaterialProperties, setMaterialType, setMaterialParameter, materials } = useMaterials();

	const fileImporter = useRef<typeof FileImporter>();
	const uploadingTextureParameter = useRef<TextureParameter>();
	const [name, setName] = useState(selectedMaterialProperties[0]?.name || '');
	const [editingTextParamValue, setEditingTextParamValue] = useState<{ name: string; value: string } | null>(null);
	const [editingTextParamName, setEditingTextParamName] = useState<{
		name: string;
		newName: string;
		value: string;
	} | null>(null);

	const { access } = useLoaderData() as { access: Access };

	const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setName(e.target.value);
	};

	useEffect(() => {
		if (selectedMaterialProperties[0]) {
			setName(selectedMaterialProperties[0].name || '');
		}
	}, [selectedMaterialProperties]);

	const backgroundColor = (color: string) => {
		const s = { background: '#ff0000', border: '1px solid #C4C4C4', cursor: 'pointer' };
		s.background = color;
		return s;
	};

	function throttle<T extends (...args: Parameters<T>) => void>(
		func: T,
		limit: number,
	): (...args: Parameters<T>) => void {
		let inThrottle: boolean;
		return function (...args: Parameters<T>): void {
			if (!inThrottle) {
				func.apply(this, args);
				inThrottle = true;
				setTimeout(() => (inThrottle = false), limit);
			}
		};
	}

	// Dragging a slider should not overload the server with calls.
	const throttledSetMaterialParameter = useCallback(
		throttle((name: string, value: ParameterValue) => {
			setMaterialParameter(name, value);
		}, 100),
		[],
	);

	function handleClickTexture(entry: TextureParameter): void {
		if (fileImporter.current) {
			uploadingTextureParameter.current = entry;
			(fileImporter.current as unknown as FileImporter).openDialog();
		}
	}

	function handleUnselectTexture(entry: TextureParameter): void {
		setMaterialParameter(entry.name, null);
	}

	function handleDroppedFiles(files: File[], entry: TextureParameter): void {
		if (files.length === 0) return;

		uploadingTextureParameter.current = entry;

		const reader = new FileReader();
		const filename = files[0].name;
		reader.onload = (e: ProgressEvent<FileReader>) => handleUploadTexture(e.target.result as ArrayBuffer, filename);
		reader.readAsArrayBuffer(files[0]);
	}

	function handleUploadTexture(buffer: ArrayBuffer, filename: string): void {
		setTimeout(() => {
			const ptr = window.lys._malloc(buffer.byteLength);
			window.lys.HEAPU8.set(new Uint8Array(buffer), ptr);
			const textureId = globalThis.lys.createTexture(filename, ptr, buffer.byteLength);

			if (uploadingTextureParameter.current) {
				setMaterialParameter(uploadingTextureParameter.current.name, textureId);
			}
		}, 1);
	}

	function handleTextParameterValueChange(
		event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,
		entry: TextParameter,
	) {
		setEditingTextParamValue({ name: entry.name, value: event.currentTarget.value });
	}

	function handleTextParameterValueFocus(entry: TextParameter) {
		setEditingTextParamValue({ ...entry });
	}

	function handleTextParameterValueBlur() {
		if (editingTextParamValue == null) return;
		const { name, value } = editingTextParamValue;
		if (name !== '') setMaterialParameter(name, value);
		setEditingTextParamValue(null);
	}

	function handleTextParameterValueKeyDown(e: KeyboardEvent) {
		if (e.key === 'Enter') {
			handleTextParameterValueBlur();
			setEditingTextParamValue(null);
		} else if (e.key === 'Escape') {
			setEditingTextParamValue(null);
		}
	}

	const customMaterialFields = (): Set<string> => {
		const all = new Set<string>(
			materials
				.map((m: SimpleMaterial) => {
					const material = globalThis.lys.getMaterialById(m.id);
					return material ? JSON.parse(material.toJSONString()) : null;
				})
				.filter(Boolean)
				.flatMap((material) =>
					material.parameters.filter((p: AnyParameter) => p.type === 'text').map((p: AnyParameter) => p.name),
				),
		);

		return all;
	};

	function handleAddCommonMaterialInfoFields() {
		if (selectedMaterialProperties.length === 0) return;

		const material = selectedMaterialProperties[0];
		const usedFields = new Set(material.parameters.map((p) => p.name));
		customMaterialFields()
			.difference(usedFields)
			.forEach((p) => {
				material.parameters.push({ name: p, type: 'text', value: '' });
				setMaterialParameter(p, '');
			});
	}

	function handleAddMaterialInfoField() {
		if (selectedMaterialProperties.length === 0) return;
		setEditingTextParamValue(null);
		const material = selectedMaterialProperties[0];
		const fieldName = 'Custom Field ' + (material.parameters.filter((p) => p.type === 'text').length + 1);
		console.debug('ADD PARAM', fieldName);
		setMaterialParameter(fieldName, '');
		setEditingTextParamName({ name: fieldName, value: '', newName: fieldName });
	}

	function deleteTextParameter(entryName: string) {
		setMaterialParameter(entryName, null);
	}

	function handleTextParameterNameChange(
		event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,
		entry: TextParameter,
	) {
		setEditingTextParamName({ ...entry, newName: event.currentTarget.value });
	}

	function handleTextParameterNameFocus(entry: TextParameter, e: SyntheticEvent) {
		setEditingTextParamName({ ...entry, newName: entry.name });
		e.preventDefault();
	}

	function handleTextParameterNameBlur() {
		if (editingTextParamName == null) return;
		const { name, newName, value } = editingTextParamName;
		if (name !== newName) {
			setMaterialParameter(name, null);
			setMaterialParameter(newName, value);
		}
		setEditingTextParamName(null);
		setEditingTextParamValue({ name: newName, value });
	}

	function handleTextParameterNameKeyDown(e: KeyboardEvent) {
		if (e.key === 'Enter') {
			handleTextParameterNameBlur();
			setEditingTextParamName(null);
		} else if (e.key === 'Escape') {
			setEditingTextParamName(null);
		}
	}

	function paramToUI(entry: AnyParameter, index: number, key: string) {
		switch (entry.type) {
			case 'text': {
				return (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							paddingBottom: 1,
						}}
						key={`text_param_${key}`}
					>
						<Grid xs={8}>
							{editingTextParamName?.name === entry.name ? (
								<FTextField
									value={editingTextParamName.newName}
									name={`name_field_${key}`}
									onChange={(e) => handleTextParameterNameChange(e, entry)}
									onKeyDown={handleTextParameterNameKeyDown}
									onBlur={handleTextParameterNameBlur}
									focused={editingTextParamName?.name === entry.name}
									autoFocus={editingTextParamName?.name === entry.name}
									fullWidth
									size="small"
								/>
							) : (
								<Typography
									onDoubleClick={(e) => handleTextParameterNameFocus(entry, e)}
									flex="1 1 50%"
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
								>
									{entry.name}
								</Typography>
							)}
						</Grid>

						<Grid xs={3} sx={{ height: '32px' }}>
							<FTextField
								fullWidth
								name={`value_field_${key}`}
								value={
									editingTextParamValue?.name === entry.name
										? editingTextParamValue.value
										: entry.value
								}
								onChange={(e) => handleTextParameterValueChange(e, entry)}
								onKeyDown={(e: KeyboardEvent) => handleTextParameterValueKeyDown(e)}
								onFocus={() => handleTextParameterValueFocus(entry)}
								onBlur={handleTextParameterValueBlur}
								focused={editingTextParamValue?.name === entry.name}
								autoFocus={editingTextParamValue?.name === entry.name}
							/>
						</Grid>
						<Grid xs={1}>
							<IconButton tabIndex={-1} onClick={() => deleteTextParameter(entry.name)}>
								<DeleteIcon></DeleteIcon>
							</IconButton>
						</Grid>
					</Grid>
				);
			}
			case 'double': {
				return (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							paddingBottom: 1,
						}}
						key={index}
					>
						<Grid xs={8}>{entry.name}</Grid>

						<Grid xs={4} sx={{ height: '32px' }}>
							<NumberInput
								fullWidth
								value={entry.value}
								setValue={(v) => {
									setMaterialParameter(entry.name, v);
								}}
								max={2.0}
								min={0.0}
								step={0.01}
								precision={3}
							/>
						</Grid>
						<Grid
							xs={12}
							sx={{
								ml: 3,
								mr: 3,
							}}
						>
							<Slider
								size="small"
								style={{ width: '100%' }}
								value={entry.value * 50} // Display either internal or external value
								onChange={(_e, newValue: number) => {
									setMaterialParameter(entry.name, newValue / 50);
								}}
								aria-labelledby="input-slider"
								sx={{
									MozUserSelect: 'none',
									WebkitUserSelect: 'none',
									msUserSelect: 'none',
								}}
							/>
						</Grid>
					</Grid>
				);
			}
			case 'color': {
				const value = entry.value as string;
				return (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							paddingBottom: 2,
						}}
						key={index}
					>
						<Grid key={entry.name} xs>
							{entry.name}
						</Grid>
						<Grid
							xs={4}
							style={backgroundColor(value)}
							onClick={(e) => {
								(e.currentTarget.childNodes[0] as HTMLElement)?.focus();
								(e.currentTarget.childNodes[0] as HTMLElement)?.click();
							}}
						>
							<input
								style={{ opacity: 0, height: '30px', cursor: 'pointer' }}
								type="color"
								value={value}
								onInput={(e) => throttledSetMaterialParameter(entry.name, e.currentTarget.value)}
							/>
						</Grid>
					</Grid>
				);
			}
			case 'texture': {
				const selected: boolean = !!entry.value;
				return (
					<FileDropZone
						key={index}
						onFilesDrop={(files) => handleDroppedFiles(files, entry)}
						dropAllowed={access === 'can edit'}
					>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								paddingBottom: 2,
							}}
						>
							<Grid key={entry.name} xs>
								{entry.name}
							</Grid>
							<Grid xs={4}>
								{selected ? (
									<StyledBox
										sx={{
											display: 'flex',
											alignItems: 'center',
											flexWrap: 'nowrap',
										}}
									>
										<ThumbnailWithRetry
											src={`/api/scenes/${sceneId}/textures/${entry.value}/thumbnail`}
											onClick={() => handleClickTexture(entry)}
										/>
										<IconButton onClick={() => handleUnselectTexture(entry)} size="small">
											<DeleteIcon />
										</IconButton>
									</StyledBox>
								) : (
									<Button
										variant="outlined"
										sx={{
											flexGrow: 0,
											textTransform: 'none',
											width: '100%',
										}}
										color="primary"
										startIcon={<AddIcon />}
										onClick={() => handleClickTexture(entry)}
									>
										Select
									</Button>
								)}
							</Grid>
						</Grid>
					</FileDropZone>
				);
			}
		}
	}

	const nonWord = /\W/g;
	const builtInElements = [];
	const customElements = [];
	if (selectedMaterialProperties.length > 0) {
		const material = selectedMaterialProperties[0];
		for (let i = 0; i < material.parameters.length; i++) {
			const entry = material.parameters[i];
			const key = entry.name.replaceAll(nonWord, '');
			if (entry.type === 'text') {
				customElements.push(paramToUI(entry, i, key));
			} else {
				builtInElements.push(paramToUI(entry, i, key));
			}
		}
	}

	return (
		<Box sx={{ flex: '1 1 auto', pr: 1, pl: 1, overflow: 'auto', minHeight: 0, paddingTop: 1 }}>
			{selectedMaterialProperties[0] && (
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{ paddingBottom: 2 }}
				>
					<Grid xs={3} sx={{ paddingBottom: 2 }}>
						Name
					</Grid>
					<Grid xs={8} sx={{ paddingBottom: 2 }}>
						<FTextField
							id="standard-basic"
							value={name}
							fullWidth
							disabled={!selectedMaterialProperties[0]}
							InputLabelProps={
								selectedMaterialProperties[0] && {
									shrink: true,
								}
							}
							sx={{
								'& legend': { display: 'none' },
								'& fieldset': { top: 0 },
							}}
							onChange={handleNameChange}
							onBlur={(e) =>
								globalThis.lys.setMaterialName(
									selectedMaterialProperties[0].id,
									e.currentTarget.value,
									true,
								)
							}
							onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
								if (e.key === 'Enter') {
									(e.target as HTMLInputElement).blur();
								}
							}}
						/>
					</Grid>

					<Grid xs={4}>Type</Grid>
					<Grid xs={8}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							e2e-id="select-material-type"
							onChange={(e) => setMaterialType(e.target.value)}
							size="small"
							value={selectedMaterialProperties[0] && selectedMaterialProperties[0].type}
							inputProps={{ 'aria-label': 'Without label' }}
							style={{ width: '100%' }}
							sx={{
								'& legend': { display: 'none' },
								'& fieldset': { top: 0 },
								'& .MuiSelect-select': {
									padding: '8px', // Set the padding here
								},

								height: '32px', // Set the height to 32px
							}}
						>
							{materialTypes &&
								materialTypes.map((name) => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								))}
						</Select>
					</Grid>
				</Grid>
			)}
			{builtInElements}
			<Divider sx={{ mb: '8px' }} />
			<Typography variant="body1" sx={{ color: 'text.secondary' }}>
				Custom Material Parameters
			</Typography>
			{customElements}
			{selectedMaterialProperties[0] && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Tooltip title="Add material info" arrow>
						<IconButton aria-label="Add material info" onClick={handleAddMaterialInfoField}>
							<AddIcon fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Add common material info fields" arrow>
						<IconButton aria-label="Add common material fields" onClick={handleAddCommonMaterialInfoFields}>
							<AutoFixHigh fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			<FileImporter ref={fileImporter} onFileRead={(buffer, filename) => handleUploadTexture(buffer, filename)} />
		</Box>
	);
}
