import { useState } from 'react';
import { Spinner } from '../Spinner';
import { Box, SxProps } from '@mui/material';

export interface ThumbnailWithRetryProps {
	src: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	sx?: SxProps;
}

export function ThumbnailWithRetry({ src, onClick, style, sx }: ThumbnailWithRetryProps) {
	const [loading, setLoading] = useState(false);

	const retry = (e) => {
		const img: HTMLImageElement = e.currentTarget;
		setLoading(true);
		setTimeout(() => {
			img['_src'] ??= img.src;
			img.src = img['_src'] + '?t=' + Date.now();
			setLoading(false);
		}, 5000);
	};

	return (
		<Box
			style={{
				position: 'relative',
				display: 'inline flex',
				width: '100%',
				...style,
			}}
			sx={sx}
		>
			{loading && <Spinner description={null} />}
			{loading && (
				<div
					style={{
						width: '100%',
						height: style?.height ?? '32px',
						objectFit: 'cover',
						backgroundColor: '#777',
						zIndex: 5,
						position: 'absolute',
						top: '0px',
						left: '0px',
					}}
				/>
			)}
			<img
				src={src}
				style={{
					width: '100%',
					height: style?.height ?? '32px',
					objectFit: 'cover',
				}}
				onError={retry}
				onClick={onClick}
			/>
		</Box>
	);
}
