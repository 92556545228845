import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Alert } from '@mui/material';

export type SpinnerProps = {
	description?: string;
};

export const Spinner = ({ description }: SpinnerProps) => {
	const [showDescription, setShowDescription] = useState(!!description);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | undefined;
		if (description) {
			setShowDescription(true);
			timer = setTimeout(() => {
				setShowDescription(false);
			}, 1000); // Hide after 1 second
		} else {
			setShowDescription(false);
		}

		// Cleanup the timer if the component unmounts or description changes
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [description]);

	const handleMouseEnter = () => {
		if (description) {
			setShowDescription(true);
		}
	};

	const handleMouseLeave = () => {
		if (description) {
			setShowDescription(false);
		}
	};

	return (
		<Box
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				position: 'absolute',
				bottom: 0,
				right: 0,
				display: 'flex',
				alignItems: 'center',
				padding: '8px',
				borderRadius: '4px',
				backgroundColor: 'transparent',
				transition: 'opacity 0.3s ease',
				zIndex: 1000,
			}}
		>
			{/* Show the Alert with CircularProgress as the icon */}
			{description && (
				<Alert
					severity="info"
					icon={<CircularProgress disableShrink size={16} />}
					sx={{
						fontSize: '0.875rem',
						padding: '4px 8px',
						whiteSpace: 'nowrap',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{description}
				</Alert>
			)}
		</Box>
	);
};
