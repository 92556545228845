// CameraList.tsx

import React from 'react';
import SceneTreeViewer from './SceneTreeViewer'; // Adjust the import path as needed
import { IconButton } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import useGlobalUpdate from '../../utils/use-global-update';

interface Props {
	search: string;
	selectedCameraNodeIds: number[];
	setSelectedCameraNodeIds: (ids: number[]) => void;
	activeCamera: number;
	setActiveCamera: (cameraId: number) => void;
}

const CameraList = ({
	search,
	selectedCameraNodeIds,
	setSelectedCameraNodeIds,
	activeCamera,
	setActiveCamera,
}: Props) => {
	const cameraListUpdated = useGlobalUpdate('updateCameraList');

	const insertDefaultCamerasHandler = () => {
		// Insert standard views:  front, back, top, bottom, left, right
		const cameraNames = ['Front', 'Back', 'Top', 'Bottom', 'Left', 'Right'];
		cameraNames.forEach((name) => {
			const id = globalThis.lys.createCameraGenId(name);

			const eye = [0, 0, 0];
			const lookAt = [0, 0, 0];
			// todo: much more intelligent setting up of eye and lookAt
			// set eye based on cameraNames
			switch (name) {
				case 'Front':
					eye[2] = 1;
					break;
				case 'Back':
					eye[2] = -1;
					break;
				case 'Top':
					eye[1] = 1;
					eye[2] = 0.0000001;
					break;
				case 'Bottom':
					eye[1] = -1;
					eye[2] = 0.0000001;
					break;
				case 'Left':
					eye[0] = -1;
					break;
				case 'Right':
					eye[0] = 1;
					break;
			}
			globalThis.lys.updateCameraEyeLookAt(id, eye[0], eye[1], eye[2], lookAt[0], lookAt[1], lookAt[2]);
			globalThis.lys.centerAndFitCamera(id, true);
		});
	};

	return (
		<SceneTreeViewer
			search={search}
			selectedNodeIds={selectedCameraNodeIds}
			setSelectedNodeIds={setSelectedCameraNodeIds}
			setCalloutPins={() => {}}
			setActiveCamera={setActiveCamera}
			activeCamera={activeCamera}
			rootNodeId={-2}
			forceUpdate={cameraListUpdated}
			actionsList={['addCamera', 'createGroup', 'clone', 'delete']}
			extraButton={
				<IconButton aria-label="Insert Default Cameras" onClick={() => insertDefaultCamerasHandler()}>
					<AutoFixHighIcon fontSize="small" />
				</IconButton>
			}
		/>
	);
};

export default CameraList;
