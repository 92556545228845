import { useEffect, useState } from 'react';

// Custom hook to observe component size
export function useComponentSize(ref) {
	const [size, setSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = (entries) => {
			for (const entry of entries) {
				setSize({
					width: entry.contentRect.width,
					height: entry.contentRect.height,
				});
			}
		};

		const observer = new ResizeObserver(handleResize);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref]);

	return size;
}
