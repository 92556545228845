import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberInput from '../NumberInput';

interface PropertyControlProps {
	label: string;
	value: number;
	onChange: (value: number) => void;
	min?: number;
	max?: number;
	step?: number;
	disabled?: boolean;
}

const PropertyControl: React.FC<PropertyControlProps> = ({
	label,
	value,
	onChange,
	min = 0,
	max = 2,
	step = 0.1,
	disabled = false,
}) => (
	<Box sx={{ pt: 1 }}>
		<Grid
			container
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				paddingBottom: 1,
			}}
		>
			<Grid item xs={8}>
				{label}
			</Grid>

			<Grid item xs={4} sx={{ height: '32px' }}>
				<NumberInput value={value} setValue={(value) => onChange(value)} disabled={disabled} />
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					ml: 3,
					mr: 3,
				}}
			>
				<Slider
					size="small"
					style={{ width: '100%' }}
					value={value}
					onChange={(e, newValue) => onChange(newValue as number)}
					min={min}
					max={max}
					step={step}
					disabled={disabled}
					aria-labelledby="input-slider"
					sx={{
						MozUserSelect: 'none',
						WebkitUserSelect: 'none',
						msUserSelect: 'none',
					}}
				/>
			</Grid>
		</Grid>
	</Box>
);

const EnvironmentProperties: React.FC = () => {
	const [environmentHeight, setEnvironmentHeight] = useState(1);
	const [environmentSize, setEnvironmentSize] = useState(1);
	const [flattenGround, setFlattenGround] = useState(false);
	const [infiniteEnvironment, setInfiniteEnvironment] = useState(false);

	// Sync with lys
	const syncData = () => {
		setEnvironmentHeight(globalThis.lys.getEnvironmentHeight());
		setEnvironmentSize(globalThis.lys.getEnvironmentSize());
		setFlattenGround(globalThis.lys.getFlattenGround());
		setInfiniteEnvironment(globalThis.lys.getInfiniteEnvironment());
	};

	useEffect(() => {
		globalThis.updateEnvironmentProperties = syncData;
		syncData();

		return () => {
			delete globalThis.updateEnvironmentProperties;
		};
	}, []);

	// Update lys on changes
	const handleHeightChange = (value: number) => {
		setEnvironmentHeight(value);
		globalThis.lys.setEnvironmentHeight(value, true);
	};

	const handleSizeChange = (value: number) => {
		setEnvironmentSize(value);
		globalThis.lys.setEnvironmentSize(value, true);
	};

	const handleFlattenGroundChange = (checked: boolean) => {
		setFlattenGround(checked);
		globalThis.lys.setFlattenGround(checked, true);
	};

	const handleInfiniteEnvironmentChange = (checked: boolean) => {
		setInfiniteEnvironment(checked);
		globalThis.lys.setInfiniteEnvironment(checked, true);
	};

	return (
		<Box sx={{ flex: '1 1 auto', p: 1, overflow: 'auto', minHeight: 0 }}>
			{/* Infinite Environment Checkbox */}
			<Box sx={{ m: 0, mb: 2 }}>
				<FormControlLabel
					control={
						<Checkbox
							checked={infiniteEnvironment}
							onChange={(e) => handleInfiniteEnvironmentChange(e.target.checked)}
						/>
					}
					label="Infinite Environment"
				/>
			</Box>
			{!infiniteEnvironment && (
				<>
					<PropertyControl
						label="Environment Size"
						value={environmentSize}
						onChange={handleSizeChange}
						min={0}
						max={1000}
						step={1}
						disabled={infiniteEnvironment}
					/>

					<Box sx={{ m: 0 }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={flattenGround}
									onChange={(e) => handleFlattenGroundChange(e.target.checked)}
									disabled={infiniteEnvironment}
								/>
							}
							label="Flatten Ground"
						/>
					</Box>

					{flattenGround && (
						<PropertyControl
							label="Environment Height"
							value={environmentHeight}
							onChange={handleHeightChange}
							min={0}
							max={1}
							step={0.01}
							disabled={infiniteEnvironment}
						/>
					)}
				</>
			)}
		</Box>
	);
};

export default EnvironmentProperties;
