import React, { useEffect, useState } from 'react';
import { ActionKey, ActionContext } from '../actions';
import { useSelectedNodes } from '../SelectedNodesContext';
import { useLibraryUpdateContext } from '../LibraryReloadContext';
import ActionMenu from '../ActionMenu'; // Import the common component
import { useCallouts } from '../../contexts/CalloutContext';

interface CanvasContextMenuProps {
	location: [number, number] | null;
	sceneId: string;
	eventCoordinates: [number, number] | null;
	handleClose: () => void;
}

const CanvasContextMenu: React.FC<CanvasContextMenuProps> = ({ location, eventCoordinates, handleClose, sceneId }) => {
	const { selectedNodeIds, setSelectedNodeIds, selectedPointInNodes } = useSelectedNodes();
	const { reloadLibrary } = useLibraryUpdateContext();

	const { setCalloutBoxes: setCalloutPins } = useCallouts();
	const actionContext: ActionContext = {
		selectedNodeIds,
		selectedPointInNodes,
		setSelectedNodeIds,
		setCalloutPins,
		reloadLibrary,
		sceneId,
	};

	type MenuAction = ActionKey | 'divider';

	const [menuActions, setMenuActions] = useState<MenuAction[]>([]);

	useEffect(() => {
		if (eventCoordinates) {
			const [x, y] = eventCoordinates;
			const nodeId = globalThis.lys.getNodeAt(x, y);
			if (nodeId < 0) {
				setMenuActions(['centerAndFit', 'showAllParts', 'setFocalDistance', 'hideCallouts']);
			} else {
				setMenuActions([
					'centerAndFit',
					'showAllParts',
					'divider',
					'clone',
					'delete',
					'groupParts',
					'divider',
					'lockNode',
					'unlockNode',
					'divider',
					'hideNode',
					'showHiddenNode',
					'divider',
					'unlinkMaterial',
					'linkMaterial',
					'saveMaterialToLibrary',
					'setFocalDistance',
					'divider',
					'showCallouts',
					'hideCallouts',
				]);
			}
		}
	}, [eventCoordinates]);

	return (
		<ActionMenu
			open={location != null}
			handleClose={handleClose}
			location={location}
			menuActions={menuActions}
			actionContext={actionContext}
		/>
	);
};

export default CanvasContextMenu;
