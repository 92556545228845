/**
 * A 2D bounding box
 */
export type AABB = { minX: number; minY: number; maxX: number; maxY: number };

/**
 * Converts an image to a blob which can be sent to the API, or shown in an img element.
 * Optionally crops the image according to the aabb (bounding box)
 * Optionally resizes the image
 */
export async function createJpgBlob(
	imageData: ImageData,
	aabb?: AABB,
	resize?: { width: number; height: number },
): Promise<Blob> {
	if (aabb) {
		const width = aabb.maxX - aabb.minX;
		const height = aabb.maxY - aabb.minY;
		const canvas = new OffscreenCanvas(width, height);
		const ctx = canvas.getContext('2d');
		ctx.putImageData(imageData, -aabb.minX, -aabb.minY, aabb.minX, aabb.minY, width, height);

		if (resize) {
			return await createResizedJpgBlob(canvas, resize);
		}
		return await canvas.convertToBlob({
			type: 'image/jpeg',
			quality: 0.75,
		});
	} else {
		const canvas = new OffscreenCanvas(imageData.width, imageData.height);
		const ctx = canvas.getContext('2d');
		ctx.putImageData(imageData, 0, 0);

		if (resize) {
			return await createResizedJpgBlob(canvas, resize);
		}
		return await canvas.convertToBlob({
			type: 'image/jpeg',
			quality: 0.75,
		});
	}
}

/**
 * Find the best area of interest for a thumbnail version of a rendering
 */
export function cutoutThumbnail(imageData: ImageData): AABB | null {
	const gradients = computeHighpassGradients(imageData);
	let aabb = findBoundingBoxOfGradients(gradients);

	if (aabb.minX === aabb.maxX || aabb.minY === aabb.maxY) {
		return null;
	}

	aabb = addMargin(aabb, imageData.width, imageData.height);
	aabb = maintainAspectRatioOfOne(aabb);
	return fitAabbWithinOriginal(aabb, imageData.width, imageData.height);
}

/**
 * Convert RGB image data to RGBA
 * The image data we get from a lys buffer is RGB but ImageData expects RGBA.
 */
export function lysImageBufferToRGBA(array: Uint8Array, width: number, height: number): ImageData {
	const clampedArrayWith4Channels = new Uint8ClampedArray(width * height * 4);
	for (let y = 0; y < height; y++) {
		for (let x = 0; x < width; x++) {
			const i = (y * width + x) * 4;
			const j = ((height - y - 1) * width + x) * 3;
			clampedArrayWith4Channels[i + 0] = array[j + 0];
			clampedArrayWith4Channels[i + 1] = array[j + 1];
			clampedArrayWith4Channels[i + 2] = array[j + 2];
			clampedArrayWith4Channels[i + 3] = 255; // Set alpha to 255
		}
	}
	return new ImageData(clampedArrayWith4Channels, width, height);
}

async function createResizedJpgBlob(canvas: OffscreenCanvas, resize: { width: number; height: number }): Promise<Blob> {
	const resizeCanvas = new OffscreenCanvas(resize.width, resize.height);
	const resizeCtx = resizeCanvas.getContext('2d');
	resizeCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resize.width, resize.height);
	return await resizeCanvas.convertToBlob({
		type: 'image/jpeg',
		quality: 0.75,
	});
}

/** Finds an area of interest from the high pass gradients
 * @param gradients The high pass gradients, assumed 4 bytes per pixel
 */
function findBoundingBoxOfGradients(gradients: ImageData): AABB {
	const aabb = {
		minX: gradients.width,
		minY: gradients.height,
		maxX: 0,
		maxY: 0,
	};
	for (let y = 1; y < gradients.height - 1; y++) {
		for (let x = 1; x < gradients.width - 1; x++) {
			const i = (y * gradients.width + x) * 4;
			if (gradients.data[i] > 10) {
				aabb.minX = Math.min(aabb.minX, x);
				aabb.minY = Math.min(aabb.minY, y);
				aabb.maxX = Math.max(aabb.maxX, x);
				aabb.maxY = Math.max(aabb.maxY, y);
			}
		}
	}

	if (aabb.minX > aabb.maxX || aabb.minY > aabb.maxY) {
		return {
			minX: 0,
			minY: 0,
			maxX: gradients.width,
			maxY: gradients.height,
		};
	}

	return aabb;
}

// TODO: Compute on GPU for efficiency
function computeHighpassGradients(image: ImageData): ImageData {
	const gradients = new Uint8ClampedArray(image.data.length);
	const kernelSize = 3;
	const kernelOffset = Math.floor(kernelSize / 2);

	for (let y = 0; y < image.height; y++) {
		for (let x = 0; x < image.width; x++) {
			let sum = 0;
			let count = 0;

			for (let ky = -kernelOffset; ky <= kernelOffset; ky++) {
				for (let kx = -kernelOffset; kx <= kernelOffset; kx++) {
					const px = x + kx;
					const py = y + ky;

					if (px >= 0 && px < image.width && py >= 0 && py < image.height) {
						for (let p = 0; p < 3; p++) {
							const index = (py * image.width + px) * 4 + p;
							if (kx === 0 && ky === 0) {
								sum -= 8 * image.data[index];
							} else {
								sum += image.data[index];
							}
							count++;
						}
					}
				}
			}

			const average = -sum / count;
			const index = (y * image.width + x) * 4;
			const gradient = Math.max(0, Math.min(255, Math.abs(average)));
			gradients[index] = gradient;
			gradients[index + 1] = gradient;
			gradients[index + 2] = gradient;
			gradients[index + 3] = 255;
		}
	}

	return new ImageData(gradients, image.width, image.height);
}

function maintainAspectRatioOfOne(aabb: AABB): AABB {
	const result = structuredClone(aabb);
	const width = result.maxX - result.minX;
	const height = result.maxY - result.minY;
	const centerX = (result.minX + result.maxX) / 2;
	const centerY = (result.minY + result.maxY) / 2;

	if (width > height) {
		result.minY = Math.floor(centerY - width / 2);
		result.maxY = Math.floor(centerY + width / 2);
	} else {
		result.minX = Math.floor(centerX - height / 2);
		result.maxX = Math.floor(centerX + height / 2);
	}
	return result;
}

function addMargin(aabb: AABB, width: number, height: number): AABB {
	const margin = 10;
	const result = structuredClone(aabb);
	if (result.minX > margin) result.minX -= margin;
	if (result.minY > margin) result.minY -= margin;
	if (result.maxX < width - margin) result.maxX += margin;
	if (result.maxY < height - margin) result.maxY += margin;
	return result;
}

function fitAabbWithinOriginal(aabb: AABB, width: number, height: number): AABB {
	const aabbWidth = aabb.maxX - aabb.minX;
	const aabbHeight = aabb.maxY - aabb.minY;

	if (aabbWidth > width) {
		const centerY = height / 2;
		return {
			minX: 0,
			minY: Math.floor(centerY - width / 2),
			maxX: width,
			maxY: Math.floor(centerY + width / 2),
		};
	}

	if (aabbHeight > height) {
		const centerX = width / 2;
		return {
			minX: Math.floor(centerX - height / 2),
			minY: 0,
			maxX: Math.floor(centerX + height / 2),
			maxY: height,
		};
	}

	return aabb;
}
