import CssBaseline from '@mui/material/CssBaseline';
import {
	Snackbar,
	IconButton,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { Fragment, StrictMode, useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, Navigate, redirect, RouteObject, RouterProvider } from 'react-router-dom';
import FigurementApp from './pages/FigurementApp';
import FilesPage from './pages/FilesPage';
import Login from './pages/Login';
import AdminUsersList from './pages/admin/AdminUsersList';
import ResetPassword from './pages/ResetPasswordPage';
import { VerifyAccount } from './pages/VerifyAccount';
import { PaletteMode } from '@mui/material';
import { AuthenticatedUser, isAuthenticatedUser, User } from './utils/user';
import { ImportDataProvider } from './utils/import-data-context';
import { dark, light } from './utils/theme';
import AdminUserEvents from './pages/admin/AdminUserEvents';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

export const RELATIVE_SCENES_PATH = 'home/Scenes';
export const RELATIVE_RENDERS_PATH = 'home/Renders';
export const RELATIVE_MATERIALS_PATH = 'home/Materials';
export const HOME_PATH = `/files/${RELATIVE_SCENES_PATH}`;

export default function App() {
	const [errors, setErrors] = useState<string[]>([]);
	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [mode, setMode] = useState<PaletteMode>('dark');
	const [installPrompt, setInstallPrompt] = useState<Event | undefined>(undefined);
	const APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE = 'dismissedAppInstallation';

	const originalConsoleError = console.error;

	function sanitizeErrorMessage(message) {
		// First, remove ANSI escape codes
		const ansiEscapeCodePattern = /\x1b\[[0-9;]*[a-zA-Z]/g;
		message = message.replace(ansiEscapeCodePattern, '');

		// Then remove non-printable characters
		message = message.replace(/[\x00-\x1F\x7F]/g, '');

		return message;
	}

	// // Override the console.error function
	// console.error = function (message, ...optionalParams) {
	//   originalConsoleError.apply(console, [message, ...optionalParams]);

	//   // Sanitize the error message before adding it to the state
	//   const sanitizedMessage = sanitizeErrorMessage(message);

	//   setErrors((prevErrors) => [...prevErrors, sanitizedMessage]);
	// };

	// Function to handle showing the error dialog
	const handleOpenErrorDialog = () => {
		setOpenErrorDialog(true);
	};

	// Function to handle closing the error dialog
	const handleCloseErrorDialog = () => {
		setOpenErrorDialog(false);
	};

	const handleDismissErrors = () => {
		setErrors([]); // Clears all errors
		setOpenErrorDialog(false); // Close the dialog
	};

	const action = (
		<Fragment>
			<Button
				onClick={() => {
					if (installPrompt) {
						(installPrompt as any).prompt();
					}
				}}
				variant="contained"
				color="success"
			>
				Install App
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={(_e) => {
					window.localStorage.setItem(APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE, 'true');
					setInstallPrompt(undefined);
				}}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</Fragment>
	);

	const useAppTheme = (mode: PaletteMode) => {
		return useMemo(() => {
			const theme = createTheme(mode === 'dark' ? dark : light);
			return theme;
		}, [mode]);
	};

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		if (prefersDarkMode) setMode('dark');
		else setMode('light');
	}, [prefersDarkMode]);

	const handleLoggedIn = (user: AuthenticatedUser) => {
		setUser(user);
		router.navigate(HOME_PATH);
	};

	useEffect(() => {
		/* code to prevent emscripten compiled code from eating key input */
		window.addEventListener(
			'keydown',
			function (event: KeyboardEvent) {
				if (event.keyCode === 8 || event.keyCode === 9) {
					event.stopImmediatePropagation();
				}
			},
			true,
		);

		window.addEventListener(
			'keyup',
			function (event: KeyboardEvent) {
				if (event.keyCode === 8 || event.keyCode === 9) {
					event.stopImmediatePropagation();
				}
			},
			true,
		);

		window.addEventListener('beforeinstallprompt', (e: Event) => {
			// Prevent the mini-infobar from appearing on mobile
			//e.preventDefault();
			// Stash the event so it can be triggered later.

			const appInstallDismissed = window.localStorage.getItem(APP_INSTALL_DISMISSED_IN_LOCAL_STORAGE);
			if (!appInstallDismissed) {
				setInstallPrompt(e);
			}
		});

		window.addEventListener('appinstalled', () => {
			setInstallPrompt(undefined);
		});

		// // Handler for uncaught exceptions
		// const handleUncaughtError = (message, source, lineno, colno, error) => {
		//   // Log the error to the console
		//   originalConsoleError('Uncaught Exception:', message, 'at', source, ':', lineno, ':', colno);

		//   // Sanitize and store the error message
		//   const errorMsg = `Uncaught Exception: ${message} at ${source}:${lineno}:${colno}`;
		//   const sanitizedMessage = sanitizeErrorMessage(errorMsg);
		//   setErrors((prevErrors) => [...prevErrors, sanitizedMessage]);
		// };

		// window.onerror = handleUncaughtError;

		// // Handler for unhandled promise rejections
		// const handleUnhandledRejection = (event) => {
		//   // Prevent the default logging (optional)
		//   event.preventDefault();

		//   // Log the error to the console
		//   originalConsoleError('Unhandled Promise Rejection:', event.reason);

		//   // Sanitize and store the error message
		//   const errorMsg = `Unhandled Promise Rejection: ${event.reason}`;
		//   const sanitizedMessage = sanitizeErrorMessage(errorMsg);
		//   setErrors((prevErrors) => [...prevErrors, sanitizedMessage]);
		// };

		// window.onunhandledrejection = handleUnhandledRejection;

		// Cleanup on component unmount
		return () => {
			window.onerror = null;
			window.onunhandledrejection = null;
		};
	}, []);

	const theme = useAppTheme(mode);

	// This is level 1 of access
	// Level 0 is when there's no user, not even an anonymous one
	const requireUser = async () => {
		let currentUser = user; // store in local var because updating state is async
		if (!currentUser) {
			const response = await fetch('/api/auth');
			if (response.ok) {
				const json = await response.json();
				currentUser = json.user;
				if (isAuthenticatedUser(currentUser)) {
					setUser(currentUser);
				} else {
					currentUser = new User();
					setUser(currentUser);
				}
			} else {
				currentUser = new User();
				setUser(currentUser);
			}
		}
		return currentUser;
	};

	// Some routes require the user to be authenticated
	const requireAuthenticated = async () => {
		const currentUser = await requireUser();
		if (currentUser && !isAuthenticatedUser(currentUser)) {
			return redirect('/login');
		}
		return null;
	};

	// The most restricted access level
	const requireAdminRights = async () => {
		const currentUser: User = await requireUser();
		const authUser: AuthenticatedUser = isAuthenticatedUser(currentUser) ? currentUser : null;
		if (!authUser) {
			return redirect('/login');
		}
		if (!authUser.email.endsWith('@figurement.com')) {
			return redirect('/login');
		}
		return null;
	};

	// This is a mixed access level
	// because even anonymous users may be allowed to edit a
	// scene, if it is shared with "everyone".
	const sceneLoader = async ({ params, request }) => {
		if (!params.id) return new Response('Not found', { status: 404 });

		const user = await requireUser();

		const response = await fetch(`/api/scenes/${params.id}`);
		if (response.ok) {
			const meta = await response.json();
			const { access } = meta;
			const currentPath = new URL(request.url).pathname;
			const editPath = `/scenes/${params.id}/edit`;
			const viewPath = `/scenes/${params.id}/view`;
			if (access === 'can edit' && currentPath !== editPath && currentPath !== viewPath) {
				return redirect(editPath);
			}

			if (access === 'can view' && currentPath !== viewPath) {
				return redirect(viewPath);
			}

			if (access === 'no access') {
				if (isAuthenticatedUser(user)) {
					return redirect(HOME_PATH);
				} else {
					return redirect('/login');
				}
			}

			return meta;
		} else if (response.status === 401) {
			return redirect('/login');
		} else if (response.status === 404) {
			return redirect(RELATIVE_SCENES_PATH);
		}
	};

	const routes: RouteObject[] = [
		{
			path: '/login',
			element: <Login onUser={handleLoggedIn} />,
		},
		{
			path: '/verify-account/:token',
			element: <VerifyAccount />,
		},
		{
			path: '/reset-password',
			element: <ResetPassword onUser={handleLoggedIn} />,
		},
		{
			path: '/files/*',
			element: isAuthenticatedUser(user) ? <FilesPage user={user} onThemeModeChange={setMode} /> : <></>,
			loader: requireAuthenticated,
		},
		{
			path: '/scenes/:id/edit',
			element: <FigurementApp user={user} canEdit={true} onThemeModeChange={setMode} />,
			loader: sceneLoader,
		},
		{
			path: '/scenes/:id/view',
			element: <FigurementApp user={user} canEdit={false} onThemeModeChange={setMode} />,
			loader: sceneLoader,
		},
		{
			path: '/scenes/:id',
			loader: sceneLoader,
		},
		{
			path: '/admin/users',
			element: isAuthenticatedUser(user) ? <AdminUsersList user={user} /> : <></>,
			loader: requireAdminRights,
		},
		{
			path: '/admin/user-events',
			element: isAuthenticatedUser(user) ? <AdminUserEvents /> : <></>,
			loader: requireAdminRights,
		},
		{
			path: '/',
			element: <Navigate to={HOME_PATH} replace />,
		},
	];

	const router = createBrowserRouter(routes);

	return (
		<StrictMode>
			<ThemeProvider theme={theme}>
				<CssBaseline />

				{/* The warning icon that appears if there are errors */}
				{errors.length > 0 && (
					<IconButton
						style={{
							position: 'fixed',
							bottom: 20,
							right: 20,
							backgroundColor: 'red',
							color: 'white',
							zIndex: 1300,
						}}
						onClick={handleOpenErrorDialog}
					>
						<ErrorOutlineIcon />
					</IconButton>
				)}

				{/* Dialog to display the list of errors */}
				<Dialog
					open={openErrorDialog}
					onClose={handleCloseErrorDialog}
					maxWidth="md"
					fullWidth
					style={{ zIndex: 1300 }} // Adjust the z-index to ensure it appears above other elements
				>
					<DialogTitle>Error List</DialogTitle>
					<DialogContent
						style={{
							maxHeight: '400px', // Limit the height for vertical scrolling
							overflowY: 'auto', // Enable vertical scrolling
							overflowX: 'auto', // Enable horizontal scrolling
						}}
					>
						<List
							style={{
								whiteSpace: 'nowrap', // Prevent text from wrapping, allowing horizontal scrolling
							}}
						>
							{errors.map((error, index) => (
								<ListItem key={index}>
									<ListItemText primary={error} />
								</ListItem>
							))}
						</List>
					</DialogContent>
					<Button onClick={handleCloseErrorDialog} color="primary">
						Close
					</Button>
					<Button onClick={handleDismissErrors} color="secondary">
						Dismiss
					</Button>
				</Dialog>

				<Snackbar
					open={installPrompt != undefined}
					message={<>Want easy access to Figurement from your operating system?</>}
					action={action}
				/>
				<ImportDataProvider>
					<RouterProvider router={router} />
				</ImportDataProvider>
			</ThemeProvider>
		</StrictMode>
	);
}
