import { useState, useEffect } from 'react';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio, Box, Checkbox, FormGroup } from '@mui/material';

import FTextField from '../FTextField';

export default function ImageProperties() {
	// Resolution states
	const [resolutionWidth, setResolutionWidth] = useState<number>(1920);
	const [resolutionHeight, setResolutionHeight] = useState<number>(1080);

	enum BackgroundType {
		Color = 'color',
		Environment = 'environment',
		Backplate = 'backplate',
	}

	// Background settings
	const [background, setBackground] = useState<BackgroundType>(BackgroundType.Color);
	const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
	const [forceUpdate, setForceUpdate] = useState(0);
	const [bloom, setBloom] = useState<boolean>(false); // State for Bloom effect

	function hexToRgb(hex: string) {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
				}
			: null;
	}

	function rgbToHex(r: number, g: number, b: number) {
		return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
	}

	const getData = () => {
		setResolutionWidth(globalThis.lys.getWidth());
		setResolutionHeight(globalThis.lys.getHeight());
		const background = globalThis.lys.getBackgroundColor();
		console.log('background', background);
		const hexColor = rgbToHex(background.x * 255, background.y * 255, background.z * 255);
		console.log('hexColor', hexColor);
		setBackgroundColor(hexColor);

		if (globalThis.lys.isShowingHdri()) {
			setBackground(BackgroundType.Environment);
		} else if (globalThis.lys.isShowingBackgroundColor()) {
			setBackground(BackgroundType.Color);
		}

		setBloom(globalThis.lys.isBloomEffectEnabled());
	};

	useEffect(() => {
		globalThis.updateImageConfiguration = () => setForceUpdate((n) => n + 1);
		getData();

		return () => {
			delete globalThis.updateImageConfiguration;
		};
	}, []);

	useEffect(() => {
		getData();
	}, [forceUpdate]);

	const handleBloomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const enabled = event.target.checked;
		setBloom(enabled);
		globalThis.lys.setBloomEffect(enabled, true);
	};

	const handleResolutionWidth = (value: string) => {
		const num = parseInt(value, 10);
		if (!isNaN(num)) setResolutionWidth(num);
	};

	const handleResolutionHeight = (value: string) => {
		const num = parseInt(value, 10);
		if (!isNaN(num)) setResolutionHeight(num);
	};

	// Handler for background type selection
	const handleBackgroundTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value as BackgroundType;
		setBackground(value);

		if (value === BackgroundType.Environment) {
			globalThis.lys.setShowEnvironmentBackground(true);
		} else if (value === BackgroundType.Color) {
			const { r, g, b } = hexToRgb(backgroundColor);
			globalThis.lys.setShowColorBackground(r / 255.0, g / 255.0, b / 255.0, true);
		}
	};

	// Handler for background color
	const handleBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const color = event.target.value;
		setBackgroundColor(color);

		const { r, g, b } = hexToRgb(color);
		globalThis.lys.setShowColorBackground(r / 255.0, g / 255.0, b / 255.0, true);
	};

	const backgroundColorStyle = (color: string) => ({
		background: color,
		border: '1px solid #C4C4C4',
		cursor: 'pointer',
	});

	return (
		<Box sx={{ padding: 1 }}>
			{/* Resolution Settings */}
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<Box sx={{ flex: 1 }}>
					<Typography>Width</Typography>
					<FTextField
						disabled={true}
						type="number"
						value={resolutionWidth}
						onChange={(e) => handleResolutionWidth(e.target.value)}
					/>
				</Box>
				<Box sx={{ flex: 1 }}>
					<Typography>Height</Typography>
					<FTextField
						disabled={true}
						type="number"
						value={resolutionHeight}
						onChange={(e) => handleResolutionHeight(e.target.value)}
					/>
				</Box>
			</Box>

			{/* Background Settings */}
			<Box sx={{ marginTop: 1 }}>
				<Box sx={{ mb: 1, mt: 2 }}>Background</Box>
				<RadioGroup sx={{ ml: 1 }} value={background} onChange={handleBackgroundTypeChange}>
					<FormControlLabel value="environment" control={<Radio size="small" />} label="Environment Light" />
					<Grid container alignItems="center">
						<Grid item xs={6}>
							<FormControlLabel
								value={BackgroundType.Color}
								control={<Radio size="small" />}
								label="Color"
								sx={{ marginRight: 1 }}
							/>
						</Grid>
						{background === BackgroundType.Color && (
							<Grid
								item
								xs={6}
								style={backgroundColorStyle(backgroundColor)}
								onClick={(e) => {
									const input = e.currentTarget.querySelector('input');
									input?.focus();
									input?.click();
								}}
							>
								<input
									type="color"
									value={backgroundColor}
									onChange={handleBackgroundColorChange}
									style={{ opacity: 0, height: '30px', cursor: 'pointer' }}
								/>
							</Grid>
						)}
					</Grid>
					<FormControlLabel disabled value="backplate" control={<Radio size="small" />} label="Backplate" />
					<FormControlLabel
						disabled
						value="transparent"
						control={<Radio size="small" />}
						label="Transparent"
					/>
				</RadioGroup>
			</Box>
			{/* Bloom Settings */}
			<Box sx={{ marginTop: 2 }}>
				<FormGroup>
					<FormControlLabel
						control={<Checkbox checked={bloom} onChange={handleBloomChange} />}
						label="Bloom"
					/>
				</FormGroup>
			</Box>
		</Box>
	);
}
