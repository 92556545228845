import { Box, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { isValidEmail, AuthenticatedUser } from '../utils/user';
import FButton from '../components/FButton';
import Alert from '@mui/material/Alert';

async function loginUser(credentials: { email: string; password: string }): Promise<Response> {
	return fetch('/api/auth', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(credentials),
	});
}

export default function Login({ onUser }: { onUser: (user: AuthenticatedUser) => void }) {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string | null>(null);
	const [message, setMessage] = useState<string | null>(null);

	const [searchParams] = useSearchParams();

	// Handle autofill synchronization
	useEffect(() => {
		const emailField = document.querySelector<HTMLInputElement>('input[type="email"]');
		const passwordField = document.querySelector<HTMLInputElement>('input[type="password"]');

		if (emailField?.value) {
			setEmail(emailField.value); // Sync autofill email to state
		}
		if (passwordField?.value) {
			setPassword(passwordField.value); // Sync autofill password to state
		}
	}, []);

	// Automatically submit form if email and token are in the query params
	useEffect(() => {
		const email = searchParams.get('email');
		const token = searchParams.get('token'); // Assuming token acts as password here

		if (email && token) {
			submitForm(email, token);
		}
	}, [searchParams]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!validate()) return;
		submitForm(email, password);
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.preventDefault(); // Prevent default Enter behavior
			if (validate()) {
				submitForm(email, password);
			}
		}
	};

	const submitForm = async (email: string, password: string) => {
		try {
			const response = await loginUser({ email, password });

			if (response.status === 200) {
				const { user }: { user: AuthenticatedUser } = await response.json();
				window.localStorage.setItem('user', JSON.stringify(user));
				onUser(user);
				return;
			}

			if (response.status === 202) {
				setMessage('Check your email for a one-time sign-in link');
				setError(null);
				return;
			}

			// Attempt to parse the response JSON
			try {
				const jsonResponse = await response.json();
				const { message } = jsonResponse;
				setError(message ?? 'You can sign up for early access');
			} catch (error) {
				// Handle cases where the response body is not valid JSON
				console.error('An error occurred:', error);
				setError('Unexpected error occurred. Please try again.');
			}
		} catch (error) {
			// Handle cases where the request fails entirely
			console.error('An error occurred:', error);
			setError('An error occurred. Please check your internet connection and try again.');
		}
	};

	const validate = () => {
		return isValidEmail(email) && (!!password || (!password && email));
	};

	return (
		<div>
			<Box
				display={isMobileOnly ? undefined : 'flex'}
				justifyContent={isMobileOnly ? undefined : 'center'}
				alignItems={isMobileOnly ? undefined : 'center'}
				textAlign="center"
				minHeight="100vh"
				minWidth={isMobileOnly ? '100vw' : undefined}
			>
				<Paper sx={isMobileOnly ? { height: '100vh' } : undefined} elevation={10} style={{ padding: 30 }}>
					<Typography variant="h2">Figurement</Typography>

					<form onSubmit={handleSubmit}>
						<div style={{ paddingTop: 30 }}>
							<TextField
								fullWidth
								label="Email"
								autoComplete="username"
								autoFocus
								type="email"
								value={email} // Controlled input
								onChange={(e) => setEmail(e.target.value)}
								onKeyDown={handleKeyPress}
							/>
						</div>
						<div style={{ paddingTop: 15 }}>
							<TextField
								fullWidth
								autoComplete="current-password"
								label="Password"
								type="password"
								value={password} // Controlled input
								onChange={(e) => setPassword(e.target.value)}
								onKeyDown={handleKeyPress}
							/>
						</div>
						<div style={{ paddingTop: 15 }}>
							<FButton variant="contained" type="submit" fullWidth disabled={!validate()}>
								{!password && email ? 'Send me a login code' : 'Log in'}
							</FButton>
						</div>
						<div style={{ paddingTop: 15 }}>
							<FButton variant="text" href={`/reset-password?email=${email ?? ''}`}>
								Forgot my password
							</FButton>
						</div>
						<div>
							<FButton variant="text" href={'https://Figurement.com'}>
								Get Access
							</FButton>
						</div>
						{error && <Alert severity="error">Error: {error}</Alert>}
						{message && <Alert severity="info">{message}</Alert>}
					</form>
				</Paper>
			</Box>
		</div>
	);
}
