import { Box } from '@mui/system';
import { User } from '../../utils/user';
import UserAvatar from '../../components/toolbar/UserAvatar';
import FButton from '../../components/FButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { AppBar, Toolbar } from '@mui/material';
import { isMobileOnly } from 'react-device-detect';

export default function AdminHeader({ user, onNewUserClick }: { user: User; onNewUserClick: () => void }) {
	return (
		<>
			<AppBar
				enableColorOnDark
				color={'neutralbackground' as any}
				position={'fixed'}
				sx={isMobileOnly ? { top: 'auto', bottom: 0, paddingBottom: '25px' } : {}}
				elevation={0}
			>
				<Toolbar
					disableGutters={isMobileOnly ? undefined : true}
					variant="dense"
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<UserAvatar user={user} />
					{user.name}
					<Box sx={{ flex: 1 }}></Box>
					<FButton
						startIcon={<AddBoxIcon />}
						size="small"
						variant="contained"
						tooltip="New Empty Scene"
						onClick={onNewUserClick}
						sx={{
							marginRight: 1,
						}}
					>
						New User
					</FButton>
				</Toolbar>
			</AppBar>
		</>
	);
}
