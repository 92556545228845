import React, { useState, useEffect } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useDragItemContext } from '../DragItemContext';
import EnvironmentIcon from '@mui/icons-material/Language';
import { ThumbnailWithRetry } from '../asset-library/ThumbnailWithRetry';
import FileDropZone from '../FileDropZone';

// Define EnvironmentMap interface
interface EnvironmentMap {
	id: string;
	name: string;
	thumbnailUrl: string;
}

interface EnvironmentListProps {
	search: string; // New prop to filter material names
	sceneId: string;
}

const EnvironmentList = ({ search, sceneId }: EnvironmentListProps) => {
	const { setDraggedItem } = useDragItemContext();
	const [selectedEnvironment, setSelectedEnvironment] = useState<string | null>();
	const [environmentMaps, setEnvironmentMaps] = useState<EnvironmentMap[]>([]);
	const [forceUpdate, setForceUpdate] = useState(0);

	const handleDragStart = (environmentMap: EnvironmentMap) => {
		setDraggedItem(environmentMap, 'environmentMapList');
	};

	const handleNodesSelected = (_event: React.SyntheticEvent, itemIds: string[] | string) => {
		const selectedId = Array.isArray(itemIds) ? itemIds[0] : itemIds;

		if (selectedId) {
			globalThis.lys.setActiveEnvironmentId(selectedId);
			setSelectedEnvironment(selectedId);
		}
	};

	useEffect(() => {
		globalThis.updateEnvironmentMaps = () => setForceUpdate((n) => n + 1);
		updateEnvironmentList();

		return () => {
			delete globalThis.updateEnvironmentMaps;
		};
	}, []);

	function handleDroppedFiles(files: File[]): void {
		if (files.length === 0) return;

		const reader = new FileReader();
		const filename = files[0].name;
		reader.onload = (e: ProgressEvent<FileReader>) =>
			handleUploadEnvironment(e.target.result as ArrayBuffer, filename);
		reader.readAsArrayBuffer(files[0]);
	}

	function handleUploadEnvironment(buffer: ArrayBuffer, filename: string): void {
		const ptr = window.lys._malloc(buffer.byteLength);
		window.lys.HEAPU8.set(new Uint8Array(buffer), ptr);
		const environmentId = globalThis.lys.createEnvironment(filename, ptr, buffer.byteLength);
		globalThis.lys.setActiveEnvironmentId(environmentId);
	}

	const updateEnvironmentList = () => {
		if (!globalThis.lys) return;

		const envIds: string[] = globalThis.lys.getAllEnvironmentIds();

		// Fetch environment names and thumbnails
		const newEnvironmentMaps: EnvironmentMap[] = envIds.map((id: string) => {
			const texture = globalThis.lys.getHdriTextureById(id);

			const name = texture?.getName(); // Fetch the name of the texture
			const thumbnailUrl = `/api/scenes/${sceneId}/textures/${id}/thumbnail`;
			return {
				id,
				name,
				thumbnailUrl,
			};
		});

		setSelectedEnvironment(globalThis.lys.getActiveEnvironmentId());
		setEnvironmentMaps(newEnvironmentMaps);
	};

	useEffect(() => {
		updateEnvironmentList();
	}, [forceUpdate]);

	// Filter materials based on the search prop
	const filteredEnvironments = environmentMaps.filter((env: EnvironmentMap) =>
		env.name.toLowerCase().includes(search.toLowerCase()),
	);

	return (
		<FileDropZone onFilesDrop={handleDroppedFiles}>
			<div>
				<SimpleTreeView
					selectedItems={selectedEnvironment ? [selectedEnvironment.toString()] : []}
					onSelectedItemsChange={handleNodesSelected}
				>
					{filteredEnvironments.map((environmentMap: EnvironmentMap) => (
						<TreeItem
							sx={{ height: '36px' }}
							draggable={true}
							onDragStart={() => handleDragStart(environmentMap)}
							key={environmentMap.id}
							itemId={environmentMap.id.toString()}
							slots={{
								endIcon: EnvironmentIcon,
							}}
							label={
								<div
									style={{
										padding: '0px',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{/* Environment Map Name */}
									<span style={{ flexGrow: 1 }}>{environmentMap.name}</span>
									<ThumbnailWithRetry
										style={{
											flexGrow: 0,
											width: '80px',
											height: '24px',
										}}
										src={environmentMap.thumbnailUrl}
									/>
								</div>
							}
						/>
					))}
				</SimpleTreeView>
			</div>
		</FileDropZone>
	);
};

export default EnvironmentList;
