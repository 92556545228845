import { Avatar, AvatarGroup } from '@mui/material';
import { useState } from 'react';
import { colorFromString } from '../../utils/colors';
import { useEffect } from 'react';

export type UserSession = { id: number; name: string };

export type ActiveUsersProps = {
	activeUsersList: UserSession[];
};

export default function ActiveUsers({ activeUsersList }: ActiveUsersProps) {
	const [followUserId, setFollowUserId] = useState(-1);

	const selected = {
		color: '#ffffff',
		bgcolor: '#37885E',
		width: 31,
		height: 31,
		boxShadow: 20,
		fontSize: '12px',
		fontWeight: 300,
		border: '2px solid white !important ',
	};

	const unselected = {
		bgcolor: '#37885E',
		width: 32,
		height: 32,
		fontSize: '12px',
		fontWeight: 300,
		border: '1px solid #1e1e1e !important ',
	};

	const onClickAvatar = (id) => {
		if (followUserId == id) {
			setFollowUserId(-1);
			globalThis.lys.followUserCamera(-1);
		} else {
			setFollowUserId(id);
			globalThis.lys.followUserCamera(id);
		}
	};

	// on activeUsersList change, update the AvatarGroup with useEffects
	useEffect(() => {
		console.log('activeUsersList changed', activeUsersList);
	}, [activeUsersList]);

	return (
		<AvatarGroup
			sx={{
				marginRight: 1,
				display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' },
			}}
		>
			{activeUsersList?.map((item) => (
				<Avatar
					key={item.id}
					sx={{
						...(followUserId == item.id ? selected : unselected),
						color: '#FFF',
						bgcolor: colorFromString(item.name),
					}}
					alt={item.name}
					onClick={(e) => onClickAvatar(item.id)}
				>
					{item.name.charAt(0)}
				</Avatar>
			))}
		</AvatarGroup>
	);
}
