import { MaterialWithProperties } from '../components/MaterialsContext';
import { ImageTexture, MaterialAndTextures } from '../components/WebGLCanvas';

export async function saveMaterialToLibrary(sceneId: string, id: string): Promise<void> {
	const mat = globalThis.lys.getMaterialById(id);

	if (!mat) {
		console.warn('Material ' + id + ' not found during save to library');
		return;
	}

	// Call renderThumbnail for the current material ID
	globalThis.lys.renderThumbnail(id);

	// Wait 3 seconds for the thumbnail to render
	await new Promise((resolve) => setTimeout(resolve, 3000));

	// Get the rendered thumbnail
	const base64string = globalThis.lys.getThumbnail();
	const thumbnailBlob = base64ToBlob(base64string, 'image/png');

	const material = JSON.parse(mat.toJSONString()) as MaterialWithProperties;

	const textures: ImageTexture[] = material.parameters
		.filter((param) => param.type === 'texture' && param.value)
		.map((param) => {
			return globalThis.lys.getTextureById(param.value);
		})
		.filter((tx) => !!tx)
		.map((tx) => JSON.parse(tx.toJSONString()) as ImageTexture);

	const srcMterialAndTextureInfo = { material, textures };

	const response = await fetch(`/api/scenes/${sceneId}/materials/${id}/copies`, {
		method: 'POST',
		body: JSON.stringify(srcMterialAndTextureInfo),
	});
	if (!response.ok) {
		throw new Error('Failed to save material');
	}
	const destMaterialAndTextureInfo = (await response.json()) as MaterialAndTextures;

	console.log('Material saved with new Id: ', destMaterialAndTextureInfo.material.id, ', now saving thumbnail');
	await fetch(`/api/materials/${destMaterialAndTextureInfo.material.id}/thumbnail`, {
		method: 'PUT',
		body: thumbnailBlob,
	});
}

// Utility function to convert a base64 string to a Blob
function base64ToBlob(base64, mimeType) {
	const byteString = atob(base64.split(',')[1]); // Remove the data URL prefix
	const byteArrays = [];

	for (let i = 0; i < byteString.length; i += 512) {
		const slice = byteString.slice(i, i + 512);
		const byteNumbers = new Array(slice.length);
		for (let j = 0; j < slice.length; j++) {
			byteNumbers[j] = slice.charCodeAt(j);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: mimeType });
}
