import { createRef, forwardRef, useImperativeHandle } from 'react';
import { StepImporter } from '../../opencascade/StepImporter';
import { ImportData, ImportDataContext } from '../../utils/import-data-context';
import React, { useContext } from 'react';
export interface ModelFileImporter {
	openDialog: () => void;
}

export type StepImporterConfigurations = {
	tessellationAngle: number;
	tessellationLineDeflection: number;
};

export const defaultStepImporterConfigurations: StepImporterConfigurations = {
	tessellationAngle: 1, //values between 0.1 and 1 are reasonable. Smaller is better. According to copilot
	tessellationLineDeflection: 0.1, //values between 0.01 and 0.1 are reasonable. Smaller is better. According to copilot
};

export type ModelFileImporterProps = {
	onFileRead?: (filename: string) => void;
};

const ModelFileImporter = forwardRef(({ onFileRead }: ModelFileImporterProps, ref) => {
	const inputRef = createRef<HTMLInputElement>();

	const { setImportData } = useContext(ImportDataContext);

	useImperativeHandle(ref, () => ({
		openDialog: () => {
			inputRef.current.click();
		},
	}));

	const onFileChange = (e) => {
		e.preventDefault();
		const file: File = e.target.files[0];
		readFileToImportData(file, setImportData);
		if (onFileRead) {
			onFileRead(file.name);
		}
	};
	return (
		// TODO: add support for mtl+obj+images - but that would require changes to the ImportData being carried as well
		<input
			type="file"
			onChange={onFileChange}
			ref={inputRef}
			style={{ display: 'none' }}
			accept=".stp,.step,.glb,.gltf,.obj"
		/>
	);
});

ModelFileImporter.displayName = 'ModelFileImporter';

export async function readFileToImportData(
	file: File,
	setImportData: React.Dispatch<React.SetStateAction<ImportData>>,
): Promise<void> {
	const promise = new Promise<Uint8Array>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const buffer = e.target?.result;
			resolve(new Uint8Array(buffer as ArrayBuffer));
		};
		reader.onerror = () => {
			reject(new Error(`Error reading file ${file.name}`));
		};
		reader.readAsArrayBuffer(file);
	});

	const buffer = await promise;
	setImportData({ filename: file.name, buffer: buffer });
}

export function isStepFile(filename: string): boolean {
	return filename.endsWith('.step') || filename.endsWith('.stp');
}

export async function importStepFileFromBuffer(
	buffer: Uint8Array,
	filename: string,
	configurations?: StepImporterConfigurations,
): Promise<void> {
	if (isStepFile(filename)) {
		buffer = await new StepImporter().importStep(buffer, configurations);
		filename = filename.replace(/\.[^/.]+$/, '.glb');
		importModelFromBufferInLys(buffer, filename);
	} else {
		throw new Error(`File ${filename} does not have .step or .stp extension`);
	}
}

export async function importModelFromBufferInLys(buffer: Uint8Array, filename: string): Promise<void> {
	const ptr = window.lys._malloc(buffer.byteLength);
	window.lys.HEAPU8.set(new Uint8Array(buffer), ptr);
	window.lys.importFileModel(filename, ptr, buffer.byteLength);
	window.lys._free(ptr);
}

export { ModelFileImporter };
