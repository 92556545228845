import React, { useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box } from '@mui/material';
import EnvironmentIcon from '@mui/icons-material/Language';

interface Props {
	search: string;
}

const ImageList = ({ search }: Props) => {
	// Hardcoded images
	const [images] = useState([{ id: 1, name: 'Realtime View' }]);
	const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);

	// Handle image selection
	const handleImageSelect = (event: React.SyntheticEvent, itemIds: string[]) => {
		setSelectedImageIds(itemIds.map((id) => parseInt(id, 10)));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				boxSizing: 'border-box',
			}}
		>
			<Box sx={{ flexGrow: 1, overflow: 'auto' }}>
				<SimpleTreeView
					multiSelect
					selectedItems={selectedImageIds.map(String)} // Convert number IDs to strings
					onSelectedItemsChange={handleImageSelect}
				>
					{images.map((image) => (
						<TreeItem
							slots={{
								endIcon: EnvironmentIcon,
							}}
							key={image.id}
							itemId={image.id.toString()}
							label={image.name}
						/>
					))}
				</SimpleTreeView>
			</Box>
		</Box>
	);
};

export default ImageList;
