import React, { createContext, useContext, useState, useEffect } from 'react';

// Define the context shape
interface DragItemContextType {
	draggedItem: any | null;
	dragType: string | null;
	setDraggedItem: (item: any | null, type: string) => void;
	clearDraggedItem: () => void;
}

// Create context with default values
const DragItemContext = createContext<DragItemContextType>({
	draggedItem: null,
	dragType: null,
	setDraggedItem: () => {},
	clearDraggedItem: () => {},
});

// Custom hook to use the DragItemContext
export const useDragItemContext = () => useContext(DragItemContext);

// Provider component to wrap around your app
export const DragItemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [draggedItem, setDraggedItemState] = useState<any | null>(null);
	const [dragType, setDragType] = useState<string | null>(null);

	// Set the dragged item and its type when drag starts
	const setDraggedItem = (item: any | null, type: string) => {
		setDraggedItemState(item);
		setDragType(type);
	};

	// Clear the dragged item and drag type when drag ends or is dropped
	const clearDraggedItem = () => {
		setDraggedItemState(null);
		setDragType(null);
	};

	// Global drop handler to clear drag state when dropping outside valid areas
	useEffect(() => {
		const handleDropOutside = (event: DragEvent) => {
			// If there's no valid drop target, clear the dragged item
			if (!event.defaultPrevented) {
				clearDraggedItem();
			}
		};

		window.addEventListener('drop', handleDropOutside);
		window.addEventListener('dragend', clearDraggedItem);

		return () => {
			window.removeEventListener('drop', handleDropOutside);
			window.removeEventListener('dragend', clearDraggedItem);
		};
	}, []);

	return (
		<DragItemContext.Provider value={{ draggedItem, dragType, setDraggedItem, clearDraggedItem }}>
			{children}
		</DragItemContext.Provider>
	);
};
