import { createContext, useContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';
type LysReadyContextType = {
	lysIsReady: boolean;
	setLysIsReady: Dispatch<SetStateAction<boolean>>;
};

const LysReadyContext = createContext<LysReadyContextType>(null);

type LysReadyProviderProps = {
	children: ReactNode;
};

export const LysReadyProvider = ({ children }: LysReadyProviderProps) => {
	const [lysReady, setLysReady] = useState(false);

	return (
		<LysReadyContext.Provider value={{ lysIsReady: lysReady, setLysIsReady: setLysReady }}>
			{children}
		</LysReadyContext.Provider>
	);
};

export const useLysReady = (): LysReadyContextType => {
	const context = useContext(LysReadyContext);
	if (!context) {
		throw new Error('useMode must be used within a LysReadyProvider');
	}
	return context;
};
