import { Box, Paper, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';

export const VerifyAccount: FC = () => {
	const params = useParams();

	const [state, setState] = useState<'loading' | 'ok' | 'error'>('loading');
	useEffect(() => {
		if (params.token) {
			fetch(`/api/auth/verifications/${params.token}`, {
				method: 'PATCH',
			}).then((response) => {
				setState(response.ok ? 'ok' : 'error');
			});
		} else {
			setState('error');
		}
	}, []);

	return (
		<Box
			display={isMobileOnly ? undefined : 'flex'}
			justifyContent={isMobileOnly ? undefined : 'center'}
			alignItems={isMobileOnly ? undefined : 'center'}
			textAlign="center"
			minHeight="100vh"
			minWidth={isMobileOnly ? '100vw' : undefined}
		>
			<Paper sx={isMobileOnly ? { height: '100vh' } : undefined} elevation={10} style={{ padding: 30 }}>
				{state === 'loading' && <Typography variant="h2">Verifying...</Typography>}
				{state === 'error' && (
					<Typography variant="h2">
						We so wish we could verify your email address. Alas, something has gone awry..
					</Typography>
				)}
				{state === 'ok' && (
					<>
						<Typography variant="h2">Thank you for verifying your email address.</Typography>
						{/* <Typography variant="h6" style={{ paddingTop: 16 }}>
                        You are now on the waiting list.
                        We will let you know the moment your account is ready.
                    </Typography> */}
					</>
				)}
			</Paper>
		</Box>
	);
};
