import { createContext, SyntheticEvent, useRef, useEffect } from 'react';

import { useTheme } from '@mui/material';
import { useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LibraryDrawer from '../components/asset-library/LibraryDrawer';
import Menu from '../components/toolbar/Menu';
import SceneDrawer from '../components/scene/SceneDrawer';
import WebGLCanvas from '../components/WebGLCanvas';

import { Alert, PaletteMode, Snackbar } from '@mui/material';

import Box from '@mui/material/Box';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { User } from '../utils/user';
import { Spinner } from '../components/Spinner';

import { SelectedNodesProvider } from '../components/SelectedNodesContext';
import { MaterialProvider } from '../components/MaterialsContext';
import { DragItemProvider } from '../components/DragItemContext';
import { LibraryReloadProvider } from '../components/LibraryReloadContext';
import { CalloutContextProvider } from '../contexts/CalloutContext';

import { KeyboardShortcutsProvider } from '../components/KeyboardShortcutsContext';
import KeyboardShortcutsModal from '../components/KeyboardShortcutsModal';
import { useHotkeys } from 'react-hotkeys-hook';
import { LysReadyProvider } from '../components/LysReadyContext';
import ResizableContainer from '../components/ResizableContainer';
import VerticalMenu from '../components/scene/VerticalMenu';

export type Access = 'can view' | 'can edit' | 'no access';
export interface SceneMeta {
	realPath: string;
	name: string;
	access: Access;
}

export type WsConnectionStatus = 'not yet connected' | 'connected' | 'disconnected';

export const LatestRenderContext = createContext<number>(0);

export type FigurementAppProps = {
	user: User;
	canEdit: boolean;
	onThemeModeChange: (mode: PaletteMode) => void;
};

export enum SceneTabMode {
	scene,
	material,
	environment,
	camera,
	image,
	shot,
	render,
	chat,
}

export default function FigurementApp({ user, canEdit, onThemeModeChange }: FigurementAppProps) {
	const { id: sceneId } = useParams();
	const [newRenderCounter, setNewRenderCounter] = useState<number>(0);
	const [newChatMessagesCounter, setNewChatMessagesCounter] = useState<number>(0);

	const [menuIconCommand, setMenuIconCommand] = useState<string>();
	const [centerAndFit, setCenterAndFit] = useState(false);
	const [renderMode, setRenderMode] = useState<string>();
	const [isWsConnected, setIsWsConnected] = useState<WsConnectionStatus>('not yet connected');

	const fullscreenHandle = useFullScreenHandle();

	const [showLibraryDrawer, setShowLibraryDrawer] = useState(!isMobile);
	const lysWGLCanvasRef = useRef(null);

	const [materialTypes, setMaterialTypes] = useState([]);

	const [activeUsersList, setActiveUsersList] = useState();
	const [filename, setFilename] = useState('');

	const theme = useTheme();

	const [latestRender, setLatestRender] = useState<number>(Date.now());

	const [loadingMessage, setLoadingMessage] = useState<string>('Loading app...');

	const [isShortcutModalOpen, setIsShortcutModalOpen] = useState(false);

	const [sceneTabMode, setSceneTabMode] = useState<SceneTabMode>(
		isMobile ? null : canEdit ? SceneTabMode.scene : SceneTabMode.chat,
	);

	const handleChangeSceneDrawer = (_event: SyntheticEvent | null, newTabIndex: SceneTabMode) => {
		if (sceneTabMode === newTabIndex) {
			// Close the content area by setting the mode to null
			setSceneTabMode(null);
		} else {
			if (sceneTabMode === SceneTabMode.render && newTabIndex !== SceneTabMode.render) {
				setNewRenderCounter(0);
			}
			if (sceneTabMode === SceneTabMode.chat && newTabIndex !== SceneTabMode.chat) {
				setNewChatMessagesCounter(0);
			}

			setSceneTabMode(newTabIndex);

			if (newTabIndex === SceneTabMode.render) {
				setNewRenderCounter(0);
			}
			if (newTabIndex === SceneTabMode.chat) {
				setNewChatMessagesCounter(0);
			}
		}
	};

	// Open modal when 'K' key is pressed
	useHotkeys('k', () => setIsShortcutModalOpen(true), [setIsShortcutModalOpen]);

	const setLibraryDrawerVisibility = (visible: boolean) => {
		setShowLibraryDrawer(visible);
	};

	const onRenderClick = async () => {
		await saveRenderAndThumbnail();
	};

	const handleFilenameChange = (name: string) => {
		if (name !== filename) {
			setFilename(name);
			window.lys.setSceneName(name);
		}
	};

	useEffect(() => {
		console.debug('FigurementApp mount');

		globalThis.setLoadingMessage = (message: string) => {
			setLoadingMessage(message);
		};

		// Hack to manipulate the Fullscreen component to support our layout
		const element = document.querySelector('.fullscreen');
		if (element instanceof HTMLElement) {
			element.style.flex = '1';
			element.style.display = 'flex';
		}

		return () => {
			console.debug('FigurementApp unmount');
		};
	}, []);

	async function saveRenderAndThumbnail() {
		const renderFrame = await window.lys.getCurrentImage();

		if (!renderFrame) {
			console.warn('No pixels in render buffer. Not saving render.');
			return;
		}

		const form = new FormData();
		form.set('full', renderFrame.full);
		form.set('thumbnail', renderFrame.thumbnail);

		fetch('/api/scenes/' + sceneId + '/renders', {
			method: 'POST',
			body: form,
		})
			.then((response) => response.text())
			.then(() => {
				// Increment the render counter on successful save
				setNewRenderCounter((n) => n + 1);
				setLatestRender(Date.now());
			})
			.catch((error) => {
				console.error('Failed to save render:', error);
			});
	}

	async function saveThumbnail(sceneId: string): Promise<Response> {
		if (!window.lys) return; // Perhaps lys has already been unloaded by reloading the page

		const renderFrame = await window.lys.getCurrentImage();
		if (!renderFrame) {
			console.warn('No pixels in render buffer. Not saving thumbnail.');
			return;
		}

		console.debug('Took thumbnail image');
		const form = new FormData();
		form.set('thumbnail', renderFrame.thumbnail);

		return fetch('/api/scenes/' + sceneId + '/renders', {
			method: 'POST',
			body: form,
		});
	}

	function renderMainApplication() {
		return (
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					flexDirection: isMobileOnly ? 'column-reverse' : 'column', // Reverse direction on mobile
					overflow: 'hidden',
				}}
			>
				{/* Top Menu */}
				<Menu
					onFullScreen={fullscreenHandle}
					isfullScreen={fullscreenHandle.active}
					onIconClick={setMenuIconCommand}
					onCenterAndFitClick={setCenterAndFit}
					onRenderClick={onRenderClick}
					onShowLibraryDrawer={setLibraryDrawerVisibility}
					libraryDrawerOpen={showLibraryDrawer}
					user={user}
					sceneId={sceneId}
					activeUsersList={activeUsersList}
					sceneName={filename}
					onChangeSceneName={handleFilenameChange}
					canEdit={canEdit}
					onThemeModeChange={onThemeModeChange}
					onRenderModeClick={setRenderMode}
				/>

				{/* Main Content Area */}
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						overflow: 'hidden',
					}}
				>
					{/* Left Content: ResizableContainer */}
					<Box
						sx={{
							flex: 1, // Takes up remaining space
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{/* Left Drawer, canvas, right Drawer */}
						<ResizableContainer
							orientation="horizontal"
							dividerVariant="hidden"
							defaultSizes={['375px', 1, '375px']}
							sx={{ height: '100%' }}
							show={[
								showLibraryDrawer && canEdit ? true : false,
								true,
								sceneTabMode !== null ? true : false,
							]}
						>
							<LibraryDrawer />
							<Box
								sx={{
									flex: 1,
									position: 'relative', // required for Spinner to position itself correctly
									display: 'flex',
									overflow: 'hidden',
									borderLeft: `1px solid ${theme.palette.divider}`,
									borderRight: `1px solid ${theme.palette.divider}`,
								}}
							>
								{loadingMessage && <Spinner description={loadingMessage} />}
								<WebGLCanvas
									ref={lysWGLCanvasRef}
									setSceneTabMode={setSceneTabMode}
									mouseMode={menuIconCommand}
									centerAndFit={centerAndFit}
									onCenterAndFitDone={setCenterAndFit}
									renderMode={renderMode}
									sceneId={sceneId}
									user={user}
									onLoadMessage={setLoadingMessage}
									onSceneNameUpdate={setFilename}
									onActiveUsersListChange={setActiveUsersList}
									setMaterialTypes={setMaterialTypes}
									onUpdateSceneThumbnail={() =>
										setTimeout(async () => {
											await saveThumbnail(sceneId);
											setLatestRender(Date.now());
										}, 500)
									}
									onConnectionChanged={(connected) =>
										setIsWsConnected(connected ? 'connected' : 'disconnected')
									}
								/>
								{isWsConnected === 'disconnected' && (
									<Snackbar
										open={true}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
									>
										<Alert severity="warning" color="warning">
											We are having issues connecting to the server. Changes will be saved as soon
											as network is restored.
										</Alert>
									</Snackbar>
								)}
							</Box>
							<SceneDrawer
								sceneId={sceneId}
								canEdit={canEdit}
								materialTypes={materialTypes}
								user={user}
								sceneTabMode={sceneTabMode}
								setSceneTabMode={setSceneTabMode}
								newRenderCounter={newRenderCounter}
								setNewRenderCounter={setNewRenderCounter}
								newChatMessagesCounter={newChatMessagesCounter}
								setNewChatMessagesCounter={setNewChatMessagesCounter}
							/>
						</ResizableContainer>
					</Box>

					{/* Right Content: VerticalMenu */}
					<Box
						sx={{
							borderLeft: '1px solid',
							borderColor: theme.palette.divider,
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						<VerticalMenu
							sceneTabMode={sceneTabMode}
							handleChange={handleChangeSceneDrawer}
							canEdit={canEdit}
							newRenderCounter={newRenderCounter}
							newChatMessagesCounter={newChatMessagesCounter}
						/>
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		<FullScreen handle={fullscreenHandle}>
			<LysReadyProvider>
				<SelectedNodesProvider>
					<MaterialProvider>
						<CalloutContextProvider canvas={lysWGLCanvasRef.current?.canvas} user={user}>
							<KeyboardShortcutsProvider sceneId={sceneId}>
								<KeyboardShortcutsModal
									sceneId={sceneId}
									open={isShortcutModalOpen}
									onClose={() => setIsShortcutModalOpen(false)}
								/>
								<LibraryReloadProvider>
									<DragItemProvider>
										<LatestRenderContext.Provider value={latestRender}>
											{renderMainApplication()}
										</LatestRenderContext.Provider>
									</DragItemProvider>
								</LibraryReloadProvider>
							</KeyboardShortcutsProvider>
						</CalloutContextProvider>
					</MaterialProvider>
				</SelectedNodesProvider>
			</LysReadyProvider>
		</FullScreen>
	);
}
