import * as FileApi from '../../services/file-api';
import { GridDeleteIcon } from '@mui/x-data-grid/material/icons';
import { DriveFileRenameOutline, type SvgIconComponent } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { AskDeleteDialog } from './AskDeleteDialog';
import { AskRenameDialog } from './AskRenameDialog';
import { ShowRenderDialog } from './ShowRenderDialog';

interface Action {
	label: string;
	icon: SvgIconComponent;
	disabled?: (file: FileApi.FSEntry) => boolean;
	handler: (file: FileApi.FSEntry, onComplete: () => void) => () => React.JSX.Element;
}

const sceneActions: Record<string, Action> = {
	delete: {
		label: 'Delete',
		icon: GridDeleteIcon,
		handler: (file: FileApi.FSEntry, onComplete: () => void): (() => React.JSX.Element) => {
			return showDeleteDialog(file, () => deleteScene(file.id), onComplete);
		},
	},
};

const renderActions: Record<string, Action> = {
	delete: {
		label: 'Delete',
		icon: GridDeleteIcon,
		handler: (file: FileApi.FSEntry, onComplete: () => void): (() => React.JSX.Element) => {
			return showDeleteDialog(file, () => deleteRender(file.id), onComplete, false);
		},
	},
};

const isDefaultMaterial = (file: FileApi.FSEntry): boolean =>
	!!file?.path && file.path.startsWith('api/files/home/Materials/Default-Materials/');

const materialActions: Record<string, Action> = {
	delete: {
		label: 'Delete',
		icon: GridDeleteIcon,
		disabled: isDefaultMaterial,
		handler: (file: FileApi.FSEntry, onComplete: () => void): (() => React.JSX.Element) => {
			return showDeleteDialog(file, () => deleteMaterial(file.id), onComplete);
		},
	},
	rename: {
		label: 'Rename',
		icon: DriveFileRenameOutline,
		disabled: isDefaultMaterial,
		handler: (file: FileApi.FSEntry, onComplete: () => void): (() => React.JSX.Element) => {
			return showRenameDialog(file, (newName: string) => renameMaterial(file.id, newName), onComplete);
		},
	},
};

export const fileActions: Record<FileApi.FSEntryTypes, Record<string, Action>> = {
	scene: sceneActions,
	folder: {},
	render: renderActions,
	material: materialActions,
};

function showDeleteDialog(
	file: FileApi.FSEntry,
	actionFunction: () => Promise<void>,
	onComplete: () => void,
	showName: boolean = true,
): () => React.JSX.Element {
	const submit = async () => {
		await actionFunction();
		onComplete();
	};
	return () => (
		<AskDeleteDialog
			noun={file.type}
			name={showName ? file.label : ''}
			open={true}
			onConfirm={submit}
			onClose={onComplete}
		/>
	);
}

function showRenameDialog(
	file: FileApi.FSEntry,
	actionFunction: (newName: string) => Promise<void>,
	onComplete: () => void,
): () => React.JSX.Element {
	const submit = async (newName: string) => {
		await actionFunction(newName);
		onComplete();
	};
	return () => (
		<AskRenameDialog noun={file.type} name={file.label} open={true} onConfirm={submit} onClose={onComplete} />
	);
}

function deleteMaterial(id: string): Promise<void> {
	return fetch(`/api/materials/${id}`, {
		method: 'DELETE',
	}).then((response) => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	});
}

function renameMaterial(id: string, name: string): Promise<void> {
	const body = JSON.stringify({ name });

	return fetch(`/api/materials/${id}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
		},
		body: body,
	}).then((response) => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	});
}

function deleteScene(id: string): Promise<void> {
	return fetch(`/api/scenes/${id}`, {
		method: 'DELETE',
	}).then((response) => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	});
}

export function deleteRender(id: string): Promise<void> {
	return fetch(id, {
		method: 'DELETE',
	}).then((response) => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	});
}
