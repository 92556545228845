// actions.tsx

import React from 'react';
import AddIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveToLibraryIcon from '@mui/icons-material/SaveOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CenterFocusIcon from '@mui/icons-material/CenterFocusStrong';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TargetIcon from '@mui-extra/icons/TargetIcon';
import { Label, LabelOff, type SvgIconComponent } from '@mui/icons-material';
import { saveMaterialToLibrary } from '../services/materials-api';
import { PointInNode } from './SelectedNodesContext';

export interface LysMaterial {
	toJSONString(): string;
	getId(): string;
	getName(): string;
	getType(): string;
	getThumbnail(): string;
}

export interface TreeNode {
	getParent(): TreeNode;
	getId(): string;
	getType(): string;
	getName(): string;
	isLocked(): boolean;
	isHidden(): boolean;
	getMaterial(): LysMaterial | null;
}

export type ActionKey =
	| 'clone'
	| 'delete'
	| 'groupParts'
	| 'createGroup'
	| 'unlinkMaterial'
	| 'linkMaterial'
	| 'saveMaterialToLibrary'
	| 'lockNode'
	| 'unlockNode'
	| 'hideNode'
	| 'showHiddenNode'
	| 'centerAndFit'
	| 'showAllParts'
	| 'addCamera'
	| 'setFocalDistance'
	| 'showCallouts'
	| 'hideCallouts';

export interface ActionContext {
	selectedNodeIds: number[];
	selectedPointInNodes: PointInNode[];
	setSelectedNodeIds: React.Dispatch<React.SetStateAction<number[]>>;
	setCalloutPins: (pins: Set<PointInNode>) => void;
	rootNodeId?: number;
	reloadLibrary?: () => void;
	sceneId?: string;
}

interface Action {
	label: string | ((context: ActionContext) => string);
	icon?: SvgIconComponent;
	handler: (context?: ActionContext) => void;
	disabled?: (context: ActionContext) => boolean;
	keyboardShortcut?: string;
}

const disableLeafNodeIf = (
	disableActionFunction: (node: TreeNode) => boolean,
): ((context: ActionContext) => boolean) => {
	const disableFunction = (context: ActionContext) => {
		let disable = false;
		const ids = context?.selectedNodeIds || [];
		disable = ids.every((id) => {
			const node = globalThis.lys.getNodeById(id);
			return node && node.getChildren().size() == 0 && disableActionFunction(node);
		});
		return disable;
	};
	return disableFunction;
};

const actions: Record<ActionKey, Action> = {
	addCamera: {
		label: 'Add Camera',
		icon: AddIcon,
		handler: (context: ActionContext) => {
			const newNodeId = globalThis.lys.createCameraGenId('New Camera');
			globalThis.lys.setActiveCamera(newNodeId, true);
			globalThis.lys.reparentNode(context.rootNodeId, newNodeId, true);
			context.setSelectedNodeIds([newNodeId]);
		},
	},
	lockNode: {
		label: 'Lock Part',
		icon: LockIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			nodeIds.forEach((id) => {
				globalThis.lys.setLocked(id, true, true);
			});
		},
		disabled: disableLeafNodeIf((node: TreeNode) => node.isLocked()),
	},
	unlockNode: {
		label: 'Unlock Part',
		icon: LockOpenIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			nodeIds.forEach((id) => {
				globalThis.lys.setLocked(id, false, true);
			});
		},
		disabled: disableLeafNodeIf((node: TreeNode) => !node.isLocked()),
	},
	hideNode: {
		label: 'Hide Part',
		icon: VisibilityOffIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			nodeIds.forEach((id) => {
				globalThis.lys.setHidden(id, true, true);
			});
		},
		disabled: disableLeafNodeIf((node: TreeNode) => node.isHidden()),
	},
	showHiddenNode: {
		label: 'Show Hidden Part',
		icon: VisibilityIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			nodeIds.forEach((id) => {
				globalThis.lys.setHidden(id, false, true);
			});
		},
		disabled: disableLeafNodeIf((node: TreeNode) => !node.isHidden()),
	},
	saveMaterialToLibrary: {
		label: 'Save Material to Library',
		icon: SaveToLibraryIcon,
		handler: async (context: ActionContext) => {
			if (!context.sceneId) {
				throw new Error('No scene ID provided with the action context');
			}

			const selectedMaterialIds = context.selectedNodeIds
				.map((id) => {
					const node = globalThis.lys.getNodeById(id);
					return node ? node.getMaterial()?.getId() : undefined;
				})
				.filter((id) => id !== undefined);

			//make selectedMaterialIds unique
			const uniqueMatIds = [...new Set(selectedMaterialIds)] as string[];

			if (!selectedMaterialIds || selectedMaterialIds.length === 0) return false;

			for await (const id of uniqueMatIds) {
				await saveMaterialToLibrary(context.sceneId, id);
			}

			context.reloadLibrary();
		},
		disabled: () => {
			return false;
		},
	},
	clone: {
		label: 'Clone Part',
		icon: FileCopyIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			const newIds: number[] = [];
			nodeIds.forEach((id) => {
				const newId: number = globalThis.lys.cloneSubtree(id, true);
				newIds.push(newId);
			});
			context.setSelectedNodeIds(newIds);
		},
		disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
	},
	delete: {
		label: 'Delete Part',
		icon: DeleteIcon,
		handler: (context: ActionContext) => {
			const nodeIds: number[] = context.selectedNodeIds || [];
			nodeIds.forEach((id) => {
				globalThis.lys.deleteSubtree(id, true);
			});
			context.setSelectedNodeIds([]);
		},
		disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
	},
	groupParts: {
		label: 'Group Parts',
		icon: CreateNewFolderIcon,
		handler: (context: ActionContext) => {
			const selectedNodeIds: number[] = context.selectedNodeIds || [];
			if (selectedNodeIds.length === 0) {
				return;
			}
			const newId: number = globalThis.lys.createNodeGenId('Group');
			const parentId: number = globalThis.lys.getNodeById(selectedNodeIds[0]).getParent().getId();
			globalThis.lys.reparentNode(parentId, newId, true);
			selectedNodeIds.forEach((id) => {
				globalThis.lys.reparentNode(newId, id, true);
			});
			const newSelectedNodeIds = [...new Set([...selectedNodeIds, newId])];
			context.setSelectedNodeIds(newSelectedNodeIds);
		},
		disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
	},
	createGroup: {
		label: 'Create Group',
		icon: CreateNewFolderIcon,
		handler: (context: ActionContext) => {
			const newId: number = globalThis.lys.createNodeGenId('Group');
			globalThis.lys.reparentNode(context.rootNodeId, newId, true);
			globalThis.lys.setSelectedNodeIds([newId]);

			//context.setSelectedNodeIds([newId]);
		},
		disabled: () => false,
	},
	unlinkMaterial: {
		label: 'Separate Materials',
		icon: LinkOffIcon,
		handler: (context: ActionContext) => {
			const selectedNodeIds: number[] = context.selectedNodeIds || [];
			let materialsToDelete: string[] = [];
			selectedNodeIds.forEach((id) => {
				const material = globalThis.lys.getNodeById(id).getMaterial();
				if (material) {
					materialsToDelete.push(material.getId());
				}
			});
			// make unique
			materialsToDelete = [...new Set(materialsToDelete)];

			selectedNodeIds.forEach((id) => {
				const material = globalThis.lys.getNodeById(id).getMaterial();
				if (material) {
					const newMatId = globalThis.lys.cloneMaterial(material.getId());
					globalThis.lys.setMaterial(id, newMatId, true);
				}
			});

			//delete old materials
			// materialsToDelete.forEach((matId) => {
			// 	globalThis.lys.deleteMaterialById(matId, true);
			// });
		},
		disabled: () => false,
	},
	linkMaterial: {
		label: 'Join Materials',
		icon: LinkIcon,
		handler: (context: ActionContext) => {
			const selectedNodeIds: number[] = context.selectedNodeIds || [];

			const selectedMaterialIds = selectedNodeIds.map((id) => {
				const node = globalThis.lys.getNodeById(id);
				return node ? node.getMaterial()?.getId() : undefined;
			});
			// make unique and remove undefined from selectedMaterialsId

			const lastMatId = selectedMaterialIds[selectedMaterialIds.length - 1];

			const uniqueMatIds = [...new Set(selectedMaterialIds.filter((x) => x !== undefined))] as string[];

			const allNodes: number[] = globalThis.lys.getAllNodeIds();
			allNodes.forEach((nodeId) => {
				const node = globalThis.lys.getNodeById(nodeId);
				if (node) {
					const mat = node.getMaterial();
					if (mat && uniqueMatIds.includes(mat.getId())) {
						globalThis.lys.setMaterial(nodeId, lastMatId, true);
					}
				}
			});

			uniqueMatIds.forEach((matId) => {
				if (matId != lastMatId) globalThis.lys.deleteMaterialById(matId, true);
			});
		},
		disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 1),
	},
	centerAndFit: {
		label: 'Center and Fit',
		icon: CenterFocusIcon,
		handler: () => {
			globalThis.lys._setCenterAndFit();
		},
	},
	showAllParts: {
		label: 'Show All Parts',
		icon: VisibilityIcon,
		handler: () => {
			globalThis.lys.showAllParts();
		},
	},
	setFocalDistance: {
		label: 'Set Focal Distance',
		icon: TargetIcon,
		handler: () => {
			console.log('Entering Set Focal Distance Mode...');

			// Find the canvas element
			const canvas = document.getElementById('canvas') as HTMLCanvasElement;

			if (!canvas) {
				console.error('Canvas element with id="canvas" not found.');
				return;
			}

			// Create the overlay
			const overlay = document.createElement('div');

			// Style the overlay
			overlay.style.position = 'fixed';
			overlay.style.top = '0';
			overlay.style.left = '0';
			overlay.style.width = '100%';
			overlay.style.height = '100%';
			overlay.style.cursor = 'crosshair';
			overlay.style.zIndex = '1000';
			overlay.style.boxSizing = 'border-box';
			overlay.tabIndex = 0;

			overlay.style.pointerEvents = 'none';

			const canvasRect = canvas.getBoundingClientRect();
			const canvasOverlay = document.createElement('div');

			canvasOverlay.style.position = 'absolute';
			canvasOverlay.style.top = `${canvasRect.top}px`;
			canvasOverlay.style.left = `${canvasRect.left}px`;
			canvasOverlay.style.width = `${canvasRect.width}px`;
			canvasOverlay.style.height = `${canvasRect.height}px`;
			canvasOverlay.style.cursor = 'crosshair';
			canvasOverlay.style.pointerEvents = 'auto';
			canvasOverlay.style.boxSizing = 'border-box';
			canvasOverlay.style.outline = '2px solid blue';

			// Add event listeners to the canvasOverlay instead
			const handleMouseMove = (event: MouseEvent) => {
				const x = event.clientX - canvasRect.left;
				const y = event.clientY - canvasRect.top;

				globalThis.lys.setFocalDistanceAt(x, y);
			};

			const handleMouseClick = () => {
				cleanup();
			};

			const handleKeyDown = (event: KeyboardEvent) => {
				if (event.key === 'Escape') {
					console.log('Focal distance mode canceled');
					cleanup();
				}
			};

			canvasOverlay.addEventListener('mousemove', handleMouseMove);
			canvasOverlay.addEventListener('click', handleMouseClick);
			overlay.addEventListener('keydown', handleKeyDown);

			const cleanup = () => {
				canvasOverlay.removeEventListener('mousemove', handleMouseMove);
				canvasOverlay.removeEventListener('click', handleMouseClick);
				overlay.removeEventListener('keydown', handleKeyDown);
				document.body.removeChild(overlay);
			};

			overlay.appendChild(canvasOverlay);

			document.body.appendChild(overlay);

			overlay.focus();
		},
	},
	showCallouts: {
		label: 'Show Callouts',
		icon: Label,
		handler: (context: ActionContext) => {
			context.setCalloutPins(new Set(context.selectedPointInNodes));
		},
		disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
	},
	hideCallouts: {
		label: 'Hide Callouts',
		icon: LabelOff,
		handler: (context: ActionContext) => {
			context.setCalloutPins(new Set());
		},
	},
};

export { actions };
