import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';

const SceneProperties: React.FC = () => {
	const [numberOfTriangles, setNumberOfTriangles] = useState<number>(0);
	const [memoryConsumption, setMemoryConsumption] = useState<Record<string, any>>({});

	// Sync with lys
	const syncData = () => {
		setNumberOfTriangles(globalThis.lys.getNumberOfTriangles());
		const memoryData = JSON.parse(globalThis.lys.getMemoryConsumptionJson());
		setMemoryConsumption(memoryData || {});
	};

	useEffect(() => {
		globalThis.updateSceneInformation = syncData;
		syncData();

		return () => {
			delete globalThis.updateSceneInformation;
		};
	}, []);

	// Format the number for display
	const formattedNumber = new Intl.NumberFormat('en-US').format(numberOfTriangles);

	// Helper function to format bytes into KB, MB, GB, etc.
	const formatBytes = (bytes: number) => {
		const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		let unitIndex = 0;
		let value = bytes;

		while (value >= 1024 && unitIndex < units.length - 1) {
			value /= 1024;
			unitIndex++;
		}

		return `${value.toFixed(2)} ${units[unitIndex]}`;
	};

	// Recursive function to render nested JSON
	const renderNestedJson = (data: any, level = 0): JSX.Element[] => {
		return Object.entries(data).map(([key, value]) => {
			const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
			if (typeof value === 'object' && value !== null) {
				return (
					<React.Fragment key={key}>
						<Box sx={{ mb: '8px', marginLeft: `${level * 16}px`, fontWeight: 'bold' }}>{formattedKey}:</Box>
						{renderNestedJson(value, level + 1)}
					</React.Fragment>
				);
			} else {
				const formattedValue = typeof value === 'number' ? formatBytes(value) : String(value);
				return (
					<Box
						key={key}
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr auto',
							gap: 1,
							marginLeft: `${level * 16}px`,
						}}
					>
						<span>{formattedKey}:</span>
						<span>{formattedValue}</span>
					</Box>
				);
			}
		});
	};

	return (
		<Box sx={{ mt: 1, flex: '1 1 auto', p: 1, overflow: 'auto', minHeight: 0 }}>
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 1 }}>
				<span>Number of Triangles:</span>
				<span>{formattedNumber}</span>
			</Box>
			<Box sx={{ mt: 2 }}>{renderNestedJson(memoryConsumption)}</Box>
		</Box>
	);
};

export default SceneProperties;
