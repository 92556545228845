import { DragEvent, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CanvasContextMenu from './scene/CanvasContextMenu';
import type { MainModule } from '../typings/lys';
import { ImportDataContext } from '../utils/import-data-context';
import { AABB, createJpgBlob, cutoutThumbnail, lysImageBufferToRGBA } from '../utils/thumbnails';
import { useDragItemContext } from './DragItemContext'; // Import the context
import { useTheme } from '@mui/material';
import { User } from '../utils/user';
import { useLysReady } from './LysReadyContext';
import StepFileDialog from '../pages/StepFileDialog';
import { importModelFromBufferInLys, isStepFile, readFileToImportData } from './toolbar/ModelFileImporter';
import { MaterialWithProperties, useMaterials } from './MaterialsContext';
import { SceneTabMode } from '../pages/FigurementApp';

interface BufferWithSize {
	ptr: number;
	size: number;
	width: number;
	height: number;
}

export interface ImageTexture {
	id: string;
	name: string;
	mimeType: string;
	flipY: boolean;
}

export interface MaterialAndTextures {
	material: MaterialWithProperties;
	textures: ImageTexture[];
}

declare global {
	interface MainModuleWrapper {
		setFollowUserId: (userId: number) => void;
		importNamedModel: (modelName: string) => void;
		importFileModel: (modelName: string, x: number, y: number) => void;
		getMaterialNamesList: () => string;
		setUserName: (username: string) => void;
		getMaterialFromNodeId: (nodeId: number) => string;
		setMaterialName: (nodeId: number, name: string) => void;
		setPause: (pause: boolean) => void;
		updateMaterialThumbnails: () => void;
		changeScene: (sceneId: string) => void;
		setSceneName(name: string): void;
		getCurrentImage(): Promise<{ full: Blob; thumbnail: Blob } | null>;
		createTexture(filename: string, buffer: number, size: number): string;
		createEnvironment(filename: string, buffer: number, size: number): string;
		getAllEnvironmentIds(): string[];
		getActiveEnvironmentId(): string | null;
		setActiveEnvironmentId(environmentId: string | null): void;
		captureFrame(): BufferWithSize;
		freeFrame(ptr: number): void;
		_quit: () => void;
	}

	interface Window {
		lys: MainModule & MainModuleWrapper;
		onSceneNameUpdate: (name: string) => void;
		onMaterialListUpdate: (materialList: string) => void;
		onUsersListUpdate: (activeUsersList: string) => void;
		onCameraListUpdate: (cameraList: string) => void;
		onMaterialThumbnailUpdate: (id: number, dataurl: string) => void;
		onUpdateSceneThumbnail: () => void;
		onMouseModeChange: (mode: string) => void;
		onReplayBegin: () => void;
		onReplayEnd: () => void;
		onWebsocketConnectionStatusChanged: (connected: boolean) => void;
	}
}

function debounce<T extends (...args: unknown[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
	let timer: NodeJS.Timeout;
	return (...args: Parameters<T>) => {
		clearTimeout(timer);
		timer = setTimeout(() => func(...args), delay);
	};
}

export type WebGLCanvasProps = {
	user: User;
	sceneId: string;
	mouseMode: string;
	renderMode: string;

	centerAndFit: boolean;
	onCenterAndFitDone: (done: boolean) => void;

	onSceneNameUpdate: (name: string) => void;
	onActiveUsersListChange: (users: any) => void;
	onLoadMessage: (message: string) => void;
	onUpdateSceneThumbnail: () => void;
	onConnectionChanged: (connected: boolean) => void;
	setSceneTabMode: React.Dispatch<React.SetStateAction<SceneTabMode | null>>;
	setMaterialTypes: (materialTypes: any) => void;
};

export type CanvasHandle = {
	canvas: HTMLCanvasElement;
};

const WebGLCanvas = forwardRef<CanvasHandle, WebGLCanvasProps>(
	(
		{
			mouseMode,
			centerAndFit,
			onCenterAndFitDone,
			renderMode,
			onSceneNameUpdate,
			onActiveUsersListChange,
			user,
			setMaterialTypes,
			onLoadMessage,
			onConnectionChanged,
			sceneId,
			onUpdateSceneThumbnail,
			setSceneTabMode,
		}: WebGLCanvasProps,
		canvasRef,
	) => {
		const canvasHeight = useRef(1);
		const canvasWidth = useRef(1);

		const lysStartedInitializing = useRef(false); // strict mode mounts components twice, wasm lys can't survice that
		const { lysIsReady, setLysIsReady } = useLysReady();

		const ref = useRef(null);

		useImperativeHandle(canvasRef, () => ({
			get canvas() {
				return ref.current;
			},
		}));

		const { importData, setImportData } = useContext(ImportDataContext);

		const { draggedItem, clearDraggedItem, dragType } = useDragItemContext();

		const previousNodeIdRef = useRef<number | null>(-1); // No re-renders
		const previousMaterialIdRef = useRef<string | null>(null); // No re-renders

		const { materials } = useMaterials();
		const theme = useTheme(); // Get theme for accessing primary color

		// Dragging a material from the library to the scene
		const [draggingMaterialId, setDraggingMaterialId] = useState<string | null>(null);
		// The textures belonging to the material being dragged
		const [draggingTextures, setDraggingTextures] = useState<ImageTexture[]>([]);
		const [hoveringNodeId, setHoveringNodeId] = useState<number>(0);

		const lysFunctionCall = (mode: string) => {
			if (globalThis.lys == undefined) return;

			switch (mode) {
				case 'Center':
					globalThis.lys._setCenterAndFit();
					break;
				case 'Select':
					globalThis.lys._setControlModeSelect();
					break;
				case 'Tumble':
					globalThis.lys._setControlModeTumble();
					break;
				case 'Pan':
					globalThis.lys._setControlModePan();
					break;
				case 'Dolly':
					globalThis.lys._setControlModeDolly();
					break;
				case 'Zoom':
					globalThis.lys._setControlModeZoom();
					break;
				default:
					console.warn('unknown lys control mode', mode);
			}
		};

		useEffect(() => {
			if (globalThis.lys == undefined || renderMode === undefined) return;

			switch (renderMode) {
				case 'Wire':
					globalThis.lys._setWireFrameRenderMode();
					break;
				case 'Fast':
					globalThis.lys._setRealTimeRenderMode();
					break;
				case 'Photo':
					globalThis.lys._setRaytraceRenderMode();
					break;
				default:
					console.warn('unknown render mode ', renderMode);
			}
		}, [renderMode]);

		useEffect(() => {
			lysFunctionCall(mouseMode);
		}, [mouseMode]);

		useEffect(() => {
			if (globalThis.lys == undefined) return;
			globalThis.lys._setCenterAndFit();
			onCenterAndFitDone(false);
		}, [centerAndFit]);

		useEffect(() => {
			if (!ref.current) return;

			const canvas = ref.current;

			if (dragType === 'fileGrid' || dragType === 'materialList') {
				canvas.style.outlineColor = theme.palette.primary.main;
				canvas.style.outlineWidth = '2px';
				canvas.style.outlineStyle = 'solid';
				canvas.style.outlineOffset = '-2px';
			} else {
				// Reset the outline if no relevant dragType is active
				canvas.style.outline = 'none';
			}
		}, [dragType]);

		useEffect(() => {
			if (!!importData && lysIsReady) {
				if (!isStepFile(importData.filename)) {
					console.log(`Importing file: ${importData?.filename} size=${importData?.buffer.byteLength}`);
					importModelFromBufferInLys(importData.buffer, importData.filename);
					setImportData(null);
				}
				// If it is step file the step file dialog will be shown - see StepFileDialog in this file
			}
		}, [importData, lysIsReady]);

		const handleDragEnter = async (e: DragEvent<HTMLCanvasElement>) => {
			e.preventDefault();

			if (draggedItem) {
				if (dragType == 'fileGrid') {
					const materialsWithTextures: MaterialAndTextures = await copyMaterialFromLibraryToScene(
						draggedItem.id,
						sceneId,
					);

					materialsWithTextures.textures.forEach((t: ImageTexture) =>
						globalThis.lys.addTexture(t.id, t.name, t.mimeType, t.flipY, true),
					);

					globalThis.lys.createMaterialFromJson(JSON.stringify(materialsWithTextures.material));

					setDraggingMaterialId(materialsWithTextures.material.id);
					setDraggingTextures(materialsWithTextures.textures);
				} else if (dragType === 'materialList') {
					setDraggingMaterialId(draggedItem.materialId);
				}
			}
		};

		const handleDragLeave = (e: DragEvent<HTMLCanvasElement>) => {
			e.preventDefault();

			if (draggedItem) {
				setDraggingMaterialId(null);
				setHoveringNodeId(0);
			}
		};

		const handleDragOver = (e: DragEvent<HTMLCanvasElement>) => {
			e.preventDefault();

			if (draggedItem) {
				if (dragType === 'materialList' || dragType === 'fileGrid') {
					const rect = e.currentTarget.getBoundingClientRect();
					const x = e.clientX - rect.left;
					const y = e.clientY - rect.top;
					const nodeId: number = globalThis.lys.getNodeAt(x, y);
					setHoveringNodeId(nodeId);
				}
			}
		};

		const handleDrop = (e: DragEvent<HTMLCanvasElement>) => {
			e.preventDefault();
			e.stopPropagation();

			// Check if this is an internal drag event
			if (draggedItem) {
				setHoveringNodeId(0);
				setDraggingMaterialId(null);
				previousNodeIdRef.current = 0;
				previousMaterialIdRef.current = null;
			} else if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
				// Handle OS filesystem drag
				const file = e.dataTransfer.files[0];
				// Handle .step or .stp file import
				if (file.name.endsWith('.step') || file.name.endsWith('.stp')) {
					readFileToImportData(file, setImportData);
				}
			}

			clearDraggedItem();
			e.dataTransfer.clearData();
		};

		// DRAGGING MATERIALS
		// Update the material and textures when
		// dragging begins or ends, when the copy of the dragged material
		// is copied to the scene, and fully created in lys.
		useEffect(() => {
			if (!lysIsReady) return;
			if (hoveringNodeId > 0) {
				if (previousNodeIdRef.current !== hoveringNodeId) {
					resetMaterialOnNode();

					previousNodeIdRef.current = hoveringNodeId;
					previousMaterialIdRef.current =
						globalThis.lys.getNodeById(hoveringNodeId)?.getMaterial()?.getId() ?? null;
				}

				// The material may not be fully created in lys yet
				if (draggingMaterialId != null && materials.find((m) => m.id === draggingMaterialId)) {
					globalThis.lys.setMaterial(hoveringNodeId, draggingMaterialId, true);
				}
			} else {
				// Not hovering a node
				// re-establish material on node previously hovered
				resetMaterialOnNode();
				previousNodeIdRef.current = 0;
				previousMaterialIdRef.current = null;
			}
		}, [hoveringNodeId, draggingMaterialId, materials, draggedItem, lysIsReady]);

		useEffect(() => {
			if (!lysIsReady) return;

			// Texture dropped outside a node, let's clean up material and textures
			if (draggedItem == null) {
				previousNodeIdRef.current = 0;
				previousMaterialIdRef.current = null;

				if (hoveringNodeId <= 0 && draggingMaterialId != null) {
					console.debug('Cleaning up material and textures', draggingMaterialId);

					deleteMaterial(draggingMaterialId);
					deleteTextures(draggingTextures);
				}
			}
		}, [draggingMaterialId, draggedItem, lysIsReady]);

		function deleteMaterial(materialId: string) {
			globalThis.lys.deleteMaterialById(materialId, true);
			// The material thumnbail is not governed by lys.exe
			fetch(`/api/scenes/${sceneId}/materials/${materialId}/thumbnail`, {
				method: 'DELETE',
			});
		}

		function deleteTextures(textures: ImageTexture[]) {
			if (textures) {
				textures.forEach((t: ImageTexture) => globalThis.lys.removeTexture(t.id, true));
			}
		}

		const resetMaterialOnNode = () => {
			if (previousNodeIdRef.current > 0 && previousMaterialIdRef.current != null) {
				globalThis.lys.setMaterial(previousNodeIdRef.current, previousMaterialIdRef.current, true);
			}
		};

		async function copyMaterialFromLibraryToScene(
			materialId: string,
			sceneId: string,
		): Promise<MaterialAndTextures> {
			const response = await fetch(`/api/materials/${materialId}/applications/scenes/${sceneId}`, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error(`Failed to fetch material texture list with materialsId ${materialId}`);
			}
			return (await response.json()) as MaterialAndTextures;
		}

		const handleBlur = () => {
			if (!window.lys) return;
			console.log('Focus lost, pausing');
			window.lys.setPause(true); //Pause on loosing focus
		};

		const handleFocus = () => {
			if (!window.lys) return;

			console.log('Focus gained, unpausing');
			window.lys.setPause(false);
		};

		const initializeLys = async () => {
			const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

			globalThis.lysIsReady = () => {
				//lys will call back on this function when ready
				console.log('lys is ready');
				setLysIsReady(true);
			};

			try {
				let instance: MainModule & MainModuleWrapper;
				try {
					const mod = isIos
						? await import('../../.build/lys/wasm/bin/lys_no_growth.mjs')
						: await import('../../.build/lys/wasm/bin/lys_growth.mjs');

					instance = await mod.default({
						arguments: ['--open', sceneId, '--username', user.name],
						canvas: (() => document.getElementById('canvas'))(),
					});

					// Proceed with your module logic
				} catch (error) {
					console.error('Module import failed:', error);

					// Create a reload function
					const reload = () => {
						location.reload();
					};

					// Notify the user
					const userConfirmed = window.confirm('Failed to load necessary resources. Click OK to reload now.');

					if (userConfirmed) {
						reload();
					}
				}

				globalThis.criticalError = () => {
					// Notify the user and reload the page
					const reload = () => location.reload();

					const userConfirmed = window.confirm('Critical error encountered: Click OK to reload now.');

					if (userConfirmed) {
						reload();
					}
				};

				console.debug('WebGLCanvas component mounted');
				window['lys'] = instance;
				instance['setSceneName'] = instance.cwrap('setSceneName', null, ['string']);
				instance['importNamedModel'] = instance.cwrap('importNamedModel', null, ['string']);
				instance['changeScene'] = instance.cwrap('changeScene', null, ['string']);
				instance['importFileModel'] = instance.cwrap('importFileModel', null, ['string', 'number', 'number']);
				instance['getMaterialNamesList'] = instance.cwrap('getMaterialNamesList', 'string', null);
				instance['setUserName'] = instance.cwrap('setUserName', null, ['string']);
				instance['setPause'] = instance.cwrap('setPause', null, ['number']);
				instance['updateMaterialThumbnails'] = instance.cwrap('updateMaterialThumbnails', null, null);
				instance['createTexture'] = instance.cwrap('createTexture', 'string', [
					'string',
					'number',
					'number',
					'boolean',
				]);
				instance['createEnvironment'] = instance.cwrap('createEnvironment', 'string', [
					'string',
					'number',
					'number',
					'boolean',
				]);
				instance['getCurrentImage'] = async () => {
					const bufferAndSize = instance.captureFrame();
					const array = new Uint8Array(instance.HEAP8.buffer, bufferAndSize.ptr, bufferAndSize.size);
					const imageData = lysImageBufferToRGBA(array, bufferAndSize.width, bufferAndSize.height);
					instance.freeFrame(bufferAndSize.ptr);
					const aabb: AABB = cutoutThumbnail(imageData);

					if (!aabb) return null;

					return {
						full: await createJpgBlob(imageData),
						thumbnail: await createJpgBlob(imageData, aabb, {
							width: 128,
							height: 128,
						}),
					};
				};

				setMaterialTypes(JSON.parse(window.lys.getMaterialNamesList()));

				window.addEventListener('blur', handleBlur);
				window.addEventListener('focus', handleFocus);

				const focus: boolean = document.hasFocus();
				console.log('Focus: ', focus);

				window.lys.setPause(!focus);

				//wasm loaded - erase default "loading wasm"
				onLoadMessage('');
			} catch (error) {
				if (error.name == 'ExitStatus') console.debug('WASM exit gracefully');
				else if (error.name == 'RuntimeError') {
					console.error('RuntimeError from Wasm: ', error);
				} else {
					console.error('Unexpected error during Wasm quit', error);
				}
			}
		};

		useEffect(() => {
			if (!ref.current || !ref.current.parentElement) return;

			const parent = ref.current.parentElement;

			// Debounced resize handler
			const debouncedResizeLys = debounce((width, height) => {
				if (globalThis.lys) {
					globalThis.lys._setSize(width, height);
				}
				ref.current.style.visibility = 'visible';
			}, 50);

			const updateCanvasSize = () => {
				const width = parent.clientWidth;
				const height = parent.clientHeight;

				// Skip updates if the size hasn't changed
				if (canvasWidth.current === width && canvasHeight.current === height) {
					return;
				}

				// Update canvas dimensions
				canvasWidth.current = width;
				canvasHeight.current = height;

				ref.current.style.width = `${width}px`;
				ref.current.style.height = `${height}px`;
				ref.current.width = width;
				ref.current.height = height;

				ref.current.style.visibility = 'hidden';

				// Trigger debounced WebGL resize
				debouncedResizeLys(width, height);
			};

			const observer = new ResizeObserver(() => updateCanvasSize());
			observer.observe(parent);

			// Initial size setup
			updateCanvasSize();

			// Cleanup observer on unmount
			return () => {
				observer.disconnect();
			};
		}, [ref]);

		useEffect(() => {
			if (!lysStartedInitializing.current) {
				lysStartedInitializing.current = true;
				initializeLys();

				window.onSceneNameUpdate = (name: string) => {
					onSceneNameUpdate(name);
				};
				window.onUsersListUpdate = (activeUsersList) => {
					onActiveUsersListChange(JSON.parse(activeUsersList));
				};
				window.onUpdateSceneThumbnail = () => {
					onUpdateSceneThumbnail();
				};
				window.onWebsocketConnectionStatusChanged = (connected: boolean) => {
					onConnectionChanged(connected);
				};
			}
			return () => {
				// signal to other components they should no longer use window.lys.
				setLysIsReady(false);

				window.removeEventListener('blur', handleBlur);
				window.removeEventListener('focus', handleFocus);

				if (window.lys) {
					console.log('WebGLCanvas component unmounted');

					console.log('WebAssembly memory before quit:', window.lys.HEAP8.length / 1024 / 1024, 'MB');
					window.lys?._quit();
					window.lys = null;
				}
			};
		}, []);

		useEffect(() => {
			if (globalThis.lys) {
				console.debug('Scene change to: ', sceneId, 'but reusing lys.wasm');
				globalThis.lys.changeScene(sceneId);
			}
		}, [sceneId]);

		const [rightClickMenuLocation, setRightClickMenuLocation] = useState(null);
		const [eventCoordinates, setEventCoordinates] = useState<[number, number] | null>(null);

		// Create a new left-click event
		const leftClickEvent = new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window,
		});

		const handleRightClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
			console.log('Right click event');
			// Find the target element or the element you want to dispatch the event to
			const targetElement = event.currentTarget; // or any specific element

			// Dispatch the left-click event
			targetElement.dispatchEvent(leftClickEvent);

			const x = event.clientX;
			const y = event.clientY;
			setRightClickMenuLocation([x, y]);

			const rect = event.currentTarget.getBoundingClientRect();
			setEventCoordinates([x - rect.left, y - rect.top]);

			event.preventDefault();
			event.stopPropagation();
		};

		const handleDoubleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
			const rect = e.currentTarget.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;

			const nodeId: number = globalThis.lys.getNodeAt(x, y);

			if (nodeId >= 0) setSceneTabMode(SceneTabMode.material);
			else setSceneTabMode(SceneTabMode.environment);

			e.preventDefault();
			e.stopPropagation();
		};

		return (
			<>
				<canvas
					ref={ref}
					onDrop={(e) => handleDrop(e)}
					onDragOver={(e) => handleDragOver(e)}
					onDragEnter={(e) => handleDragEnter(e)}
					onDragLeave={(e) => handleDragLeave(e)}
					style={{
						position: 'absolute',
						boxSizing: 'border-box',
						borderWidth: 0,
						margin: 0,
						display: 'block',
						zIndex: 0,
						width: `${canvasWidth.current}px`,
						height: `${canvasHeight.current}px`,
						overflow: 'hidden',
					}}
					width={canvasWidth.current}
					height={canvasHeight.current}
					onContextMenu={handleRightClick}
					onDoubleClick={handleDoubleClick}
					className="App-renderwindow"
					id="canvas"
				/>
				<CanvasContextMenu
					sceneId={sceneId}
					eventCoordinates={eventCoordinates}
					location={rightClickMenuLocation}
					handleClose={() => setRightClickMenuLocation(null)}
				/>
				{lysIsReady && (
					<StepFileDialog
						importData={importData}
						onLoadMessage={onLoadMessage}
						onClose={() => setImportData(null)}
					/>
				)}
				{
					// eslint-disable-next-line react/no-unknown-property
					lysIsReady && <span e2e-id="lys-is-ready" />
				}
			</>
		);
	},
);
WebGLCanvas.displayName = 'WebGLCanvas';
export default WebGLCanvas;
