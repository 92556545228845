import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface UpdateContextType {
	reloadLibrary: () => void; // Function to trigger a reload
	doLibraryReload: number; // Variable to track when reload should happen
}

// Create context with undefined default value
const UpdateContext = createContext<UpdateContextType | undefined>(undefined);

interface LibraryReloadProviderProps {
	children: ReactNode;
}

// Create a provider component
export const LibraryReloadProvider = ({ children }: LibraryReloadProviderProps) => {
	const [doLibraryReload, setDoLibraryReload] = useState(0);

	// Function to trigger a reload by incrementing doLibraryReload
	const reloadLibrary = () => setDoLibraryReload((n) => n + 1);

	return <UpdateContext.Provider value={{ doLibraryReload, reloadLibrary }}>{children}</UpdateContext.Provider>;
};

// Custom hook to use the UpdateContext
export const useLibraryUpdateContext = (): UpdateContextType => {
	const context = useContext(UpdateContext);
	if (!context) {
		throw new Error('useLibraryUpdateContext must be used within a LibraryReloadProvider');
	}
	return context;
};
