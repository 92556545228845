import '@mui/material/styles/styled';
import { createRoot } from 'react-dom/client';
import App from './App';
import './manifest.json';

// don't render if in a web worker
if (typeof window !== 'undefined') {
	const container = document.getElementById('root');
	const root = createRoot(container); // createRoot(container!) if you use TypeScript

	root.render(<App />);
}
