// VerticalMenu.tsx
import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Badge } from '@mui/material';
import { SyntheticEvent } from 'react';
import SceneIcon from '@mui/icons-material/Category';
import ChatIcon from '@mui/icons-material/Chat';
import MaterialIcon from '@mui/icons-material/SportsSoccer';
import EnvironmentIcon from '@mui/icons-material/Language';
import CameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import ImageIcon from '@mui/icons-material/AspectRatio';
import ShotIcon from '@mui/icons-material/BurstMode';
import { CameraAlt } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { SceneTabMode } from '../../pages/FigurementApp';

type VerticalMenuProps = {
	sceneTabMode: SceneTabMode | null;
	handleChange: (_event: SyntheticEvent | null, newTabIndex: SceneTabMode) => void;
	canEdit: boolean;
	newRenderCounter: number;
	newChatMessagesCounter: number;
};

const VerticalMenu: React.FC<VerticalMenuProps> = ({
	sceneTabMode,
	handleChange,
	canEdit,
	newRenderCounter,
	newChatMessagesCounter,
}) => {
	const menuItems = [
		{
			mode: SceneTabMode.scene,
			icon: <SceneIcon />,
			label: 'Scene',
			show: canEdit,
		},
		{
			mode: SceneTabMode.material,
			icon: <MaterialIcon />,
			label: 'Material',
			show: canEdit,
			e2eId: 'tab-material',
		},
		{
			mode: SceneTabMode.camera,
			icon: <CameraIcon />,
			label: 'Camera',
			show: canEdit,
		},
		{
			mode: SceneTabMode.environment,
			icon: <EnvironmentIcon />,
			label: 'Env.',
			show: canEdit,
		},
		{
			mode: SceneTabMode.image,
			icon: <ImageIcon />,
			label: 'Image',
			show: canEdit,
		},
		{
			mode: SceneTabMode.shot,
			icon: <ShotIcon />,
			label: 'Shot',
			show: canEdit,
		},
		{
			mode: SceneTabMode.render,
			icon: (
				<Badge badgeContent={newRenderCounter} color="primary">
					<CameraAlt />
				</Badge>
			),
			label: 'Renders',
			show: canEdit,
		},
		{
			mode: SceneTabMode.chat,
			icon: (
				<Badge badgeContent={newChatMessagesCounter} color="primary">
					<ChatIcon />
				</Badge>
			),
			label: 'Chat',
			show: true,
		},
	];

	return (
		<Box
			sx={{
				width: 70,
				minWidth: 70,
				maxWidth: 70,
				flexShrink: 0,
				flexGrow: 0,
			}}
		>
			<List>
				{menuItems.map((item) => {
					if (!item.show) {
						return null;
					}
					return (
						<ListItem
							button
							key={item.mode}
							selected={sceneTabMode === item.mode}
							onClick={() => handleChange(null, item.mode)}
							{...(item.e2eId && { 'e2e-id': item.e2eId })}
							sx={{ flexDirection: 'column', alignItems: 'center', padding: 1 }}
						>
							<ListItemIcon
								sx={{
									minWidth: 'auto',
									color: sceneTabMode === item.mode ? 'primary.main' : 'inherit',
								}}
							>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										sx={{
											fontSize: '0.75rem',
											textAlign: 'center',
											textTransform: 'none',
										}}
									>
										{item.label}
									</Typography>
								}
							/>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
};

export default VerticalMenu;
