import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export type Vec3 = { x: number; y: number; z: number };
export type PointInNode = { id: number; point: Vec3 };

// Define the context type
export interface SelectedNodesContextType {
	selectedNodeIds: number[];
	selectedPointInNodes: PointInNode[];
	setSelectedNodeIds: (ids: number[]) => void;
}

// Create context with undefined default value
const SelectedNodesContext = createContext<SelectedNodesContextType | undefined>(undefined);

interface SelectedNodesProviderProps {
	children: ReactNode;
}

// Create a provider component
export const SelectedNodesProvider = ({ children }: SelectedNodesProviderProps) => {
	const [selectedNodeIds, setSelectedNodeIds] = useState<number[]>([]);
	const [selectedPointInNodes, setSelectedPointInNodes] = useState<PointInNode[]>([]);
	const [forceUpdate, setForceUpdate] = useState(0);

	// Helper function to compare arrays
	const arraysAreEqual = (arr1: number[] | PointInNode[], arr2: number[] | PointInNode[]) => {
		if (arr1.length !== arr2.length) return false;

		return arr1.every((value, index) => {
			if (typeof value === 'number' && typeof arr2[index] === 'number') {
				return value === arr2[index];
			}
			return (value as PointInNode).id === (arr2[index] as PointInNode).id;
		});
	};

	useEffect(() => {
		globalThis.updateSelectedNodeIds = () => setForceUpdate((n) => n + 1);

		return () => {
			delete globalThis.updateSelectedNodeIds;
		};
	}, []);

	// useEffect to retrieve selected node IDs from globalThis.lys when forceUpdateCount changes
	useEffect(() => {
		if (!globalThis.lys) return;

		const retrievedNodePoints: PointInNode[] = globalThis.lys.getSelectedPointInNodes();
		// Avoid setting state if the new node IDs are the same as the current state
		if (retrievedNodePoints && Array.isArray(retrievedNodePoints)) {
			const retrievedNodeIds = retrievedNodePoints.map((p) => p.id);
			if (!arraysAreEqual(retrievedNodeIds, selectedNodeIds)) {
				setSelectedNodeIds(retrievedNodeIds);
			}
			setSelectedPointInNodes(retrievedNodePoints);
		}
	}, [forceUpdate]);

	// useEffect to retrieve selected node IDs from globalThis.lys when setSelectedNodeIds is used
	useEffect(() => {
		if (!globalThis.lys) return;

		const previouslySelectedNodePoints: PointInNode[] = globalThis.lys.getSelectedPointInNodes();
		const retrievedNodeIds = previouslySelectedNodePoints.map((p) => p.id);

		// Avoid setting state if the new node IDs are the same as the current state
		if (!arraysAreEqual(retrievedNodeIds, selectedNodeIds)) {
			globalThis.lys.setSelectedNodeIds(selectedNodeIds);
			setForceUpdate((p) => p + 1);
		}
	}, [selectedNodeIds]);

	return (
		<SelectedNodesContext.Provider value={{ selectedNodeIds, setSelectedNodeIds, selectedPointInNodes }}>
			{children}
		</SelectedNodesContext.Provider>
	);
};

// Custom hook to use the SelectedNodesContext
export const useSelectedNodes = (): SelectedNodesContextType => {
	const context = useContext(SelectedNodesContext);
	if (!context) {
		throw new Error('useSelectedNodes must be used within a SelectedNodesProvider');
	}
	return context;
};
