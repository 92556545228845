import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const FTextField: React.FC<TextFieldProps> = ({ sx = {}, ...props }) => {
	return (
		<TextField
			size="small"
			variant="outlined"
			hiddenLabel
			sx={{
				'& legend': { display: 'none' }, // Hides the legend for a cleaner look
				'& fieldset': { top: 0 }, // Aligns the fieldset border correctly
				height: '32px',
				'& .MuiInputBase-root': {
					height: '32px',
					padding: '0px 8px', // Adjust padding around the text field
				},
				'& .MuiInputBase-input': {
					height: '32px',
					padding: '0px', // Remove default padding
					lineHeight: '32px', // Center text vertically
				},
				...sx, // Merge with any custom styles provided via the sx prop
			}}
			{...props} // Spread the rest of the props
		/>
	);
};

export default FTextField;
