import { ChangeEventHandler, createRef, forwardRef, SyntheticEvent, useImperativeHandle } from 'react';

export interface FileImporter {
	openDialog: () => void;
}

export type FileImporterProps = {
	onFileRead: (buffer: ArrayBuffer, filename: string) => void;
};

export const FileImporter = forwardRef(({ onFileRead }: FileImporterProps, ref) => {
	const inputRef = createRef<HTMLInputElement>();

	useImperativeHandle(ref, () => ({
		openDialog: () => {
			inputRef.current.click();
		},
	}));

	const onFileChange = (e: SyntheticEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.currentTarget.files.length === 0) return;

		const reader = new FileReader();
		const filename = e.currentTarget.files[0].name;
		reader.onload = (e: ProgressEvent<FileReader>) => onFileRead(e.target.result as ArrayBuffer, filename);
		reader.readAsArrayBuffer(e.currentTarget.files[0]);
	};

	return <input type="file" onChange={onFileChange} ref={inputRef} style={{ display: 'none' }} />;
});
