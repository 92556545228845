import { ArrowCircleUp, SearchOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { IconButton, PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import FButton from '../components/FButton';
import UserAvatar from '../components/toolbar/UserAvatar';
import { AuthenticatedUser, createTemporarySceneName } from '../utils/user';
import { createAndGotoNewScene } from '../utils/scene';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ModelFileImporter } from '../components/toolbar/ModelFileImporter';
import * as FileApi from '../services/file-api';
import ResizableContainer from '../components/ResizableContainer';
import FileGrid from '../components/asset-library/FileGrid';
import FTextField from '../components/FTextField';
import FolderTree from '../components/asset-library/FolderTree';
import { useMediaQuery, useTheme } from '@mui/material';

export function changeExtension(destPath: string, extension: string) {
	return destPath.replace(/\.[^/.]+$/, extension);
}

export type FilesPageProps = {
	user: AuthenticatedUser;
	onThemeModeChange: (mode: PaletteMode) => void;
};

export default function FilesPage({ user, onThemeModeChange }: FilesPageProps) {
	const navigate = useNavigate();
	const { '*': rest } = useParams();

	const theme = useTheme();

	// Detect screen sizes
	const [show, setShow] = useState<[boolean, boolean]>([true, false]);
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));

	const [fileList, setFileList] = useState([]);
	const [folderList, setFolderList] = useState([]);
	const [folderTree, setFolderTree] = useState<FileApi.FSEntry[]>([]);

	const [breadcrumb, setBreadcrumb] = useState<FileApi.FSEntry[]>([]);

	const imageContainerRef = useRef(null);
	const modelImporter = useRef(null);

	useEffect(() => {
		if (isXs || isSm) {
			setShow([false, true]);
		} else {
			setShow([true, true]);
		}
	}, [isXs, isSm]);

	useEffect(() => {
		FileApi.loadFolders().then(setFolderTree);
		FileApi.loadFilesForFolder(rest, false).then((jsonData) => {
			const files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== 'folder');
			setFileList(files);
			setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === 'folder'));
		});
	}, []);

	useEffect(() => {
		if (folderTree.length > 0) {
			const path = buildBreadcrumb(folderTree, rest);
			setBreadcrumb(path);
		}
	}, [folderTree, rest]);

	const onImportClick = () => {
		modelImporter.current.openDialog();
	};

	const onFileRead = (filename: string) => {
		createAndGotoNewScene(changeExtension(filename, ''), navigate);
	};

	const handleAddSceneClick = async () => {
		createAndGotoNewScene(createTemporarySceneName(), navigate);
	};

	function handleSelectedPath(path: string): void {
		navigate(`/files/${path}`, { replace: false });
	}

	const clickOnFolderIcon = async (id: string) => {
		onSelectNode(id);
		navigate(`/files/${id}`, { replace: true });
	};

	const onSelectNode = async (id: string): Promise<void> => {
		FileApi.loadFilesForFolder(id, false).then((jsonData) => {
			let files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== 'folder');
			if (files.every((f) => f.type === 'render')) {
				files = files.sort((a, b) => a.modifiedAgo - b.modifiedAgo);
			}
			setFileList(files);
			setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === 'folder'));
		});
		handleSelectedPath(id);
	};

	return (
		<>
			<main
				style={{
					display: 'flex',
					height: '100%',
					width: '100%',
				}}
			>
				<ResizableContainer
					show={show}
					dividerVariant="hidden"
					defaultSizes={['250px', 1]}
					orientation={'horizontal'}
				>
					<Box
						style={{
							display: 'flex',
							gap: '16px',
							height: '100%',
							width: '100%',
							flexDirection: 'column',
							padding: '16px',
						}}
					>
						<div
							style={{
								display: 'flex',
								gap: '4px',
								alignItems: 'center',
							}}
						>
							<h1 style={{ margin: 0, padding: '0 8px' }}>Figurement</h1>
						</div>
						<FolderTree folderTree={folderTree} selectedNodeId={rest} onSelectNode={onSelectNode} />
					</Box>
					<Box
						style={{
							display: 'flex',
							height: '100%',
							width: '100%',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								display: 'flex',
								padding: '16px 16px 16px 16px',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<FTextField
								sx={{
									pr: 2,
									flex: 1,
									display: {
										xs: 'none',
										sm: 'none',
										md: 'flex',
										lg: 'flex',
									},
								}}
								id="standard-bare"
								InputProps={{
									endAdornment: (
										<IconButton>
											<SearchOutlined />
										</IconButton>
									),
									style: { borderRadius: '5px' },
								}}
							/>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '16px', // Space between buttons
									paddingRight: '8px', // Padding around buttons
								}}
							>
								<FButton
									startIcon={<AddBoxIcon />}
									size="small"
									variant="contained"
									tooltip="New Empty Scene"
									e2e-id="new-scene"
									onClick={handleAddSceneClick}
								>
									New Scene
								</FButton>

								<ModelFileImporter ref={modelImporter} onFileRead={onFileRead} />

								<FButton
									startIcon={<ArrowCircleUp />}
									tooltip="Import 3D/CAD files"
									onClick={onImportClick}
								>
									Import File
								</FButton>
							</div>

							<div
								style={{
									marginLeft: 'auto', // Push avatar to the far right
								}}
							>
								<UserAvatar user={user} onThemeModeChange={onThemeModeChange} />
							</div>
						</div>
						<Box
							ref={imageContainerRef}
							sx={{
								overflow: 'auto',
								padding: '8px 16px 16px 16px',
								flex: 1,
								backgroundColor: 'background.area',
							}}
						>
							<FileGrid
								breadCrumb={breadcrumb}
								folderList={folderList}
								fileList={fileList}
								onSelectFolder={clickOnFolderIcon}
								onChange={() => onSelectNode(rest)}
							/>
						</Box>
					</Box>
				</ResizableContainer>
			</main>
		</>
	);
}

function buildBreadcrumb(nodes: FileApi.FSEntry[], targetId: string): FileApi.FSEntry[] {
	const path: FileApi.FSEntry[] = [];
	const findAndBuildPath = (nodes: FileApi.FSEntry[], targetId: string): boolean => {
		for (const node of nodes) {
			if (node.id === targetId) {
				path.push(node);
				return true;
			}
			if (node.children) {
				const foundInChildren = findAndBuildPath(node.children, targetId);
				if (foundInChildren) {
					path.unshift(node);
					return true;
				}
			}
		}
		return false;
	};
	findAndBuildPath(nodes, targetId);
	return path;
}
