import React, { useState, useRef, ChangeEvent, useCallback } from 'react';
import { TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBarProps {
	onSearch: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const handleSearchChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			setSearchTerm(value);

			// Clear previous debounce timer
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}

			// Set new debounce timer
			debounceTimeout.current = setTimeout(() => {
				onSearch(value);
			}, 300); // Adjust the delay as needed (300ms here)
		},
		[onSearch],
	);

	const handleClear = (): void => {
		if (inputRef.current) {
			inputRef.current.value = '';
			setSearchTerm('');
			onSearch('');
		}
	};

	return (
		<TextField
			inputRef={inputRef}
			sx={{
				p: 1,
				m: 0,
				paddingTop: 1,
				paddingBottom: 1,
				'& legend': { display: 'none' },
				'& fieldset': { top: 0 },
			}}
			hiddenLabel
			size="small"
			fullWidth
			variant="outlined"
			onChange={handleSearchChange}
			placeholder="Search..."
			aria-label="Search"
			role="searchbox"
			InputProps={{
				endAdornment: (
					<IconButton
						sx={{
							padding: 0, // Remove padding around the IconButton
							margin: 0, // Remove margin around the IconButton
						}}
						onClick={handleClear}
						aria-label="clear search"
						disabled={!searchTerm.trim()} // Disable if the search term is empty
					>
						<ClearIcon
							sx={{
								margin: 1, // Ensure no margin inside the IconButton
							}}
						/>
					</IconButton>
				),
				sx: {
					paddingRight: '0px', // Remove right padding in the TextField
				},
			}}
		/>
	);
};

export default SearchBar;
