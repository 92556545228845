import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

export type Access = 'no access' | 'can edit' | 'can view';

export const AccessMenu = ({ currentAccess, onAccessChange }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (access: Access) => {
		onAccessChange(access);
		handleClose();
	};

	return (
		<>
			<Button
				sx={{ width: '100px', textTransform: 'none' }}
				endIcon={<ArrowDropDown />}
				onClick={handleClick}
				variant="outlined"
				size="small"
			>
				{currentAccess}
			</Button>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuItem onClick={() => handleMenuItemClick('no access')}>no access</MenuItem>
				<MenuItem onClick={() => handleMenuItemClick('can edit')}>can edit</MenuItem>
				<MenuItem onClick={() => handleMenuItemClick('can view')}>can view</MenuItem>
			</Menu>
		</>
	);
};
