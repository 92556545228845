export function debounceForBroadcast<T extends (...args: any[]) => void>(
	func: (...args: [...Parameters<T>, boolean]) => void,
	wait: number,
): (...args: Parameters<T>) => void {
	let timeout: ReturnType<typeof setTimeout> | null = null;

	return function (...args: Parameters<T>) {
		func(...args, false); // Call immediately with "false"

		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func(...args, true); // Debounced call with "true"
		}, wait);
	};
}
