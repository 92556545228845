import { Box, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { Fragment, Children, useCallback, useRef, useState, useEffect } from 'react';

export type ResizableContainerProps = {
	children: React.ReactNode;
	defaultSizes?: Array<number | string>;
	show?: boolean[];
	orientation?: 'horizontal' | 'vertical';
	dividerVariant?: 'fullWidth' | 'hidden' | 'middle';
	sx?: SxProps<Theme>;
	style?: React.CSSProperties;
};

export default function ResizableContainer({
	children,
	defaultSizes,
	orientation = 'vertical',
	dividerVariant = 'fullWidth',
	sx,
	show,
	style,
}: ResizableContainerProps) {
	const theme = useTheme();
	const panelRefs = useRef<(HTMLDivElement | null)[]>([]);

	// Initialize sizes and minSizes as state variables (mapped by unique keys)
	const [sizes, setSizes] = useState<{ [key: string]: number | string }>({});
	const [minSizes, setMinSizes] = useState<{ [key: string]: number }>({});
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	const [visibleChildren, setVisibleChildren] = useState<Array<{ child: React.ReactNode; key: string }>>([]);

	useEffect(() => {
		const childrenArray = Children.toArray(children);

		// Map over all children to determine visibility, sizes, and keys
		const childData = childrenArray.map((child: React.ReactElement, index) => {
			const isVisible = !show || show[index] !== false; // Default to true if show is undefined
			const size = defaultSizes ? defaultSizes[index] : 1;
			const minSize = typeof size === 'string' && size.endsWith('px') ? parseFloat(size) : 0;
			const key = child.key != null ? child.key : index.toString();

			return {
				child,
				index,
				key,
				size,
				minSize,
				visible: isVisible,
			};
		});

		// Build new sizes and minSizes objects
		const newSizes = { ...sizes }; // Copy previous sizes
		const newMinSizes = { ...minSizes }; // Copy previous minSizes

		const newVisibleChildren: Array<{ child: React.ReactNode; key: string }> = [];

		childData.forEach((cd) => {
			if (cd.visible) {
				if (!(cd.key in sizes)) {
					// New visible child, assign default size
					newSizes[cd.key] = cd.size;
				}
				newMinSizes[cd.key] = cd.minSize;
				newVisibleChildren.push({ child: cd.child, key: cd.key });
			} else {
				// Child is not visible, remove size
				delete newSizes[cd.key];
				delete newMinSizes[cd.key];
			}
		});

		setSizes(newSizes);
		setMinSizes(newMinSizes);
		setVisibleChildren(newVisibleChildren);
	}, [children, defaultSizes, show]);

	const handleMouseDown = useCallback(
		(index: number, event: MouseEvent) => {
			event.preventDefault();

			const isHorizontal = orientation === 'horizontal';
			const startPosition = isHorizontal ? event.clientX : event.clientY;

			const startSizes = visibleChildren.map(({ key }, idx) => {
				const panel = panelRefs.current[idx];
				return panel?.getBoundingClientRect()?.[isHorizontal ? 'width' : 'height'] ?? 0;
			});

			const onMouseMove = (moveEvent: MouseEvent) => {
				window.getSelection()?.removeAllRanges();

				const currentPosition = isHorizontal ? moveEvent.clientX : moveEvent.clientY;
				const delta = currentPosition - startPosition;

				const newSizesArray = [...startSizes];
				const minSizeCurrent = minSizes[visibleChildren[index].key];
				const minSizeNext = minSizes[visibleChildren[index + 1].key];

				newSizesArray[index] = Math.max(startSizes[index] + delta, minSizeCurrent);
				newSizesArray[index + 1] = Math.max(startSizes[index + 1] - delta, minSizeNext);

				// Update sizes in state
				const updatedSizes = { ...sizes };
				visibleChildren.forEach(({ key }, idx) => {
					if (typeof sizes[key] === 'string' && (sizes[key] as string).endsWith('px')) {
						// Fixed-size panel
						updatedSizes[key] = `${newSizesArray[idx]}px`;
					} else {
						// Flexible panel
						updatedSizes[key] = newSizesArray[idx];
					}
				});

				setSizes(updatedSizes);
			};

			const onMouseUp = () => {
				document.removeEventListener('mousemove', onMouseMove);
				document.removeEventListener('mouseup', onMouseUp);
			};

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		},
		[sizes, orientation, minSizes, visibleChildren],
	);

	const panelStyle = (size: number | string) => {
		const commonStyles = {
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			flexShrink: 1,
		};

		if (typeof size === 'string' && size.endsWith('px')) {
			return {
				flex: `0 0 ${size}`,
				...commonStyles,
			};
		} else if (typeof size === 'number') {
			return {
				flexGrow: size,
				flexShrink: 1,
				flexBasis: 0,
				...commonStyles,
			};
		} else {
			return {
				flexGrow: 1,
				flexShrink: 1,
				flexBasis: 0,
				...commonStyles,
			};
		}
	};

	const dividerStyle = {
		position: 'relative',
		cursor: orientation === 'horizontal' ? 'ew-resize' : 'ns-resize',
		flexShrink: 0,
		flexGrow: 0,
		[orientation === 'horizontal' ? 'width' : 'height']: dividerVariant === 'hidden' ? '0px' : '8px', // Draggable area size
		[orientation === 'horizontal' ? 'height' : 'width']: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexBasis: 'auto',
	};

	const dividerInnerStyle = {
		backgroundColor: theme.palette.divider,
		zIndex: 1,
		[orientation === 'horizontal' ? 'width' : 'height']: '1px',
		[orientation === 'horizontal' ? 'height' : 'width']: '100%',
		maxWidth:
			orientation === 'vertical'
				? dividerVariant === 'fullWidth'
					? '100%'
					: dividerVariant === 'hidden'
						? '0%'
						: dividerVariant === 'middle'
							? '20%'
							: '100%'
				: undefined,
		maxHeight:
			orientation === 'horizontal'
				? dividerVariant === 'fullWidth'
					? '100%'
					: dividerVariant === 'hidden'
						? '0%'
						: dividerVariant === 'middle'
							? '20%'
							: '100%'
				: undefined,
	};

	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column', // Ensure proper flex direction
				minHeight: 0, // Allow flex items to shrink
				...sx,
			}}
			style={style}
		>
			<Stack
				direction={orientation === 'vertical' ? 'column' : 'row'}
				sx={{ height: '100%', width: '100%', flexGrow: 1, minHeight: 0 }}
				spacing={0}
			>
				{visibleChildren.map(({ child, key }, index) => (
					<Fragment key={key}>
						<Box
							ref={(el: HTMLDivElement | null) => (panelRefs.current[index] = el)}
							className="resizable-panel"
							sx={panelStyle(sizes[key])}
						>
							{child}
						</Box>
						{index < visibleChildren.length - 1 && (
							<Box className="resizable-divider" sx={dividerStyle}>
								<Box
									sx={{
										...dividerInnerStyle,
										backgroundColor:
											hoveredIndex === index ? theme.palette.action.hover : theme.palette.divider,
									}}
								/>

								<Box
									onMouseDown={(e: React.MouseEvent) => handleMouseDown(index, e.nativeEvent)}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									sx={{
										position: 'absolute',
										top: '-4px', // Extend beyond parent's bounds
										bottom: '-4px',
										left: '-4px',
										right: '-4px',
										cursor: orientation === 'horizontal' ? 'ew-resize' : 'ns-resize',
										backgroundColor:
											hoveredIndex === index ? theme.palette.action.selected : 'transparent', // Light blue on hover
										opacity: 1,
										pointerEvents: 'auto',
										zIndex: 2,
									}}
								/>
							</Box>
						)}
					</Fragment>
				))}
			</Stack>
		</Box>
	);
}
