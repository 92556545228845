import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { sendResetPasswordRequest } from '../ResetPasswordPage';
import UserProfileForm, { UserAction, UserProfile } from '../../components/users/UserProfileForm';
import { User } from '../../utils/user';
import AdminHeader from './AdminHeader';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
	'--DataGrid-containerBackground': 'background.paper',
	'& .MuiDataGrid-root': {
		backgroundColor: 'var(--DataGrid-containerBackground)',
	},
}));

export default function AdminUsersList({ user }: { user: User }) {
	const appbarHeight = isMobile ? '74.5px' : '47px';

	const handleMenuClose = () => {
		setSelectedRow(null);
	};

	type UserInfo = {
		id: string;
		email: string;
		name: string;
		company: string;
		isVerified: boolean;
		enabled: boolean;
	};

	const [users, setUsers] = useState([] as UserInfo[]);
	const [selectedRow, setSelectedRow] = useState<UserInfo>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [openCreateUserMenu, setOpenCreateUserMenu] = useState(false);

	const enableUser = async (email: string) => {
		await sendResetPasswordRequest(email);
		setSelectedRow(null);
		fetchUsers();
	};

	const deleteUser = async (id: string) => {
		await fetch('/api/admin/users/' + id, {
			method: 'DELETE',
		});

		setSelectedRow(null);
		fetchUsers();
	};

	const columns: GridColDef[] = [
		{ field: 'creationTime', headerName: 'Time', width: 150 },
		{ field: 'id', headerName: 'ID' },
		{ field: 'email', headerName: 'Email', width: 180 },
		{ field: 'name', headerName: 'Name', width: 130 },
		{ field: 'company', headerName: 'Company', width: 100 },
		{ field: 'isVerified', headerName: 'Verified', type: 'boolean' },
		{ field: 'enabled', headerName: 'Enabled', type: 'boolean' },
		{ field: 'gdprConsent', headerName: 'GDPR Consent', type: 'boolean' },
		{
			field: 'action',
			headerName: 'Action',
			width: 70,
			renderCell: (params) => {
				const open = params.row === selectedRow;
				return (
					<div>
						<IconButton
							aria-label="more"
							aria-controls={open ? 'long-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-haspopup="true"
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setSelectedRow(params.row);
							}}
						>
							<MoreVertIcon />
						</IconButton>
					</div>
				);
			},
		},
	];

	function fetchUsers() {
		fetch('/api/admin/users')
			.then((res) => res.json())
			.then((users) => setUsers(users));
	}

	useEffect(() => {
		fetchUsers();
	}, [openCreateUserMenu]);

	return (
		<Box
			sx={{
				height: '100vh',
				width: '100%',
				backgroundColor: 'background.paper',
			}}
		>
			<AdminHeader user={user} onNewUserClick={() => setOpenCreateUserMenu(true)} />
			<CustomDataGrid
				rows={users}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 100 },
					},
				}}
				pageSizeOptions={[10, 25, 50, 100]}
				sx={{ paddingTop: appbarHeight }}
			/>

			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				open={Boolean(selectedRow)}
				anchorEl={anchorEl}
				onClose={handleMenuClose}
			>
				<MenuItem
					key={'Enable'}
					onClick={(event) => enableUser(selectedRow.email)}
					disabled={selectedRow?.enabled}
				>
					Enable
				</MenuItem>
				<MenuItem key={'Delete'} onClick={(event) => deleteUser(selectedRow.id)}>
					Delete
				</MenuItem>
			</Menu>
			<CreateUserDialog open={openCreateUserMenu} setOpen={setOpenCreateUserMenu} />
		</Box>
	);
}

type CreateUserDialogProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function CreateUserDialog({ open, setOpen }: CreateUserDialogProps) {
	function handleClose() {
		setOpen(false);
	}

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialogContent-root': {
			padding: theme.spacing(2),
		},
		'& .MuiDialogActions-root': {
			padding: theme.spacing(1),
		},
	}));

	function onSubmit(user: UserProfile, password: string): Promise<Response> {
		type Data = { [key: string]: string };
		const data: Data = {};
		data.email = user.email;
		data.name = user.name;
		data.company = user.company;
		if (password) {
			data.password = password;
		}

		return fetch('/api/admin/users', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
	}

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				Create new user
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={(event) => {
					handleClose();
				}}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<UserProfileForm
					onSubmit={onSubmit}
					onCompleted={handleClose}
					mustChangePassword={false}
					profileAction={UserAction.CREATE}
				/>
			</DialogContent>
			<DialogActions></DialogActions>
		</BootstrapDialog>
	);
}
