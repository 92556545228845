import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { debounceForBroadcast } from '../utils/debounce-for-broadcast';
import { useLysReady } from './LysReadyContext';

// Define types for parameters and materials
export interface Parameter {
	name: string;
}

export interface ColorParameter extends Parameter {
	type: 'color';
	value: string;
}

export interface TextureParameter extends Parameter {
	type: 'texture';
	value: string;
}

export interface NumericParameter extends Parameter {
	type: 'double';
	value: number;
}

export interface TextParameter extends Parameter {
	type: 'text';
	value: string;
}

export type ParameterValue = string | number | null;

export type AnyParameter = ColorParameter | TextureParameter | NumericParameter | TextParameter;

export interface MaterialWithProperties {
	id: string;
	type: string;
	name: string;
	thumbnail: string;
	parameters: AnyParameter[];
}

export interface SimpleMaterial {
	id: string;
	type: string;
	name: string;
	baseColor: { x: number; y: number; z: number };
}

interface MaterialsContextType {
	materials: SimpleMaterial[];
	selectedMaterialIds: string[];
	selectedMaterialProperties: MaterialWithProperties[];
	setSelectedMaterialIds: React.Dispatch<React.SetStateAction<string[]>>;
	setMaterialType: (newType: string) => void;
	setMaterialParameter: (parameterName: string, newValue: ParameterValue) => void;
}

// Create context with undefined default value
const MaterialsContext = createContext<MaterialsContextType>(undefined);

interface MaterialProviderProps {
	children: ReactNode;
}

export const MaterialProvider = ({ children }: MaterialProviderProps) => {
	const [materials, setMaterials] = useState<SimpleMaterial[]>([]);
	const [selectedMaterialIds, setSelectedMaterialIds] = useState<string[]>([]);
	const [selectedMaterialProperties, setSelectedMaterialProperties] = useState<MaterialWithProperties[]>([]);
	const [forceUpdate, setForceUpdate] = useState(0);
	const { lysIsReady } = useLysReady();
	const [affectedParameterName, setAffectedParameterName] = useState<string>();

	// useEffect that runs whenever forceUpdate changes
	useEffect(() => {
		if (!globalThis.lys) return;

		const materialIds = globalThis.lys.getAllMaterialIds() as string[];

		const fetchedMaterials = materialIds.flatMap((id: string) => {
			const material = globalThis.lys.getMaterialById(id);
			if (material)
				return [
					{
						id: id,
						type: material.getType(),
						name: material.getName(),
						baseColor: material.baseColor,
					},
				];
			return [];
		});

		setMaterials(fetchedMaterials);

		if (selectedMaterialIds.length === 0) return;

		const selectedProperties = selectedMaterialIds
			.map((materialId) => {
				const material = globalThis.lys.getMaterialById(materialId);
				return material ? JSON.parse(material.toJSONString()) : null;
			})
			.filter(Boolean);

		// selectedProperties.forEach(amendWithCustomFields);
		setSelectedMaterialProperties(selectedProperties);
	}, [forceUpdate, selectedMaterialIds, lysIsReady]);

	// Update material type for the first selected material
	const setMaterialType = (newType: string) => {
		setSelectedMaterialProperties((prevProps) => {
			if (prevProps.length === 0) return prevProps;

			const updatedMaterial = { ...prevProps[0], type: newType };
			globalThis.lys.changeMaterialType(updatedMaterial.id, newType, true);

			return [updatedMaterial, ...prevProps.slice(1)];
		});
	};

	const broadcaster = useCallback(
		debounceForBroadcast(
			globalThis?.lys?.setMaterialParameters ?? (() => console.warn('Lys undefined when setting material props')),
			250,
		),
		[lysIsReady, affectedParameterName, selectedMaterialIds],
	);

	// Update a specific material parameter for the first selected material
	const setMaterialParameter = (parameterName: string, newValue: ParameterValue) => {
		if (affectedParameterName !== parameterName) setAffectedParameterName(parameterName);

		setSelectedMaterialProperties((previousSelectedMaterials: MaterialWithProperties[]) => {
			if (previousSelectedMaterials.length === 0) return previousSelectedMaterials;
			const material = previousSelectedMaterials[0];
			let params: AnyParameter[] = material.parameters;

			if (newValue == null) {
				params = params.filter((param: AnyParameter) => param.name !== parameterName);
			} else {
				let found = false;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				params = params.map((param: any) => {
					if (param.name === parameterName) {
						found = true;
						return { ...param, value: newValue };
					}
					return param;
				});
				if (!found) {
					if (typeof newValue == 'string') {
						params = params.concat([{ name: parameterName, type: 'text', value: newValue }]);
					} else {
						throw new Error(
							`Tried giving a ${typeof newValue} value to a text type material parameter named ${parameterName}`,
						);
					}
				}
			}

			const updatedMaterial = {
				...previousSelectedMaterials[0],
				parameters: params,
			};

			const materialJson = JSON.stringify(updatedMaterial);

			broadcaster(updatedMaterial.id, materialJson);

			return [updatedMaterial, ...previousSelectedMaterials.slice(1)];
		});
	};

	useEffect(() => {
		globalThis.updateMaterialList = () => setForceUpdate((n) => n + 1);

		return () => {
			delete globalThis.updateMaterialList;
		};
	}, []);

	return (
		<MaterialsContext.Provider
			value={{
				materials,
				selectedMaterialIds,
				selectedMaterialProperties,
				setSelectedMaterialIds,
				setMaterialType,
				setMaterialParameter,
			}}
		>
			{children}
		</MaterialsContext.Provider>
	);
};

// Custom hook to use the MaterialsContext
export const useMaterials = (): MaterialsContextType => {
	const context = useContext(MaterialsContext);
	if (!context) {
		throw new Error('useMaterials must be used within a MaterialProvider');
	}
	return context;
};
