import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FButton from '../FButton';
import FTextField from '../FTextField';
import { useState } from 'react';

export type AskRenameDialogProps = {
	noun: string;
	name?: string;
	open: boolean;
	onConfirm: (newName: string) => void;
	onClose: () => void;
};

export function AskRenameDialog({ noun, name, open, onConfirm, onClose }: AskRenameDialogProps) {
	const [text, setText] = useState<string>(name);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Rename {noun}</DialogTitle>
			<DialogContent>
				<FTextField
					value={text}
					onChange={(e) => {
						setText(e.currentTarget.value);
					}}
					error={text.length === 0}
					helperText={text.length === 0 ? 'Name cannot be empty.' : ''}
				/>
				<DialogActions sx={{ pr: 0 }}>
					<FButton color="secondary" onClick={onClose}>
						Cancel
					</FButton>
					<FButton color="primary" onClick={() => onConfirm(text)} disabled={text.length === 0}>
						Rename
					</FButton>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
