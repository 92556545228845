import { useState } from 'react';
import * as FileApi from '../../services/file-api';
import { Menu, MenuItem, ListItemIcon } from '@mui/material';
import { fileActions } from './FileActions';

type FileGridMoreMenuProps = {
	selectedFile: FileApi.FSEntry;
	anchorEl: HTMLElement;
	onChange: () => void;
	onClose: () => void;
};

export function FileGridMoreMenu({ selectedFile, anchorEl, onChange, onClose }: FileGridMoreMenuProps) {
	if (!selectedFile) return null;

	const [showActionKey, setShowActionKey] = useState<string>(null);

	function change() {
		setShowActionKey(null);
		onChange();
	}

	let MyDialog: () => React.JSX.Element;

	Object.entries(fileActions[selectedFile.type]).map(([key, action]) => {
		if (showActionKey === key) {
			MyDialog = action.handler(selectedFile, change);
		}
	});
	return (
		<div>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				open={!MyDialog}
				anchorEl={anchorEl}
				onClose={onClose}
			>
				{Object.entries(fileActions[selectedFile.type]).map(([key, action]) => {
					return (
						<MenuItem
							key={key}
							onClick={() => {
								setShowActionKey(key);
							}}
							disabled={!!action.disabled && action.disabled(selectedFile)}
						>
							<ListItemIcon>
								<action.icon fontSize="small" />
							</ListItemIcon>
							{action.label}
						</MenuItem>
					);
				})}
			</Menu>
			{!!MyDialog && <MyDialog />}
		</div>
	);
}
