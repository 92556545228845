// SceneTreeViewer.tsx

import React, { useState, useEffect, useMemo, useRef, SyntheticEvent } from 'react';
import { Box, Typography, IconButton, Tooltip, InputBase } from '@mui/material';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import { alpha, useTheme } from '@mui/material/styles';
import BoxPlusIcon from '@mui/icons-material/AddBoxOutlined';
import BoxMinusIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import BoxIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LockIcon from '@mui/icons-material/Lock';
import CubeIcon from '@mui-extra/icons/CubeIcon';
import { actions, ActionKey, ActionContext } from '../actions';
import ActionMenu from '../ActionMenu';
import { VisibilityOff } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import useVirtual from 'react-cool-virtual';
import { PointInNode } from '../SelectedNodesContext';

const nodeTypeToIcon = (typeName: string) => {
	switch (typeName) {
		case 'node':
			return BoxIcon;
		case 'camera':
			return CameraIcon;
		case 'trimesh':
			return CubeIcon;
		default:
			return undefined;
	}
};

interface Props {
	search: string;
	selectedNodeIds: number[];
	selectedPointInNodes?: PointInNode[];
	setSelectedNodeIds: (ids: number[]) => void;
	setCalloutPins: (ids: Set<PointInNode>) => void;
	rootNodeId: number;
	forceUpdate: number;
	extraButton?: JSX.Element;
	actionsList: ActionKey[];
	activeCamera?: number | null;
	setActiveCamera?: (cameraId: number | null) => void;
	sx?: SxProps<Theme>; //  for MUI styling
}

interface VisibleNode {
	id: number;
	depth: number;
	name: string;
	type: string;
	materialName?: string;
	isLocked?: boolean;
	isHidden?: boolean;
	hasChildren: boolean;
}

const SceneTreeViewer = ({
	search,
	selectedNodeIds,
	selectedPointInNodes,
	setSelectedNodeIds,
	setCalloutPins,
	rootNodeId,
	forceUpdate,
	extraButton,
	actionsList,
	activeCamera,
	setActiveCamera,
}: Props) => {
	const [draggedItems, setDraggedItems] = useState<number[] | null>(null);
	const [dragTarget, setDragTarget] = useState<number | null>(null);
	const [editingNodeId, setEditingNodeId] = useState<number | null>(null);
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [isDraggingOverRoot, setIsDraggingOverRoot] = useState(false);
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
		nodeId: number | null;
	} | null>(null);
	const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);

	const theme = useTheme();

	const getVisibleNodes = (rootNodeId: number, expandedNodes: Set<number>, search: string): VisibleNode[] => {
		const visibleNodes: VisibleNode[] = [];
		const lowerSearch = search.toLowerCase();

		const traverse = (nodeId: number, depth: number): boolean => {
			const node = globalThis.lys.getNodeById(nodeId);
			if (!node) return false;

			const name = node.getName();
			const type = node.getType();
			const mat = node.getMaterial();
			const materialName = mat ? mat.getName() : undefined;
			const isLocked = node.isLocked();
			const isHidden = node.isHidden();
			const childrenHandle = node.getChildren();
			const hasChildren = childrenHandle.size() > 0;

			const nodeNameLower = name.toLowerCase();
			const nodeTypeLower = type.toLowerCase();
			const nodeMaterialLower = materialName?.toLowerCase() ?? '';

			const nodeMatches =
				nodeNameLower.includes(lowerSearch) ||
				nodeTypeLower.includes(lowerSearch) ||
				nodeMaterialLower.includes(lowerSearch);

			let hasMatchingDescendant = false;

			const shouldExpand = expandedNodes.has(nodeId);

			if (shouldExpand || lowerSearch !== '' || depth < 0) {
				const childrenCount = childrenHandle.size();
				for (let i = 0; i < childrenCount; i++) {
					const childNodeId = childrenHandle.get(i).getId();
					const childMatches = traverse(childNodeId, depth + 1);
					hasMatchingDescendant = hasMatchingDescendant || childMatches;
				}
			}

			// Only add the node if it's not the root node
			if (depth >= 0 && (nodeMatches || hasMatchingDescendant)) {
				visibleNodes.push({
					id: nodeId,
					depth,
					name,
					type,
					materialName,
					isLocked,
					isHidden,
					hasChildren,
				});
				return true;
			} else {
				return hasMatchingDescendant;
			}
		};
		traverse(rootNodeId, -1);
		visibleNodes.reverse();
		return visibleNodes;
	};

	const selectedExpanded = useMemo(() => {
		if (!globalThis.lys) return [];
		const selectedExpandedNodes = new Set<number>();
		selectedNodeIds.forEach((nodeId) => {
			let parentId = globalThis.lys.getNodeById(nodeId)?.getParent()?.getId();
			while (parentId != null) {
				if (selectedExpandedNodes.has(parentId)) break;
				selectedExpandedNodes.add(parentId);
				parentId = globalThis.lys.getNodeById(parentId)?.getParent()?.getId();
			}
		});
		return Array.from(selectedExpandedNodes);
	}, [selectedNodeIds]);

	useEffect(() => {
		const selectedExpandedStrings = selectedExpanded.map(String);
		setExpandedNodes((prevExpanded) => {
			const newExpanded = new Set(prevExpanded);
			selectedExpandedStrings.forEach((node) => newExpanded.add(node));
			return Array.from(newExpanded);
		});
	}, [selectedExpanded]);

	const visibleNodes = useMemo(() => {
		if (!globalThis.lys) return [];
		const activeCam = globalThis.lys.getActiveCameraId();
		setActiveCamera?.(activeCam);

		const expandedNodesSet = new Set(expandedNodes.map(Number));
		const ret = getVisibleNodes(rootNodeId, expandedNodesSet, search);
		return ret;
	}, [rootNodeId, expandedNodes, selectedExpanded, search, forceUpdate]);

	const handleSelect = (event: React.MouseEvent, nodeId: number, index: number) => {
		const node = globalThis.lys.getNodeById(nodeId);

		if (event.ctrlKey || event.metaKey) {
			if (selectedNodeIds.includes(nodeId)) {
				setSelectedNodeIds(selectedNodeIds.filter((id) => id !== nodeId));
			} else {
				setSelectedNodeIds([...selectedNodeIds, nodeId]);
			}
			setLastSelectedIndex(index);
		} else if (event.shiftKey && lastSelectedIndex !== null) {
			const start = Math.min(lastSelectedIndex, index);
			const end = Math.max(lastSelectedIndex, index);
			const rangeIds = visibleNodes.slice(start, end + 1).map((node) => node.id);
			setSelectedNodeIds(rangeIds);
		} else {
			setSelectedNodeIds([nodeId]);
			setLastSelectedIndex(index);
		}

		if (setActiveCamera && node && node.getType() === 'camera') {
			globalThis.lys.setActiveCamera(nodeId, true);
			setActiveCamera(nodeId);
		}
	};

	const handleToggleExpand = (event: SyntheticEvent, nodeId: number) => {
		event.stopPropagation();

		const nodeIdStr = nodeId.toString();
		if (expandedNodes.includes(nodeIdStr)) {
			setExpandedNodes(expandedNodes.filter((id) => id !== nodeIdStr));
		} else {
			setExpandedNodes([...expandedNodes, nodeIdStr]);
		}
	};

	const handleBlankAreaClick = (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			setSelectedNodeIds([]);
		}
	};

	const handleDragStart = (nodeId: number, event: React.DragEvent) => {
		event.stopPropagation();
		event.dataTransfer.effectAllowed = 'move';
		setDraggedItems([...new Set([...selectedNodeIds, nodeId])]);
		setSelectedNodeIds([...new Set([...selectedNodeIds, nodeId])]);
	};

	const handleDrop = (targetNodeId: number, event: React.DragEvent) => {
		event.preventDefault();
		event.stopPropagation();

		if (!draggedItems) return;

		const filteredDraggedItems = draggedItems.filter((id) => id !== targetNodeId);
		const targetNode = globalThis.lys.getNodeById(targetNodeId);

		if (targetNode.getType() === 'node') {
			filteredDraggedItems.forEach((id) => {
				globalThis.lys.reparentNode(targetNodeId, id, true);
				setExpandedNodes((prev) => [...prev, targetNodeId.toString()]);
			});
		} else {
			const newFolderId = globalThis.lys.createNodeGenId('New Group');
			const parent = targetNode.getParent().getId();

			globalThis.lys.reparentNode(parent, newFolderId, true);
			globalThis.lys.reparentNode(newFolderId, targetNodeId, true);
			setExpandedNodes((prev) => [...prev, newFolderId.toString()]);

			filteredDraggedItems.forEach((id) => {
				globalThis.lys.reparentNode(newFolderId, id, true);
			});
		}

		setDraggedItems(null);
		setDragTarget(null);
	};

	const handleDragOverNode = (nodeId: number, event: React.DragEvent) => {
		event.preventDefault();
		event.stopPropagation();
		event.dataTransfer.dropEffect = 'move';
		setDragTarget(nodeId);
	};

	const handleDragLeaveNode = () => {
		setDragTarget(null);
	};

	const handleEditNode = (nodeId: number) => {
		setEditingNodeId(nodeId);
	};

	const handleNodeNameSubmit = (nodeId: number, name: string) => {
		globalThis.lys.setNodeName(nodeId, name, true);
		setEditingNodeId(null);
	};

	const handleKeyPress = (e: React.KeyboardEvent, nodeId: number, name: string) => {
		if (e.key === 'Enter') {
			setEditingNodeId(null);
			handleNodeNameSubmit(nodeId, name);
			e.preventDefault();
		} else if (e.key === 'Escape') {
			setEditingNodeId(null);
		}
	};

	const handleDragOverRoot = (event: React.DragEvent) => {
		event.preventDefault();
		setIsDraggingOverRoot(true);
	};

	const handleDragLeaveRoot = () => {
		setIsDraggingOverRoot(false);
	};

	const handleDropToRoot = (event: React.DragEvent) => {
		event.stopPropagation();
		event.preventDefault();
		setIsDraggingOverRoot(false);
		if (!draggedItems) return;

		draggedItems.forEach((id) => {
			globalThis.lys.reparentNode(rootNodeId, id, true);
		});

		setDraggedItems(null);
		setDragTarget(null);
	};

	const rowHeight = 36;

	const { outerRef, innerRef, items, scrollToItem } = useVirtual({
		itemCount: visibleNodes.length,
		itemSize: rowHeight,
	});

	const lastSelecteNodeIds = useRef(selectedNodeIds);
	// Scrolling to newly selectedNodeIds
	useEffect(() => {
		// only scroll if selectedNodeIds has changed
		if (lastSelecteNodeIds.current !== selectedNodeIds) {
			if (selectedNodeIds.length > 0 && visibleNodes.length > 0) {
				const lastSelectedId = selectedNodeIds[selectedNodeIds.length - 1];

				const indexToScroll = visibleNodes.findIndex((node) => node.id === lastSelectedId);

				if (indexToScroll !== -1) {
					scrollToItem(indexToScroll);
					lastSelecteNodeIds.current = selectedNodeIds;
				}
			}
		}
	}, [selectedNodeIds, scrollToItem]);

	const actionContext: ActionContext = {
		selectedNodeIds,
		selectedPointInNodes,
		setCalloutPins,
		setSelectedNodeIds,
		rootNodeId,
	};

	const menuActions: (ActionKey | 'divider')[] = [
		'clone',
		'delete',
		'groupParts',
		'divider',
		'lockNode',
		'unlockNode',
		'divider',
		'hideNode',
		'showHiddenNode',
		'showCallouts',
		'hideCallouts',
	];

	return (
		<>
			<Box
				sx={{
					flexGrow: 1,
					overflow: 'auto',
					outline: isDraggingOverRoot ? `2px solid ${theme.palette.primary.main}` : 'none',
					outlineOffset: isDraggingOverRoot ? '-2px' : 'none',
				}}
				onDragOver={handleDragOverRoot}
				onDragLeave={handleDragLeaveRoot}
				onDrop={handleDropToRoot}
			>
				<div
					onClick={handleBlankAreaClick}
					ref={outerRef as React.RefObject<HTMLDivElement>}
					style={{ height: '100%', overflow: 'auto', padding: '8px' }}
				>
					<div ref={innerRef as React.RefObject<HTMLDivElement>}>
						{items.map(({ index, measureRef }) => {
							if (index < 0 || index >= visibleNodes.length) {
								return null; // Skip rendering if index is out of bounds
							}
							const node = visibleNodes[index];
							const {
								id: nodeId,
								depth,
								name,
								type,
								materialName,
								isLocked,
								isHidden,
								hasChildren,
							} = node;

							const IconComponent = nodeTypeToIcon(type);
							const isSelected = selectedNodeIds.includes(nodeId);
							const isDraggingOver = dragTarget === nodeId;
							const isActiveCameraNode = type === 'camera' && activeCamera === nodeId;

							const handleContextMenu = (event: React.MouseEvent) => {
								event.preventDefault();
								setSelectedNodeIds([...new Set([...selectedNodeIds, nodeId])]);
								setContextMenu(
									contextMenu === null
										? {
												mouseX: event.clientX - 2,
												mouseY: event.clientY - 4,
												nodeId: nodeId,
											}
										: null,
								);
							};

							return (
								<Box
									key={nodeId}
									ref={measureRef}
									style={{
										paddingLeft: `${depth * 20}px`,
										display: 'flex',
										alignItems: 'center',
										height: `${rowHeight}px`,
									}}
									onClick={(event) => handleSelect(event, nodeId, index)}
									onDragStart={(e) => handleDragStart(nodeId, e)}
									onDrop={(e) => handleDrop(nodeId, e)}
									onDragOver={(e) => handleDragOverNode(nodeId, e)}
									onDragLeave={handleDragLeaveNode}
									draggable={editingNodeId !== nodeId}
									onContextMenu={handleContextMenu}
									sx={{
										backgroundColor: isSelected
											? alpha(theme.palette.primary.main, 0.08) // Selected background
											: 'inherit', // Default background
										'&:hover': {
											backgroundColor: alpha(theme.palette.primary.main, 0.12), // Hover background
										},
										outlineColor: isDraggingOver ? theme.palette.primary.main : 'none',
										outlineWidth: isDraggingOver ? '2px' : 'none',
										outlineStyle: isDraggingOver ? 'solid' : 'none',
										outlineOffset: isDraggingOver ? '-2px' : 'none',
									}}
								>
									{hasChildren ? (
										expandedNodes.includes(nodeId.toString()) ? (
											<IconButton
												style={{ margin: '0 4px 0 0', padding: '0 0 0 0' }}
												size="small"
												onClick={(e) => handleToggleExpand(e, nodeId)}
											>
												<BoxMinusIcon />
											</IconButton>
										) : (
											<IconButton
												style={{ margin: '0 4px 0 0', padding: '0 0 0 0' }}
												size="small"
												onClick={(e) => handleToggleExpand(e, nodeId)}
											>
												<BoxPlusIcon />
											</IconButton>
										)
									) : (
										<IconComponent
											style={{ margin: '0 4px 0 0', padding: '0 0 0 0' }}
											sx={{
												color: isActiveCameraNode ? theme.palette.primary.main : 'inherit',
											}}
										/>
									)}

									{/* <IconButton size="small" onClick={() => handleToggleExpand(nodeId)}>
									? <BoxMinusIcon /> : <BoxPlusIcon />}
									</IconButton>
									{IconComponent && (
										<IconComponent
											sx={{
												color: isActiveCameraNode ? theme.palette.primary.main : 'inherit',
											}}
										/>
									)} */}
									{editingNodeId === nodeId ? (
										<InputBase
											onClick={(e) => e.stopPropagation()}
											defaultValue={name}
											onBlur={(e) => handleNodeNameSubmit(nodeId, e.target.value)}
											onKeyDown={(e) => {
												e.stopPropagation();
												handleKeyPress(e, nodeId, (e.target as HTMLInputElement).value);
											}}
											autoFocus
											fullWidth
											size="small"
										/>
									) : (
										<>
											<Typography
												onDoubleClick={() => handleEditNode(nodeId)}
												flex="1 1 50%"
												whiteSpace="nowrap"
												overflow="hidden"
												textOverflow="ellipsis"
											>
												{name}
											</Typography>
											{materialName && (
												<Typography
													flex="0 1 auto"
													color="inherit"
													whiteSpace="nowrap"
													overflow="hidden"
													textOverflow="ellipsis"
													sx={{ ml: 1 }}
												>
													{materialName}
												</Typography>
											)}
											{isLocked && (
												<LockIcon
													fontSize="small"
													sx={{
														ml: 1,
														color: theme.palette.grey[500],
													}}
												/>
											)}
											{isHidden && (
												<VisibilityOff
													fontSize="small"
													sx={{
														ml: 1,
														color: theme.palette.grey[500],
													}}
												/>
											)}
										</>
									)}
								</Box>
							);
						})}
					</div>
				</div>
			</Box>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginRight: '1em',
				}}
			>
				{extraButton && <Box sx={{ marginLeft: '1em' }}>{extraButton}</Box>}
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
					{actionsList.map((actionKey) => {
						const action = actions[actionKey];
						const isDisabled = action.disabled ? action.disabled(actionContext) : false;
						const labelText =
							typeof action.label === 'function' ? action.label(actionContext) : action.label;
						return (
							<Tooltip title={labelText} arrow key={actionKey}>
								<span>
									<IconButton
										aria-label={labelText}
										onClick={() => action.handler(actionContext)}
										disabled={isDisabled}
									>
										{action.icon ? <action.icon fontSize="small" /> : null}
									</IconButton>
								</span>
							</Tooltip>
						);
					})}
				</Box>
			</Box>

			<ActionMenu
				open={contextMenu !== null}
				handleClose={() => setContextMenu(null)}
				location={contextMenu !== null ? [contextMenu.mouseX, contextMenu.mouseY] : null}
				menuActions={menuActions}
				actionContext={actionContext}
			/>
		</>
	);
};

export default SceneTreeViewer;
