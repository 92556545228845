import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import * as FileApi from '../../services/file-api';
import { fileActions } from './FileActions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridCloseIcon, GridDeleteIcon } from '@mui/x-data-grid/material/icons';
import { Download, WidgetsOutlined } from '@mui/icons-material';

interface ShowRenderDialogProps {
	renderFile: FileApi.FSEntry;
	open: boolean;
	onClose: () => void;
}

export function ShowRenderDialog({ renderFile, open, onClose }: ShowRenderDialogProps) {
	const navigate = useNavigate();

	const [showDeleteDialog, setDeleteDialog] = useState<() => React.JSX.Element>(null);

	function handleGotoScene(file: FileApi.FSEntry): void {
		onClose();
		if (file.sceneId) {
			navigate(`/scenes/${file.sceneId}/edit`);
		}
	}

	return (
		<Box>
			<Dialog fullScreen open={open} onClose={onClose}>
				<DialogTitle>
					<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" title="Close">
						<GridCloseIcon />
					</IconButton>
					<IconButton
						onClick={() => downloadRender(renderFile)}
						title="Download"
						aria-label="download render"
					>
						<Download />
					</IconButton>
					<IconButton
						onClick={() => handleGotoScene(renderFile)}
						size="small"
						title="Go to scene"
						aria-label="Go to scene"
					>
						<WidgetsOutlined />
					</IconButton>
					<IconButton
						onClick={() => setDeleteDialog(() => fileActions.render['delete'].handler(renderFile, onClose))}
						size="small"
						title="Delete render"
						aria-label="Delete render"
					>
						<GridDeleteIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<img src={renderFile?.id} />
				</DialogContent>
			</Dialog>
			{showDeleteDialog && showDeleteDialog()}
		</Box>
	);
}

function downloadRender(file: FileApi.FSEntry) {
	const url = new URL(file.id, window.location.href);
	fetch(url)
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.blob();
		})
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'render.png';
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		});
}
