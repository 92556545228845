import React from 'react';
import { useSelectedNodes } from '../SelectedNodesContext';
import SceneTreeViewer from './SceneTreeViewer';
import useGlobalUpdate from '../../utils/use-global-update';
import { useCallouts } from '../../contexts/CalloutContext';

export type SceneTreeProps = {
	search: string; // Prop to filter nodes
};

export default function SceneTree({ search }: SceneTreeProps) {
	const { selectedNodeIds, setSelectedNodeIds, selectedPointInNodes } = useSelectedNodes();

	const { setCalloutBoxes: setCalloutPins } = useCallouts();

	const sceneTreeUpdated = useGlobalUpdate('updateSceneTree');

	return (
		<SceneTreeViewer
			actionsList={['createGroup', 'clone', 'delete', 'showCallouts', 'hideCallouts']}
			search={search}
			selectedNodeIds={selectedNodeIds}
			selectedPointInNodes={selectedPointInNodes}
			setSelectedNodeIds={setSelectedNodeIds}
			setCalloutPins={setCalloutPins}
			rootNodeId={-3}
			forceUpdate={sceneTreeUpdated}
		/>
	);
}
