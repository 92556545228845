import React, { useContext } from 'react';
import { Tooltip, IconButton, IconButtonProps } from '@mui/material';
import { actions, ActionKey, ActionContext as ImportedActionContext } from './actions';

const LocalActionContext = React.createContext<ImportedActionContext | null>(null);

interface ActionButtonProps extends Omit<IconButtonProps, 'onClick'> {
	locked?: boolean;
	actionKey: ActionKey;
}

export const ActionButton = ({ actionKey, locked = false, ...props }: ActionButtonProps) => {
	const actionContext = useContext(LocalActionContext);

	const action = actions[actionKey];
	const label = typeof action.label === 'function' ? action.label(actionContext) : action.label;

	const isDisabled = locked || (action.disabled ? action.disabled(actionContext) : false);

	const handleClick = () => {
		action.handler(actionContext);
	};

	return (
		<Tooltip title={label || ''} arrow>
			<span>
				<IconButton aria-label={label || 'Action'} onClick={handleClick} disabled={isDisabled} {...props}>
					{action.icon && React.createElement(action.icon, { fontSize: 'small' })}
				</IconButton>
			</span>
		</Tooltip>
	);
};

const generatedButtons = Object.keys(actions).reduce(
	(components, key) => {
		const actionKey = key as ActionKey;
		components[key] = (props: Omit<ActionButtonProps, 'actionKey'>) => (
			<ActionButton actionKey={actionKey} {...props} />
		);
		return components;
	},
	{} as Record<ActionKey, React.FC<Omit<ActionButtonProps, 'actionKey'>>>,
);

export const {
	addCamera: AddCameraIconButton,
	setFocalDistance: SetFocalDistanceIconButton,
	lockNode: LockNodeIconButton,
	unlockNode: UnlockNodeIconButton,
	hideNode: HideNodeIconButton,
	showHiddenNode: ShowHiddenNodeIconButton,
	saveMaterialToLibrary: SaveMaterialToLibraryIconButton,
	clone: CloneIconButton,
	delete: DeleteIconButton,
	groupParts: GroupPartsIconButton,
	createGroup: CreateGroupIconButton,
	unlinkMaterial: UnlinkMaterialIconButton,
	linkMaterial: LinkMaterialIconButton,
	centerAndFit: CenterAndFitIconButton,
	showAllParts: ShowAllPartsIconButton,
} = generatedButtons;
