import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NumberInput from '../NumberInput';
import { SetFocalDistanceIconButton } from '../ActionButtons';
import useGlobalUpdate from '../../utils/use-global-update';

interface Props {
	nodeId: number | undefined;
}

export default function CameraProperties({ nodeId }: Props) {
	const [translationX, setTranslationX] = useState<number | undefined>(undefined);
	const [translationY, setTranslationY] = useState<number | undefined>(undefined);
	const [translationZ, setTranslationZ] = useState<number | undefined>(undefined);

	const [rotationX, setRotationX] = useState<number | undefined>(undefined);
	const [rotationY, setRotationY] = useState<number | undefined>(undefined);
	const [rotationZ, setRotationZ] = useState<number | undefined>(undefined);

	const [targetX, setTargetX] = useState<number | undefined>(undefined);
	const [targetY, setTargetY] = useState<number | undefined>(undefined);
	const [targetZ, setTargetZ] = useState<number | undefined>(undefined);

	const [fovY, setFovY] = useState<number | undefined>(undefined);
	const [aperture, setAperture] = useState<number | undefined>(undefined);
	const [focalDistance, setFocalDistance] = useState<number | undefined>(undefined);

	const [locked, setLocked] = useState<boolean>(false);

	// const [forceUpdate, setForceUpdate] = useState(0);

	const getData = () => {
		if (!nodeId) return;

		console.log('CameraProperties: ', nodeId);

		const currentValues = globalThis.lys.getTransformRotationScale(nodeId, true);

		setTranslationX(currentValues[0]);
		setTranslationY(currentValues[1]);
		setTranslationZ(currentValues[2]);

		setRotationX(currentValues[3]);
		setRotationY(currentValues[4]);
		setRotationZ(currentValues[5]);

		const camNode = globalThis.lys.getNodeById(nodeId);
		if (!camNode) return;

		setLocked(camNode.isLocked());

		const cam = camNode.getCamera();
		const fovYValue = cam.getFovY();
		const apertureValue = cam.getAperture();
		const focalDistanceValue = cam.getFocalDistance();

		setFovY(fovYValue * (180 / Math.PI));
		setAperture(apertureValue);
		setFocalDistance(focalDistanceValue);
	};

	const activeCameraUpdated = useGlobalUpdate('updateActiveCamera');

	useEffect(() => {
		getData();
	}, [activeCameraUpdated, nodeId]);

	// Handler functions for user input
	const handleTranslationX = (value: number | undefined) => {
		setTranslationX(value);

		if (
			nodeId &&
			value !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				value,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleTranslationY = (value: number | undefined) => {
		setTranslationY(value);

		if (
			nodeId &&
			translationX !== undefined &&
			value !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				translationX,
				value,
				translationZ,
				rotationX,
				rotationY,
				rotationZ,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleTranslationZ = (value: number | undefined) => {
		setTranslationZ(value);

		if (
			nodeId &&
			translationX !== undefined &&
			translationY !== undefined &&
			value !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				translationX,
				translationY,
				value,
				rotationX,
				rotationY,
				rotationZ,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleRotationX = (value: number | undefined) => {
		setRotationX(value);

		if (
			nodeId &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			value !== undefined &&
			rotationY !== undefined &&
			rotationZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				translationX,
				translationY,
				translationZ,
				value,
				rotationY,
				rotationZ,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleRotationY = (value: number | undefined) => {
		setRotationY(value);

		if (
			nodeId &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			value !== undefined &&
			rotationZ !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				translationX,
				translationY,
				translationZ,
				rotationX,
				value,
				rotationZ,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleRotationZ = (value: number | undefined) => {
		setRotationZ(value);

		if (
			nodeId &&
			translationX !== undefined &&
			translationY !== undefined &&
			translationZ !== undefined &&
			rotationX !== undefined &&
			rotationY !== undefined &&
			value !== undefined
		) {
			globalThis.lys.setTranslateRotationScale(
				nodeId,
				translationX,
				translationY,
				translationZ,
				rotationX,
				rotationY,
				value,
				1,
				1,
				1,
				false,
				true,
			);
		}
	};

	const handleFovY = (value: number | undefined) => {
		setFovY(value);
		console.log('fovY: ', value);
		if (nodeId && value !== undefined) {
			globalThis.lys.setFovY(nodeId, value * (Math.PI / 180), true);
		}
	};

	const handleAperture = (value: number | undefined) => {
		setAperture(value);

		if (nodeId && value !== undefined) {
			globalThis.lys.setAperture(nodeId, value, true);
		}
	};

	const handleFocalDistance = (value: number | undefined) => {
		setFocalDistance(value);
		if (nodeId && value !== undefined) {
			globalThis.lys.setFocalDistance(nodeId, value, true);
		}
	};

	// Handlers for target values (implement if needed)
	const handleTargetX = (value: number | undefined) => {
		setTargetX(value);
		// Implement backend call if needed
	};

	const handleTargetY = (value: number | undefined) => {
		setTargetY(value);
		// Implement backend call if needed
	};

	const handleTargetZ = (value: number | undefined) => {
		setTargetZ(value);
		// Implement backend call if needed
	};

	return (
		<>
			<Grid
				container
				spacing={0}
				padding={1}
				columns={12}
				alignItems="center"
				justifyContent="center"
				rowSpacing={1}
				columnSpacing={1}
			>
				<Grid item xs={3}>
					<Typography align="left">Position:</Typography>
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={translationX} setValue={handleTranslationX} />
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={translationY} setValue={handleTranslationY} />
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={translationZ} setValue={handleTranslationZ} />
				</Grid>

				<Grid item xs={3}>
					<Typography align="left">Rotation:</Typography>
				</Grid>
				<Grid item xs={3}>
					<NumberInput
						disabled={!nodeId || locked}
						adornment="°"
						step={15}
						value={rotationX}
						setValue={handleRotationX}
					/>
				</Grid>
				<Grid item xs={3}>
					<NumberInput
						disabled={!nodeId || locked}
						adornment="°"
						step={15}
						value={rotationY}
						setValue={handleRotationY}
					/>
				</Grid>
				<Grid item xs={3}>
					<NumberInput
						disabled={!nodeId || locked}
						adornment="°"
						step={15}
						value={rotationZ}
						setValue={handleRotationZ}
					/>
				</Grid>

				<Grid item xs={3}>
					<Typography align="left">Target:</Typography>
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={targetX} setValue={handleTargetX} />
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={targetY} setValue={handleTargetY} />
				</Grid>
				<Grid item xs={3}>
					<NumberInput disabled={!nodeId || locked} value={targetZ} setValue={handleTargetZ} />
				</Grid>

				<Grid item xs={9}>
					<Typography align="left">Field of View</Typography>
				</Grid>
				<Grid item xs={3}>
					<NumberInput
						min={0.0}
						max={179.99}
						disabled={!nodeId || locked}
						adornment="°"
						step={10}
						value={fovY}
						setValue={handleFovY}
					/>
				</Grid>

				<Grid item xs={9}>
					<Typography align="left">Aperture</Typography>
				</Grid>
				<Grid item xs={3}>
					<NumberInput min={0.0} disabled={!nodeId || locked} value={aperture} setValue={handleAperture} />
				</Grid>
				<Grid item xs={7.5}>
					<Typography align="left">Focal Distance</Typography>
				</Grid>
				<Grid item xs={1.5}>
					<SetFocalDistanceIconButton disabled={locked} />
				</Grid>
				<Grid item xs={3}>
					<NumberInput
						min={0.0}
						disabled={!nodeId || locked}
						value={focalDistance}
						setValue={handleFocalDistance}
					/>
				</Grid>
			</Grid>
		</>
	);
}
