import { RichTreeView } from '@mui/x-tree-view';
import { useEffect, useRef, useState } from 'react';
import * as FileApi from '../../services/file-api';

type FolderTreeProps = {
	folderTree: FileApi.FSEntry[];
	selectedNodeId: string;
	renderRootId?: string; // Make it optional
	onSelectNode: (id: string) => Promise<void>;
};

export default function FolderTree({ folderTree, selectedNodeId, renderRootId, onSelectNode }: FolderTreeProps) {
	const [currentFolderTree, setCurrentFolderTree] = useState<FileApi.FSEntry[]>([]);
	const [expandedTreeFolders, setExpandedTreeFolders] = useState<string[]>([]);
	const initialLoad = useRef(true); // Track if it's the initial load

	const findFolderById = (nodes: FileApi.FSEntry[], id: string): FileApi.FSEntry | null => {
		for (const node of nodes) {
			if (node.id === id) return node;
			if (node.children) {
				const result = findFolderById(node.children, id);
				if (result) return result;
			}
		}
		return null;
	};

	useEffect(() => {
		const getAllFolderIds = (nodes: FileApi.FSEntry[]): string[] => {
			return nodes.flatMap((node) => [node.id, ...(node.children ? getAllFolderIds(node.children) : [])]);
		};

		// Only expand all folders during the initial load
		if (folderTree && folderTree.length > 0 && initialLoad.current) {
			setExpandedTreeFolders(getAllFolderIds(folderTree));
			initialLoad.current = false;
		}
	}, [folderTree]);

	useEffect(() => {
		const renderRootFolder = findFolderById(folderTree, renderRootId);
		if (renderRootId && renderRootFolder) {
			setCurrentFolderTree([renderRootFolder]);
		} else if (renderRootId) {
			setCurrentFolderTree([]);
		} else {
			setCurrentFolderTree(folderTree);
		}
	}, [folderTree]);

	const handleClickOnTreeViewItem = (_event, id) => {
		onSelectNode(id);
	};

	const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
		setExpandedTreeFolders(itemIds);
		event.stopPropagation();
	};

	return (
		<div>
			{/* <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "1em" }}>
        {breadcrumb.map((folder) => (
          <Link
            key={folder.id}
            color="inherit"
            onClick={() => handleBreadcrumbClick(folder.id)}
            style={{ cursor: "pointer" }}
          >
            {folder.label}
          </Link>
        ))}
      </Breadcrumbs> */}

			<RichTreeView
				items={currentFolderTree}
				selectedItems={[selectedNodeId]} // Highlight the selected node
				onSelectedItemsChange={handleClickOnTreeViewItem}
				expansionTrigger="iconContainer"
				expandedItems={expandedTreeFolders}
				defaultExpandedItems={expandedTreeFolders}
				onExpandedItemsChange={handleExpandedItemsChange}
			/>
		</div>
	);
}
