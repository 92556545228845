import { useNavigate } from 'react-router-dom';

export async function createAndGotoNewScene(sceneName: string | null, navigate: ReturnType<typeof useNavigate>) {
	const result = await fetch('/api/scenes', {
		method: 'POST',
		body: JSON.stringify({ name: sceneName }),
	});
	const { sceneId } = await result.json();
	navigate(`/scenes/${sceneId}`);
}
