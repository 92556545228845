import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import FButton from '../FButton';

export type AskDeleteDialogProps = {
	noun: string;
	name?: string;
	open: boolean;
	onConfirm: () => void;
	onClose: () => void;
};

export function AskDeleteDialog({ noun, name, open, onConfirm, onClose }: AskDeleteDialogProps) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Delete {noun}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete this {noun}? <br />
					<strong> {name} </strong>
				</DialogContentText>
				<DialogActions sx={{ pr: 0 }}>
					<FButton color="primary" onClick={onClose}>
						Cancel
					</FButton>
					<FButton color="error" onClick={onConfirm}>
						Delete
					</FButton>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
