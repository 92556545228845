// import ModelIcon from "@mui/icons-material/Chair";
// import ImageIcon from "@mui/icons-material/Image";
// import EnvironmentIcon from "@mui/icons-material/Language";
// import LightIcon from "@mui/icons-material/Lightbulb";
// import ColorIcon from "@mui/icons-material/Palette";
import MaterialIcon from '@mui/icons-material/SportsSoccer';
import { Paper, Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCallback, useState } from 'react';
import AssetManager from './AssetManager';
import { RELATIVE_RENDERS_PATH, RELATIVE_SCENES_PATH, RELATIVE_MATERIALS_PATH } from '../../App';
import { CameraAlt } from '@mui/icons-material';
import { useLysReady } from '../LysReadyContext';

export enum Mode {
	scenes,
	models,
	materials,
	colors,
	textures,
	envs,
	lights,
	renders,
}

const tabFileType: Record<Mode, string> = {
	[Mode.scenes]: '.scene.toml,.share.toml',
	[Mode.models]: '',
	[Mode.materials]: '.mat',
	[Mode.colors]: '.color',
	[Mode.textures]: '.png,.jpg,.jpeg,.tga',
	[Mode.envs]: '.environment',
	[Mode.lights]: '.light',
	[Mode.renders]: '.jpg',
};

export default function LibraryDrawer() {
	const [selectedTab, setSelectedTab] = useState<Mode>(Mode.materials);
	const [filetypes, setFiletypes] = useState(tabFileType[selectedTab]);
	const { lysIsReady } = useLysReady();

	const handleTabSelect = (_event: any, newSelectedTab: number) => {
		setFiletypes(tabFileType[newSelectedTab]);
		setSelectedTab(newSelectedTab);
	};

	const handleMouseDown = (_e: any) => {
		document.addEventListener('mouseup', handleMouseUp, true);
		document.addEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseUp = () => {
		document.removeEventListener('mouseup', handleMouseUp, true);
		document.removeEventListener('mousemove', handleMouseMove, true);
	};

	const handleMouseMove = useCallback((e: MouseEvent) => {
		window.getSelection().removeAllRanges();
	}, []);

	return (
		<>
			<Tabs variant="fullWidth" value={selectedTab} onChange={handleTabSelect} aria-label="icon tabs example">
				<Tab
					icon={<MaterialIcon />}
					aria-label="materials"
					label="Materials"
					value={Mode.materials}
					sx={{ minWidth: 50, textTransform: 'none' }}
				/>

				<Tab
					icon={<CameraAlt />}
					aria-label="renders"
					label="Renders"
					value={Mode.renders}
					sx={{ minWidth: 50, textTransform: 'none' }}
				/>
			</Tabs>
			{selectedTab === Mode.scenes && lysIsReady && (
				<AssetManager
					showFolders={false}
					recursive={true}
					filetypes={filetypes}
					selectedPath={RELATIVE_SCENES_PATH}
				/>
			)}
			{selectedTab === Mode.materials && lysIsReady && (
				<AssetManager
					showFolders={false}
					recursive={true}
					filetypes={filetypes}
					selectedPath={RELATIVE_MATERIALS_PATH}
				/>
			)}
			{selectedTab === Mode.renders && lysIsReady && (
				<AssetManager
					showFolders={false}
					recursive={true}
					filetypes={filetypes}
					selectedPath={RELATIVE_RENDERS_PATH}
				/>
			)}
		</>
	);
}
