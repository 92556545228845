import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { PointInNode, useSelectedNodes } from '../components/SelectedNodesContext';
import useGlobalUpdate from '../utils/use-global-update';
import { useLysReady } from '../components/LysReadyContext';
import { GpsFixed } from '@mui/icons-material';
import { User } from '../utils/user';
import { colorForUser } from '../utils/colors';
import { useTheme } from '@mui/material/styles';
import { TreeNode } from '../components/actions';
import { MaterialWithProperties, useMaterials } from '../components/MaterialsContext';

export type PositionInElement = { x: number; y: number };
export type PositionOnScreen = { left: number; top: number };
type Vec2 = [number, number];
type DOMRectLike = { left: number; top: number; width: number; height: number };

/** Represents a curved line starting any HTML element anywhere in the DOM
 *  and ending in on a point inside a node on the WebGLCanvas.
 */
export interface CalloutLine {
	pointInNode: PointInNode;
	targetElement: HTMLElement;
	backgroundColor: string;
	strokeColor: string;
	uniqueKey: string;
}

/** Represents a curved line and a box with a title and subtitle, positioned close to
 * a point inside a lys node on the WebGLCanvas.
 */
interface CalloutInfoBox {
	key: string;
	title: string;
	subtitles: Map<string, string>;
	position: PositionInElement;
	nodeCenterOnCanvas: PositionInElement;
}

interface CalloutInfoBoxPosition {
	originalPosition: PositionInElement;
}

const CALLOUT_BOX_SIZE = { width: 200, height: 75 };

// Context for adding callout lines and callout info boxes to the canvas.
export interface CalloutContext {
	// Adding and removing CalloutLines to any HTML element anywhere
	addCallout: (callout: CalloutLine) => void;
	removeCallout: (callout: CalloutLine) => void;
	// Add callout info boxesm for a subset of the nodes in the scene tree
	setCalloutBoxes: (pins: Set<PointInNode>) => void;
}

export const CalloutContextProvider = ({
	children,
	canvas,
	user,
}: {
	children: ReactNode;
	canvas?: HTMLCanvasElement;
	user: User;
}) => {
	// simple callout lines
	const [calloutLines, setCalloutLines] = useState<CalloutLine[]>([]);
	const [linesSvg, setLinesSvg] = useState<JSX.Element[]>([]);

	// info box callouts
	const [calloutPins, setCalloutPins] = useState<Set<PointInNode>>(new Set());
	const [calloutInfoBoxes, setCalloutInfoBoxes] = useState<CalloutInfoBox[]>([]);
	const [linesToInfoBoxes, setLinesToInfoBoxes] = useState<JSX.Element[]>([]);

	const theme = useTheme();
	const { lysIsReady } = useLysReady();
	const [updated, forceUpdate] = useState(0);
	const { selectedMaterialProperties } = useMaterials();
	const cameraUpdated = useGlobalUpdate('updateActiveCamera');
	const nodeTransformed = useGlobalUpdate('nodeTransformed');

	const addCallout = (callout: CalloutLine) => {
		setCalloutLines((prev) => [...prev, callout]);
	};

	const removeCallout = (callout: CalloutLine) => {
		setCalloutLines((prev) => prev.filter((c) => c !== callout && c.uniqueKey !== callout.uniqueKey));
	};

	useEffect(() => {
		if (canvas) {
			const observer = new ResizeObserver(() => forceUpdate((p) => p + 1));
			observer.observe(canvas);

			return () => {
				observer.unobserve(canvas);
			};
		}
	}, [canvas]);

	function toScreenCoordinates(element: HTMLElement, { x, y }): PositionOnScreen {
		const rect = element.getBoundingClientRect();
		return {
			left: x + rect.left + window.scrollX,
			top: y + rect.top + window.scrollY,
		};
	}

	const { selectedPointInNodes } = useSelectedNodes();
	const [crossHairs, setCrossHairs] = useState<PositionOnScreen[]>([]);
	useEffect(() => {
		if (!lysIsReady) return;
		if (canvas) {
			setCrossHairs(
				selectedPointInNodes
					.map((pin) => globalThis.lys.screenPositionOfPointInNode(pin))
					.filter((pos) => !outsideCanvas(pos, canvas))
					.map((pos) => toScreenCoordinates(canvas, pos)),
			);
		}
	}, [selectedPointInNodes, cameraUpdated, nodeTransformed, lysIsReady, updated]);

	useEffect(() => {
		if (!lysIsReady) return;
		setLinesSvg(calloutLines.map(drawCurves));
	}, [calloutLines, cameraUpdated, nodeTransformed, lysIsReady, updated]);

	function getSubtitles(node: TreeNode): Map<string, string> {
		const mat = node.getMaterial();

		const map = new Map();
		if (mat) {
			const material = JSON.parse(mat.toJSONString()) as MaterialWithProperties;
			map.set('_name', material.name);
			material.parameters.forEach((param) => {
				if (param.type === 'text' && param.value && param.value !== '') {
					map.set(param.name, param.value);
				}
			});
		}
		return map;
	}

	// make boxes not overlap by setting up a force-directed
	// graph where each box is drawn towards it's original position
	// and repelled from nearby boxes.
	function nudgeBoxes(boxes: (CalloutInfoBox & CalloutInfoBoxPosition)[]) {
		const k = 300; // repulsion spring constant
		const m = 0.2; // attraction spring constant
		const dt = 0.1; // timestep
		const maxIterations = 20;

		const forces = boxes.map(() => ({ x: 0, y: 0 }));

		for (let i = 0; i < maxIterations; i++) {
			boxes.forEach((box, index) => {
				// drawn towards original position
				const dx = box.position.x - box.originalPosition.x;
				const dy = box.position.y - box.originalPosition.y;
				const dist = Math.max(0.01, Math.sqrt(dx ** 2 + dy ** 2));
				const force = -m * dist;
				forces[index].x += force * dx;
				forces[index].y += force * dy;

				// // repel forcefully from the edges of the canvas
				// const edgeForce = 5;
				// forces[index].x += edgeForce / Math.max(0.01, box.position.x) ** 2;
				// forces[index].x -= edgeForce / Math.max(0.01, canvas.width - box.position.x) ** 2;
				// forces[index].y += edgeForce / Math.max(0.01, box.position.y) ** 2;
				// forces[index].y -= edgeForce / Math.max(0.01, canvas.height - box.position.y) ** 2;

				// repelled from nearby boxes
				boxes.forEach((otherBox, otherIndex) => {
					if (index === otherIndex) return;
					const dx = box.position.x - otherBox.position.x;
					const dy = box.position.y - otherBox.position.y;
					const dist = Math.max(0.01, Math.sqrt(dx ** 2 + dy ** 2));
					const force = k / dist ** 2;
					forces[index].x += force * dx;
					forces[index].y += force * dy;
				});
			});

			boxes.forEach((box, index) => {
				box.position.x += forces[index].x * dt;
				box.position.y += forces[index].y * dt;
			});
		}
	}

	// Callout boxes for selected nodes
	useEffect(() => {
		if (!lysIsReady) return;
		const boxes: (CalloutInfoBox & CalloutInfoBoxPosition)[] = Array.from(calloutPins)
			.map((pin: PointInNode) => {
				const node = globalThis.lys.getNodeById(pin.id);
				const name: string = node.getName();
				const pinOnScreen = globalThis.lys.screenPositionOfPointInNode(pin);
				const boxPosition: PositionInElement = placeBox(pinOnScreen, canvas.width, canvas.height);
				clamp(boxPosition, canvas.width, canvas.height);
				const subtitles = getSubtitles(node);
				return {
					key: 'cbox_' + pin.id,
					title: name.replaceAll('_', ' '),
					subtitles,
					position: boxPosition,
					originalPosition: boxPosition,
					nodeCenterOnCanvas: pinOnScreen,
				};
			})
			.filter((box) => !outsideCanvas(box.nodeCenterOnCanvas, canvas));

		nudgeBoxes(boxes);
		boxes.forEach((box) => clamp(box.position, canvas.width, canvas.height));

		setCalloutInfoBoxes(boxes);

		const linesToBoxes = boxes.map((box, index: number) => {
			const source = toScreenCoordinates(canvas, box.position);
			const target = toScreenCoordinates(canvas, box.nodeCenterOnCanvas);
			const boxRect: DOMRectLike = {
				left: source.left - CALLOUT_BOX_SIZE.width / 2,
				top: source.top - CALLOUT_BOX_SIZE.height / 2,
				width: CALLOUT_BOX_SIZE.width,
				height: CALLOUT_BOX_SIZE.height,
			};
			return drawCurvePrimitive(
				source,
				target,
				boxRect,
				theme.palette.background.default,
				theme.palette.divider,
				'tobox_' + box.key,
				42 + index * 2,
			);
		});

		setLinesToInfoBoxes(linesToBoxes);
	}, [calloutPins, lysIsReady, nodeTransformed, cameraUpdated, updated, theme, selectedMaterialProperties]);

	function outsideCanvas(position: PositionInElement, canvas: HTMLCanvasElement): boolean {
		return position.x < 0 || position.y < 0 || position.x > canvas.width || position.y > canvas.height;
	}

	// SVG soft curve from source to target
	function drawCurvePrimitive(
		source: PositionOnScreen,
		target: PositionOnScreen,
		targetRect: DOMRectLike,
		fillColor: string,
		strokeColor: string,
		uniqueKey: string,
		zIndex?: number,
	) {
		const vec: Vec2 = [target.left - source.left, target.top - source.top];
		const dist = Math.max(Math.sqrt(vec[0] * vec[0] + vec[1] * vec[1]), 0.1);
		const cpLength = dist / 8;
		const unitVec: Vec2 = [vec[0] / dist, vec[1] / dist];
		const unitVecRotated30deg: Vec2 = [
			unitVec[0] * Math.cos(Math.PI / 6) - unitVec[1] * Math.sin(Math.PI / 6),
			unitVec[0] * Math.sin(Math.PI / 6) + unitVec[1] * Math.cos(Math.PI / 6),
		];
		const normal: Vec2 = [-unitVec[1], unitVec[0]];
		const sourceLineWidth = 12;
		const targetLineWidth = 3;

		const source1: Vec2 = [source.left + normal[0] * sourceLineWidth, source.top + normal[1] * sourceLineWidth];
		const source1cp: Vec2 = [
			source.left + unitVecRotated30deg[0] * cpLength,
			source.top + unitVecRotated30deg[1] * cpLength,
		];

		const source2: Vec2 = [source.left - normal[0] * sourceLineWidth, source.top - normal[1] * sourceLineWidth];
		const source2cp: Vec2 = [
			source.left + unitVecRotated30deg[0] * cpLength,
			source.top + unitVecRotated30deg[1] * cpLength,
		];

		const target1: Vec2 = [target.left + normal[0] * targetLineWidth, target.top + normal[1] * targetLineWidth];
		const target1cp1: Vec2 = [
			target.left - unitVecRotated30deg[0] * cpLength,
			target.top - unitVecRotated30deg[1] * cpLength,
		];
		const target1cp2: Vec2 = [
			target1[0] + unitVecRotated30deg[0] * targetLineWidth,
			target1[1] + unitVecRotated30deg[1] * targetLineWidth,
		];

		const target2: Vec2 = [target.left - normal[0] * targetLineWidth, target.top - normal[1] * targetLineWidth];
		const target2cp1: Vec2 = [
			target2[0] + unitVecRotated30deg[0] * targetLineWidth,
			target2[1] + unitVecRotated30deg[1] * targetLineWidth,
		];
		const target2cp2: Vec2 = [
			target.left - unitVecRotated30deg[0] * cpLength,
			target.top - unitVecRotated30deg[1] * cpLength,
		];

		return (
			<svg
				key={`co${uniqueKey}`}
				style={{
					zIndex: zIndex ?? 42,
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					pointerEvents: 'none',
				}}
			>
				<mask id={`ma${uniqueKey}`}>
					<rect x={0} y={0} width={'100%'} height={'100%'} fill="white" />
					<rect
						x={targetRect.left + 0.7}
						y={targetRect.top + 0.7}
						width={targetRect.width - 1.4}
						height={targetRect.height - 1.4}
						rx={8}
						ry={8}
						fill="black"
					/>
				</mask>
				<path
					d={`
						M ${source1[0]},${source1[1]} 
						C ${source1cp[0]},${source1cp[1]} ${target1cp1[0]},${target1cp1[1]} ${target1[0]},${target1[1]} 
						C ${target1cp2[0]},${target1cp2[1]} ${target2cp1[0]},${target2cp1[1]} ${target2[0]},${target2[1]} 
						C ${target2cp2[0]},${target2cp2[1]} ${source2cp[0]},${source2cp[1]} ${source2[0]},${source2[1]}`}
					mask={`url(#ma${uniqueKey})`}
					strokeWidth="8"
					fill="#5f5"
					strokeLinecap="round"
					strokeLinejoin="round"
					style={{
						stroke: strokeColor,
						fill: fillColor,
						strokeWidth: 0.7,
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
					}}
				/>
			</svg>
		);
	}

	// Draws a curve between a callout box and the node it points to
	function drawCurves(callout: CalloutLine): JSX.Element {
		if (!globalThis.lys.getNodeById(callout.pointInNode.id)) {
			// Someone deleted the node to which a callout still points
			return <></>;
		}
		const positionOnCanvas: PositionInElement = globalThis.lys.screenPositionOfPointInNode(callout.pointInNode);
		if (outsideCanvas(positionOnCanvas, canvas)) {
			return <></>;
		}
		const target = toScreenCoordinates(canvas, positionOnCanvas);
		const source = toScreenCoordinates(callout.targetElement, {
			x: callout.targetElement.clientWidth / 2,
			y: callout.targetElement.clientHeight / 2,
		});
		const sourceBox = callout.targetElement.getBoundingClientRect();

		return drawCurvePrimitive(
			source,
			target,
			sourceBox,
			callout.backgroundColor,
			callout.strokeColor,
			callout.uniqueKey,
		);
	}

	// Places a callout box next to a node, but not too far away, nor too close
	function placeBox(nodeOnCanvas: PositionInElement, canvasWidth: number, canvasHeight: number): PositionInElement {
		// Origo, here, is the center of the canvas, in 2D
		// All boxes will be placed pointing at least minDistance pixels away from origo, in the direction
		// that this vector points.
		const vecFromOrigoToNode = {
			x: 0.01 + nodeOnCanvas.x - canvasWidth / 2,
			y: -0.01 + nodeOnCanvas.y - canvasHeight / 2,
		};

		const nodeDistance = Math.max(0.1, Math.sqrt(vecFromOrigoToNode.x ** 2 + vecFromOrigoToNode.y ** 2));
		const minDistance = Math.min(CALLOUT_BOX_SIZE.width, Math.min(canvasWidth, canvasHeight) / 8);
		const maxDistance = Math.min(canvasWidth, canvasHeight) / 1.3;

		const direction = {
			x: vecFromOrigoToNode.x / nodeDistance,
			y: vecFromOrigoToNode.y / nodeDistance,
		};

		// s-curved cubic from minDistance to maxDistance
		const t = nodeDistance / maxDistance;
		const s = 3 * t ** 2 - 2 * t ** 3;
		const boxDistance = minDistance + s * maxDistance;

		const boxCenter = {
			x: nodeOnCanvas.x + direction.x * boxDistance,
			y: nodeOnCanvas.y + direction.y * boxDistance,
		};

		return boxCenter;
	}

	function clamp(position: PositionInElement, canvasWidth: number, canvasHeight: number) {
		const halfling = CALLOUT_BOX_SIZE.height / 2; // Frodo!
		const halfWit = CALLOUT_BOX_SIZE.width / 2; // The author of this code

		// clamp box position to canvas dimensions
		if (position.x < halfWit) position.x = halfWit;
		if (position.x > canvasWidth - halfWit) position.x = canvasWidth - halfWit;
		if (position.y < halfling) position.y = halfling;
		if (position.y > canvasHeight - halfling) position.y = canvasHeight - halfling;
	}

	return (
		<CalloutContext.Provider value={{ addCallout, removeCallout, setCalloutBoxes: setCalloutPins }}>
			{children}
			{canvas && linesSvg}
			{canvas && linesToInfoBoxes}
			{calloutInfoBoxes.map(({ position, title, subtitles, key }: CalloutInfoBox, index: number) => {
				const boxScreenpos = toScreenCoordinates(canvas, position);
				return (
					<div
						key={key}
						style={{
							width: `${CALLOUT_BOX_SIZE.width}px`,
							minHeight: `${CALLOUT_BOX_SIZE.height}px`,
							overflow: 'hidden',
							position: 'absolute',
							padding: '8px',
							border: `1px solid ${theme.palette.divider}`,
							backgroundColor: theme.palette.background.default,
							borderRadius: '8px',
							zIndex: 41 + index * 2,
							left: `${boxScreenpos.left - CALLOUT_BOX_SIZE.width / 2}px`,
							top: `${boxScreenpos.top - CALLOUT_BOX_SIZE.height / 2}px`,
							pointerEvents: 'none',
						}}
					>
						<strong>{title}</strong>
						{Array.from(subtitles.keys()).map((field) => (
							<div
								style={{
									overflow: 'hidden',
									pointerEvents: 'none',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
								}}
								key={`subt_${field}`}
							>
								{field.startsWith('_') ? '' : `${field}: `}
								{subtitles.get(field)}
							</div>
						))}
					</div>
				);
			})}
			{canvas &&
				crossHairs.map((position, i) => (
					<GpsFixed
						key={`crosshair_${i}`}
						style={{
							color: colorForUser(user),
							borderRadius: '2px',
							width: '24px',
							height: '24px',
							left: position.left - 12 + 'px',
							top: position.top - 12 + 'px',
							position: 'absolute',
							pointerEvents: 'none',
						}}
					/>
				))}
		</CalloutContext.Provider>
	);
};

export const CalloutContext = createContext<CalloutContext | undefined>(undefined);

export const useCallouts = () => useContext(CalloutContext);
